
import React from 'react';
import { 
  Col, Container, Row, Breadcrumb, BreadcrumbItem,
 } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import SchedulingForm from './SchedulingForm';
import showResults from '../../../../Form/Show';

const SchedulingFormIndex = () => {
  const params = useParams();
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Scheduling Project</h3>
            <h3 className="page-subhead subhead">
              {params.action}
            </h3>
            <Breadcrumb className="mt-2">
              <BreadcrumbItem>
                <Link to="/master/project">
                  Project
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/master/Scheduling/${params.proid}`}>
                  Scheduling Project List
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Scheduling Project - {params.action}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <SchedulingForm onSubmit={showResults} />
        </Row>
      </Container>
    );    
};
  
export default SchedulingFormIndex;
