/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';

const SPNotificationForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const [formData, setTestData] = useState({});
  const [inputActive, setinputActive] = useState(false);
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  
  async function onSubmit(data) {
    console.log(data);
    const editedData = [];
    console.log(inputActive);
    if (inputActive === true) {
      data.sp_notify_status = '1';
    } else {
      data.sp_notify_status = '0';
    }
    data.sp_notify_modified_by = '1';
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    data.sp_notify_modified_on = currentDateFormate;
    editedData.push(data); 
    console.log(editedData);
    
    if (params.action === 'create') {
      await axios.post(`${getUserData.ses_URL}notification_type/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
    // axios.post('http://127.0.0.1:8000/notification_type/1/30888374364/1/0', editedData)
    .then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success('New Notification type added successfully');
        document.getElementById('backButton_sys_notification').click();
      } else {
        toast.error('Try Again!');
      }
    });
    } else if (params.action === 'edit') {
      await axios.put(`${getUserData.ses_URL}notification_type/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
      // axios.put(`http://127.0.0.1:8000/notification_type/1/30888374364/2/${params.id}`, editedData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success('Notification type updated successfully');
          document.getElementById('backButton_sys_notification').click();
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    }
  }

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setTestData({
        sp_notify_name: '',
        sp_notify_code: '',
        sp_notify_remarks: '',
        sp_notify_modified_by: '',
        sp_notify_modified_on: '',
      });
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      async function viewData() {
        await axios.get(`${getUserData.ses_URL}notification_type/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/notification_type/1/30888374364/2/${params.id}`)
          .then((res) => {
            // console.log(res.data.data[0]);
            // setTimeout(() => setTestData(res.data.data[0]), 1000);
            setTestData(res.data.data[0]);
            if (res.data.data[0].sp_notify_status === 1) {
              setinputActive(true);
            } else {
              setinputActive(false);
            }
          });
      }
  viewData();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/systemparameter/spnotification">
              <Button className="icon" id="backButton_sys_notification" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Notification {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_notify_name">Notification Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_notify_name"
                      type="text"
                      placeholder="Notification Name"
                      id="sp_notify_name"
                      defaultValue={formData.sp_notify_name ? formData.sp_notify_name : ''}
                      {...register('sp_notify_name', {
                        required: 'Notification name is required',
                      })}
                    />
                    {errors?.sp_notify_name && <span className="form__form-group-error">{errors.sp_notify_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_notify_code">Notification Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_notify_code"
                      type="text"
                      placeholder="Code"
                      id="sp_notify_code"
                      defaultValue={formData.sp_notify_code ? formData.sp_notify_code : ''}
                      {...register('sp_notify_code', {
                        required: 'Notification Code is required',
                      })}
                    />
                    {errors?.sp_notify_code && <span className="form__form-group-error">{errors.sp_notify_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_notify_remarks">Notification Remarks</label>
                <div className="form__form-group-field">
                  <textarea
                    name="sp_notify_remarks"
                    type="text"
                    placeholder="Enter the text..."
                    id="sp_notify_remarks"
                    {...register('sp_notify_remarks')}
                    row="3"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox 
                          name="sp_notify_status" 
                          control={control}
                          value={inputActive}  
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}                   
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active" 
                      {...register('sp_notify_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/systemparameter/spnotification">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SPNotificationForm;
