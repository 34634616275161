import React, { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import Tree from "./Tree/Tree";
import FTPMatTable from "./FTPTable/FTPMatTable";

const structure = [
  {
    type: "folder",
    name: "Folder 1",
    files: [
      {
        type: "folder",
        name: "Sub Folder 1",
        files: [
          { type: "file", name: "Toggle.js" },
          { type: "file", name: "Button.css" },
          { type: "file", name: "style.xlsx" },
        ],
      },
      {
        type: "folder",
        name: "Sub Folder 2",
        files: [
          { type: "file", name: "Tree.js" },
          { type: "file", name: "Tree.html" },
        ],
      },
      { type: "file", name: "image1.img" },
      { type: "file", name: "image2.img" },
    ],
  },
  {
    type: "folder",
    name: "Folder 2",
    files: [
      {
        type: "file",
        name: "sheet f1.xlsx",
      },
      {
        type: "file",
        name: "sheet f2.xlsx",
      },
      {
        type: "file",
        name: "sheet f3.xlsx",
      },
    ],
  },
  {
    type: "folder",
    name: "Folder 3",
    files: [
      {
        type: "file",
        name: "doc f1.doc",
      },
      {
        type: "file",
        name: "file_setup.txt",
      },
      {
        type: "file",
        name: "testing.txt",
      },
    ],
  },
  { type: "file", name: "file.txt" },
  { type: "file", name: "sheet_sample.xlsx" },
  { type: "file", name: "document.pdf" },
  { type: "file", name: "accounts.doc" },
];

export default function FileManagerMain() {
  const [data, setData] = useState(structure);

  const handleClick = (node) => {
    console.log(node);
  };
  const handleUpdate = (state) => {
    localStorage.setItem(
      "tree",
      JSON.stringify(state, (key, value) => {
        if (key === "parentNode" || key === "id") {
          return null;
        }
        return value;
      })
    );
  };

  useLayoutEffect(() => {
    try {
      const savedStructure = JSON.parse(localStorage.getItem("tree"));
      if (savedStructure) {
        setData(savedStructure);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="custom-header_links mb-3">
            <h3 className="page-title d-inline">FTP File Manager</h3>
            <Link to="/master/ftpaccount">
              <Button className="icon" color="primary">
                <p>FTP Account</p>
              </Button>
            </Link>
            <div className="d-inline">
              <strong>FTP Account: </strong>
              <span>FTP Account 1</span>
            </div>
          </div>
          <Card>
            <CardBody className="file-manager p-0">
              <div>
                {/* <Col md={12}>
                    <div>Header</div>
                  </Col> */}
                <div className="__wrapper">
                  <Col md={3} className="tree-custom">
                    <div className="d-tree">
                      <Tree
                        data={data}
                        onUpdate={handleUpdate}
                        onNodeClick={handleClick}
                      />
                    </div>
                  </Col>
                  <Col md={9}>
                    <FTPMatTable />
                  </Col>
                </div>
                {/* <Col md={12}>
                    <div>Footer</div>
                  </Col> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>{/* <FileSection /> */}</Row>
    </Container>
  );
}
