/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
// import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import store from '../../App/store';
// import { 
//   faCheckSquare, faCoffee, faCaretRight, faDesktop, 
// } from '@fortawesome/free-solid-svg-icons';
// import { Modal, Button } from 'reactstrap';
// import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
// import '../../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import './index.css';
// import SheetIframeModel from '../ModelIframe/SheetIframeModel';
import { toSheetDataSet } from '../../../redux/custom/actions/sheetTableDataActions';

const TreeListData = ({ data = [] }) => (
  <div className="d-tree">
    <ul className="d-flex d-tree-container flex-column">
      {
      console.log(data)
      }
      {data.map(tree => (
        <TreeNode node={tree} /> 
        ))}
    </ul>
  </div>
  );

// const TreeListData = (props) => {
//   // console.log('td');
//   const { data } = props;
//   // const { sData } = props.sheetTableData;
//   console.log(props);
//   // console.log(data.sheetTableData);
//   // const dest = [];
//   // dest.push(data.data);
//   return (
//     <div className="d-tree">
//       <ul className="d-flex d-tree-container flex-column">
//         {
//       // console.log(data)
//       }
//         {data.map(tree => (
//           <TreeNode node={tree} propsdata={props} /> 
//         ))}
//       </ul>
//     </div>
//   );
// };

const TreeNode = ({ node }) => {
  // console.log(node);
  const tData = [];
  const [childVisible, setChildVisiblity] = useState(false);
  const hasChild = !!node.children;
  const hasPermalink = !!node.permalink;
  const [isLoading, setLoading] = useState(true);
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
 
  async function getSheetTableData(id, type) {
    console.log('table');
    console.log(type);    
    if (type === '1') {
      // setLoading(true); 
      await axios.get(`${getUserData.ses_URL}smart_sheet/work_space/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1/${id}`)
    .then((res) => {
      console.log(res.data.data);
    //   const gData = [{
    //     label: 'Sheets',
    //   icon: 'file',
    //   title: 'Sheets',
    //   children: [],
    //   },
    // ];
    // console.log(gData);
    // console.log(node);
    if (res.data.data.folders) {
      while (node.children.length) {
        node.children.pop();
      }
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data.folders).map((keyName) => {
        node.children.push(
          {
            id: `${res.data.data.folders[keyName].id}`,
            label: `${res.data.data.folders[keyName].name}`,
            icon: 'folder',
            type: 2,
            title: 'Workspace Folder',
            permalink: `${res.data.data.folders[keyName].permalink}`,
            accesslevel: `${res.data.data.folders[keyName].accessLevel}`,
            children: [],
          },
        );
      });
    }
    });
    } else if (type === '2') {
      await axios.get(`${getUserData.ses_URL}smart_sheet/folder/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1/${id}/0`)
    .then((res) => {
      console.log(res.data.data);
    if (res.data.data.folders) {
      // setLoading(true); 
      while (node.children.length) {
        node.children.pop();
      }
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data.folders).map((keyName) => {
        node.children.push(
          {
            id: `${res.data.data.folders[keyName].id}`,
            label: `${res.data.data.folders[keyName].name}`,
            icon: 'folder',
            type: 2,
            title: 'Workspace Folder',
            permalink: `${res.data.data.folders[keyName].permalink}`,
            accesslevel: `${res.data.data.folders[keyName].accessLevel}`,
            children: [],
          },
        );
      });
    }
    });
    }
    setLoading(false); 
  }

  async function changeSheetTableData(id, type) {
    console.log(id);
    // console.log(store.getState());
    if (id !== 'undefined' && id !== null && id !== undefined) {
      if (type === '1') {
        await axios.get(`${getUserData.ses_URL}smart_sheet/work_space/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1/${id}`)
        .then((res) => {
          console.log(res.data.data);
          // eslint-disable-next-line array-callback-return
          if (res.data.data.folders) {
            // eslint-disable-next-line array-callback-return
            Object.keys(res.data.data.folders).map((keyName) => {
              tData.push(
                {
                  id: `${res.data.data.folders[keyName].id}`, 
                  // fname: <div className="file-manager_icons"><CgFolder /> <span>{res.data.data.folders[keyName].name}</span></div>,
                  fname: `${res.data.data.folders[keyName].name}`,
                  fdate: 'September 30, 2021-11-23',
                  ftype: 'Folder', 
                  fsize: '',
                  fnameorder: 'sheet_sample',
                  fpermalink: `${res.data.data.folders[keyName].permalink}`,
                },
              );
            });
            store.dispatch(toSheetDataSet(tData));
            // props.toSheetTableDataSetAction(tData);
            // console.log(props);
          } else {
            store.dispatch(toSheetDataSet([{
    
              id: '', 
              fname: '',
              fdate: '',
              ftype: '', 
              fsize: '',
              fnameorder: '',
              fpermalink: '',
            }]));
          }
        });
      } else if (type === '2') {
        await axios.get(`${getUserData.ses_URL}smart_sheet/folder/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1/${id}/0`)
        .then((res) => {
          console.log(res.data.data);
          // eslint-disable-next-line array-callback-return
          if (res.data.data.folders) {
            // eslint-disable-next-line array-callback-return
            Object.keys(res.data.data.folders).map((keyName) => {
              tData.push(
                {
                  id: `${res.data.data.folders[keyName].id}`, 
                  // fname: <div className="file-manager_icons"><CgFolder /> <span>{res.data.data.folders[keyName].name}</span></div>,
                  fname: `${res.data.data.folders[keyName].name}`,
                  fdate: 'September 30, 2021-11-23',
                  ftype: 'Folder', 
                  fsize: '',
                  fnameorder: 'sheet_sample',
                  fpermalink: `${res.data.data.folders[keyName].permalink}`,
                },
              );
            });
            store.dispatch(toSheetDataSet(tData));
            // props.toSheetTableDataSetAction(tData);
            // console.log(props);
          } else {
            store.dispatch(toSheetDataSet([{
    
              id: '', 
              fname: '',
              fdate: '',
              ftype: '', 
              fsize: '',
              fnameorder: '',
              fpermalink: '',
            }]));
          }
        });
      }
    }
  }
  
  return (
    <li className="d-tree-node border-0">
      <div className="d-flex">
        {hasChild && (
          <div 
          // setChildVisiblity(v => !v); 
            onClick={() => { getSheetTableData(`${node.id}`, `${node.type}`); setChildVisiblity(cv => !cv); }}
            className={`d-inline d-tree-toggler ${
              childVisible ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon icon="caret-right" />
          </div>
        )}

        <div className="col d-tree-head" onClick={() => changeSheetTableData(`${node.id}`, `${node.type}`)}>
          {/* <FontAwesomeIcon icon={`${node.icon}`} /> */}
          {/* <i className={`mr-1 ${node.icon}`}> </i> */}
          <FontAwesomeIcon className="mr-1" icon={`${node.icon}`} />
          {node.label}
          {/* <span> view </span> */}
        </div>
        {hasPermalink && (
            // <button onClick={ModelNotification(node.permalink, 'test')}> view </button>
            // <SheetIframeModel
            //   color="primary"
            //   title="test"
            //   header
            //   btn="View"
            //   message="message"
            // />
        <a className="ml-1 h-link" href={node.permalink} target="_blank" rel="noreferrer">
          <FontAwesomeIcon color="primary" icon="external-link-square-alt" /> Link
        </a>
          )}
      </div>
      

      {hasChild && childVisible && isLoading === false && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <TreeListData data={node.children} />
          </ul>
        </div>
      )}
    </li>
  );  
};

// const mapStateToProps = state => ({
//   // rtl: state.rtl,
//   // globalData: state.globalData,
//   sheetTableData: state.sheetTableData,
// });
// const mapDispatchToProps = dispatch => ({
//   // toGlobalDataSetAction: (url, token) => dispatch(toGlobalDataSet(url, token)),
//   toSheetTableDataSetAction: data => dispatch(toSheetDataSet(data)),
// });

export default TreeListData;
// export default connect(mapStateToProps, mapDispatchToProps)(TreeListData);
