import React from 'react';
import {
 Col, Container, Row, Breadcrumb, BreadcrumbItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import SchedulingTableData from './components/Table/SchedulingTableData';
import SchedulingTableDesign from './components/Table/SchedulingDesign';

const DataTable = () => {
    const reactTableData = SchedulingTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Scheduling Project</h3>
            {/* <h3 className="page-subhead subhead">
              List
            </h3> */}
            <Breadcrumb className="mt-2">
              <BreadcrumbItem>
                <Link to="/master/project">
                  Project
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Scheduling Project - List</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <SchedulingTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
