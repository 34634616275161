import React from 'react';
import { Route, Switch } from 'react-router-dom';
import User from '../../../Master/User/index';
import FormIndex from '../../../Master/User/components/Form/FormIndex';
import HistoryIndex from '../../../Master/User/components/History/HistoryIndex';
import FTPAccount from '../../../Master/FTPAccount';
import FTPAccountHistoryIndex
from '../../../Master/FTPAccount/components/History/FTPAccountTypeHistoryIndex';
import FTPAccountFormIndex from '../../../Master/FTPAccount/components/Form/FTPAccountFormIndex';
import Project from '../../../Master/Project';
import ProjectHistoryIndex from '../../../Master/Project/components/History/ProjectHistoryIndex';
import ProjectFormIndex from '../../../Master/Project/components/Form/ProjectFormIndex';
import SheetAccount from '../../../Master/SheetAccount';
import SheetAccountHistoryIndex from '../../../Master/SheetAccount/components/History/SheetAccountHistoryIndex';
import SheetAccountFormIndex from '../../../Master/SheetAccount/components/Form/SheetAccountFormIndex';
import Section from '../../../Master/Section';
import SectionHistoryIndex from '../../../Master/Section/components/History/SectionHistoryIndex';
import SectiontFormIndex from '../../../Master/Section/components/Form/SectionFormIndex';
import Mapping from '../../../Master/Mapping';
import MappingFormIndex from '../../../Master/Mapping/components/Form/MappingFormIndex';
import Scheduling from '../../../Master/Scheduling';
import SchedulingFormIndex from '../../../Master/Scheduling/components/Form/SchedulingFormIndex';
import DataUpload from '../../../Master/DataUpload';
import UploadHistory from "../../../Master/UploadHistory/index";
import Tracker from '../../../Master/Tracker';
import Reports from '../../../Master/Reports';
import Vendor from '../../../Master/Vendor/index';
import VendorFormIndex from '../../../Master/Vendor/components/Form/VendorFormIndex';
import VendorHistoryIndex from '../../../Master/Vendor/components/History/VendorHistoryIndex';
import Template from '../../../Master/Template/index';
import TemplateFormIndex from '../../../Master/Template/components/Form/TemplateFormIndex';
import TemplateHistoryIndex from '../../../Master/Template/components/History/TemplateHistoryIndex';
import AppSettingsIndex from '../../../Master/AppSettings/index';

export default () => (
  <Switch>
    {/* user */}
    <Route exact path="/master/user" component={User} />
    <Route exact path="/master/user/history/:id" component={HistoryIndex} />
    <Route exact path="/master/user/:action/" component={FormIndex} />
    <Route exact path="/master/user/:action/:id" component={FormIndex} />

    {/* FTP Account Type */}
    <Route exact path="/master/ftpaccount" component={FTPAccount} />
    <Route
      exact
      path="/master/ftpaccount/history/:id"
      component={FTPAccountHistoryIndex}
    />
    <Route
      exact
      path="/master/ftpaccount/:action/"
      component={FTPAccountFormIndex}
    />
    <Route
      exact
      path="/master/ftpaccount/:action/:id"
      component={FTPAccountFormIndex}
    />

    {/* Project */}
    <Route exact path="/master/project" component={Project} />
    <Route
      exact
      path="/master/project/history/:id"
      component={ProjectHistoryIndex}
    />
    <Route exact path="/master/project/:action/" component={ProjectFormIndex} />
    <Route
      exact
      path="/master/project/:action/:id"
      component={ProjectFormIndex}
    />

    {/* Sheet Account */}
    <Route exact path="/master/sheetaccount" component={SheetAccount} />
    <Route
      exact
      path="/master/sheetaccount/history/:id"
      component={SheetAccountHistoryIndex}
    />
    <Route
      exact
      path="/master/sheetaccount/:action/"
      component={SheetAccountFormIndex}
    />
    <Route
      exact
      path="/master/sheetaccount/:action/:id"
      component={SheetAccountFormIndex}
    />

    {/* Section */}
    <Route exact path="/master/section" component={Section} />
    <Route
      exact
      path="/master/section/history/:id"
      component={SectionHistoryIndex}
    />
    <Route
      exact
      path="/master/section/:action/"
      component={SectiontFormIndex}
    />
    <Route
      exact
      path="/master/section/:action/:id"
      component={SectiontFormIndex}
    />

    {/* Mapping */}
    <Route exact path="/master/mapping/:proid" component={Mapping} />
    <Route
      exact
      path="/master/mapping/:proid/:action/"
      component={MappingFormIndex}
    />
    <Route
      exact
      path="/master/mapping/:proid/:action/:id"
      component={MappingFormIndex}
    />

    {/* Scheduling */}
    <Route exact path="/master/scheduling/:proid" component={Scheduling} />
    <Route
      exact
      path="/master/scheduling/:proid/:action/"
      component={SchedulingFormIndex}
    />
    <Route
      exact
      path="/master/scheduling/:proid/:action/:id"
      component={SchedulingFormIndex}
    />

    {/* DataUpload */}
    <Route exact path="/master/dataupload" component={DataUpload} />
    <Route exact path="/master/uploadhistory" component={UploadHistory} />

    {/* Tracker */}
    <Route exact path="/master/tracker" component={Tracker} />

    {/* Reports */}
    <Route exact path="/master/reports" component={Reports} />

    {/* Vendor */}
    <Route exact path="/master/vendor" component={Vendor} />

    {/* Vendor */}
    <Route exact path="/master/vendor" component={Vendor} />
    <Route
      exact
      path="/master/vendor/history/:id"
      component={VendorHistoryIndex}
    />
    <Route exact path="/master/vendor/:action/" component={VendorFormIndex} />
    <Route
      exact
      path="/master/vendor/:action/:id"
      component={VendorFormIndex}
    />

    {/* Template */}
    <Route exact path="/master/template" component={Template} />
    <Route
      exact
      path="/master/template/history/:id"
      component={TemplateHistoryIndex}
    />
    <Route
      exact
      path="/master/template/:action/"
      component={TemplateFormIndex}
    />
    <Route
      exact
      path="/master/template/:action/:id"
      component={TemplateFormIndex}
    />

    {/* App Settings */}
    <Route exact path="/master/appsettings" component={AppSettingsIndex} />
  </Switch>
);
