/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';

const UserStatusHistoryData = () => {
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    data.push({
      hst_sta_id: 1,
      hst_sta_name: 'Active',
      hst_sta_modified_by: 'Admin',
      hst_modified_on: 'Date: 03/09/2021, Time: 01:12',
      hst_operation_type: 'Advance Changes',
    },
    {
      hst_sta_id: 2,
      hst_sta_name: 'Active',
      hst_sta_modified_by: 'Admin',
      hst_modified_on: 'Date: 04/09/2021, Time: 22:12',
      hst_operation_type: 'Basic Changes',
    },
    {
      hst_sta_id: 3,
      hst_sta_name: 'Active',
      hst_sta_modified_by: 'User',
      hst_modified_on: 'Date: 06/09/2021, Time: 05:55',
      hst_operation_type: 'Basic Changes',
    });
    setTableData(data);
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_sta_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'User Status Name',
        accessor: 'hst_sta_name',
      },
      {
        Header: 'Modified By',
        accessor: 'hst_sta_modified_by',
      },
      {
        Header: 'Modified Date',
        accessor: 'hst_modified_on',
      },
      {
        Header: 'Operation',
        accessor: 'hst_operation_type',
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default UserStatusHistoryData;
