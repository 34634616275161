/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const UserTypeHistoryData = () => {
  const [getUserData, setGetUserData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getUserData === null) {
    setGetUserData({
      ses_URL: "",
      ses_jwt: "",
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function historyData() {
      // axios.get(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
      await axios
        .get(
          `${getUserData.ses_URL}user_type/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/3/${params.id}`
        )
        .then((res) => {
          // eslint-disable-next-line array-callback-return
          Object.keys(res.data.data).map((keyName) => {
            // console.log(res.data.data);
            // console.log(res.data.status);
            data.push({
              hst_id: `${res.data.data[keyName].hst_id}`,
              hst_sp_usr_typ_name: `${res.data.data[keyName].hst_sp_usr_typ_name}`,
              hst_sp_usr_typ_code: `${res.data.data[keyName].hst_sp_usr_typ_code}`,
              hst_sp_usr_typ_remarks: `${res.data.data[keyName].hst_sp_usr_typ_remarks}`,
              hst_sp_usr_typ_status: `${res.data.data[keyName].hst_sp_usr_typ_status}`,
              hst_updated_on: `${res.data.data[keyName].hst_updated_on}`,
              hst_operation_type: `${res.data.data[keyName].hst_operation_type}`,
            });
          });
          console.log(data);
          setTableData(data);
          // window.location.reload(false);
        });
    }
    historyData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "hst_id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "User Type",
        accessor: "hst_sp_usr_typ_name",
      },
      {
        Header: "User Type Code",
        accessor: "hst_sp_usr_typ_code",
      },
      {
        Header: "Remarks",
        accessor: "hst_sp_usr_typ_remarks",
      },
      {
        Header: "Status",
        accessor: "hst_sp_usr_typ_status",
      },
      {
        Header: "Modified Date",
        accessor: "hst_updated_on",
      },
      {
        Header: "Operation",
        accessor: "hst_operation_type",
      },
    ],
    []
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default UserTypeHistoryData;
