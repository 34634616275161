import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CountryTableData from './components/Table/CountryTableData';
import CountryTableDesign from './components/Table/CountryTableDesign';

const DataTable = () => {
  const reactTableData = CountryTableData();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Country</h3>
          <h3 className="page-subhead subhead">
            Country List
          </h3>
        </Col>
      </Row>
      <Row>
        <CountryTableDesign reactTableData={reactTableData} />
      </Row>
    </Container>
  );
};

export default DataTable;
