
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PaymentTypeHistoryDesign from './PaymentTypeHistoryDesign';
import PaymentTypeHistoryData from './PaymentTypeHistoryData';



const PaymentTypeHistoryIndex = () => {
    const reactTableData = PaymentTypeHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Payment Type History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <PaymentTypeHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default PaymentTypeHistoryIndex;
