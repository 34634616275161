/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
// import { connect } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';
// import store from '../../../../App/store';

const MappingTableData = () => {
  // const testShowDataSet = () => {
  //   // dispatch(toDataSet());
  //   console.log(store.toDataSet());
  // };
  // const testShowDataGet = () => {
  //   // dispatch(toDataGet());
  //   console.log(store.toDataGet());
  // };
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const link = {
    toCreateLink: `/master/mapping/${params.proid}/view/`,
    toEditLink: `/master/mapping/${params.proid}/edit/`,
    toDeleteLink: `/master/mapping/${params.proid}`,
  };
  const [proName, setProName] = useState('Project');
  const [tableData, setTableData] = useState([]);

  async function loadMasterProjectFTPMapping() {
    await axios.get(`${getUserData.ses_URL}project/ftp_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/project/ftp_map/1/30888374364/1/0')
    .then((res) => {
      // testShowDataSet();
      // testShowDataGet();
      // eslint-disable-next-line array-callback-return
      // props.toDataSetAction('set data');
      // console.log(props.testData);
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(typeof (res.data.data[keyName].map_pro_pro_id));
        // console.log(typeof (params.proid));
        // const test = params.proid;
        // console.log(typeof (parseInt(params.proid, 10)));
        if ((res.data.data[keyName].map_pro_status !== 2) && (res.data.data[keyName].map_pro_pro_id) === parseInt(params.proid, 10)) {
        // if ((res.data.data[keyName].gn_pro_status !== 2)) {
        setProName(`${res.data.data[keyName].pro_name}`);
        // console.log(proName);
       data.push({
        map_pro_id: `${res.data.data[keyName].map_pro_id}`,
        pro_name: `${res.data.data[keyName].pro_name}`,
        ftp_acc_name: `${res.data.data[keyName].ftp_acc_name}`,
        map_pro_ftp_root_path: `${res.data.data[keyName].map_pro_ftp_root_path}`,
        sheet_acc_name: `${res.data.data[keyName].sheet_acc_name}`,
        map_pro_mapping_template: `${res.data.data[keyName].map_pro_mapping_template}`,
        status_name: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={res.data.data[keyName].map_pro_id}
             toSection="master-project-ftp"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].map_pro_id}`}
             noHistory
             toEditLink={`${link.toEditLink}${res.data.data[keyName].map_pro_id}`}
             toDeleteMethod={() => deleteMasterProjectFTPMapping(`${res.data.data[keyName].map_pro_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  async function deleteMasterProjectFTPMapping(deleteID) {
    // console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Project FTP?');
    if (confirmBox === true) {
      await axios.delete(`${getUserData.ses_URL}project/ftp_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      // axios.delete(`http://127.0.0.1:8000/project/ftp_map/1/30888374364/2/${deleteID}`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadMasterProjectFTPMapping();      
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    } 
  }
  useEffect(() => {
    loadMasterProjectFTPMapping();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'map_pro_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'FTP',
        accessor: 'ftp_acc_name',
      },
      {
        Header: 'FTP Root',
        accessor: 'map_pro_ftp_root_path',
      },
      {
        Header: 'Sheet',
        accessor: 'sheet_acc_name',
      },
      {
        Header: 'Mapping Template',
        accessor: 'map_pro_mapping_template',
      },
      {
        Header: 'Status',
        accessor: 'status_name',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData, projectName: proName };
  return reactTableData;
};

// const mapStateToProps = state => ({
//     testData: state.testData,
//   });

// const mapDispatchToProps = {
  
// };

export default MappingTableData;
// export default connect(mapStateToProps, mapDispatchToProps)(MappingTableData);
// export default withRouter(connect(state => ({
//   testData: state.testData,
// }))(MappingTableData));
// const mapStateToProps = state => ({
//   testData: state.testData,
// });

// const mapDispatchToProps = dispatch => ({
//   // toDataGetAction: () => dispatch(toDataGet()),
//   toDataSetAction: text => dispatch(toDataSet(text)),
// });
// const mapStateToProps = state => ({
//   testData: state.testData,
// });

// export default connect(mapStateToProps, null)(MappingTableData);

