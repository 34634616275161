import React, { useState } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import ReactTablePopup from "../../shared/components/table/components/ReactTablePopup";

const ActionGroupButton = ({
  btnID,
  toCreateLink,
  toHistoryLink,
  toEditLink,
  noDelete,
  noHistory,
  toDeleteMethod,
  toSection,
  toView, // Add this prop if ReactTablePopup requires a fileUrl
}) => {
  const [toViewState, settoViewState] = useState(
    toView !== "null" && toView !== undefined
  );
  const [getUserData, setGetUserData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getUserData === null) {
    setGetUserData({
      ses_URL: "",
      ses_jwt: "",
      gn_usr_id: "",
    });
  }
  // console.log(`${getUserData.ses_URL}${toView}`);

  return (
    <ButtonToolbar>
      <ButtonGroup className="btn-group--icons" dir="ltr">
        <Link to={toCreateLink}>
          <Button outline id={`btn-view-${toSection}-${btnID}`} color="success">
            <span className="lnr lnr-eye" />
          </Button>
        </Link>
        {!noHistory ? (
          <>
            <Link to={toHistoryLink}>
              <Button
                outline
                color="warning"
                id={`btn-history-${toSection}-${btnID}`}
              >
                <span className="lnr lnr-history" />
              </Button>
            </Link>
          </>
        ) : null}
        <Link to={toEditLink}>
          <Button outline color="primary" id={`btn-edit-${toSection}-${btnID}`}>
            <span className="lnr lnr-pencil" />
          </Button>
        </Link>
        {!noDelete ? (
          <>
            <div>
              <Button
                outline
                color="danger"
                onClick={toDeleteMethod}
                id={`btn-delete-${toSection}-${btnID}`}
              >
                <span className="lnr lnr-trash" />
              </Button>
            </div>
          </>
        ) : null}
        {toViewState ? (
          <ReactTablePopup fileUrl={`${getUserData.ses_URL}${toView}`} />
        ) : null}
      </ButtonGroup>
    </ButtonToolbar>
  );
} ;

export default ActionGroupButton;
