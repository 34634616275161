import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import UploadHistory from "./components/UploadHistory";
import UploadHistoryDesign from "./components/UploadHistoryDesign";

const Index = () => {
  const { t } = useTranslation("common");
  const reactTableData = UploadHistory();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t("master.uploadHistory.title")}</h3>
          <h3 className="page-subhead subhead">Uploaded History</h3>
        </Col>
      </Row>
      <Row>
        <UploadHistoryDesign reactTableData={reactTableData} />
      </Row>
    </Container>
  );
};

export default Index;
