/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faTrash, faPenToSquare, faSave, faPen, faPlus,
} from '@fortawesome/free-solid-svg-icons';

const VendorForm = ({ pristine, submitting }) => {
  const [getVendorData, setGetVendorData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getVendorData === null) {
    setGetVendorData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  // console.log(BasicData[0].url);
  const [isLoading, setLoading] = useState(true);
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [formData, setTestData] = useState({});
  const [defaultSelect, setDefaultSelect] = useState({});
  const [inputActive, setinputActive] = useState(false);
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  const {
    handleSubmit: handleSubmitMail, register: registerMail, reset: resetMail, control: controlMail, formState: { errorsMail },
  } = useForm();

  const [editedData, setEditedData] = useState([{
    sp_vendor_dir_code: '',
    sp_vendor_dir_name: '',
    sp_vendor_dir_contact: '',
    sp_vendor_dir_phone: '',
    sp_vendor_dir_address: '',
    sp_vendor_dir_liability_ins: '',
    sp_vendor_dir_ws_ins: '',
    sp_vendor_dir_status: '',
  }]);

  const [emailData, setEmailData] = useState([
    // { sp_vendor_contact_dir_id:0, sp_vendor_contact_mail: 'test@gamil.com', sp_vendor_contact_action_status: '1', email_state: 'save' },
    // { sp_vendor_contact_mail: 'test1@gamil.com', sp_vendor_contact_action_status: '2', email_state: 'save' },
    // { sp_vendor_contact_mail: 'test2@gamil.com', sp_vendor_contact_action_status: '1', email_state: 'save' },
    // { sp_vendor_contact_mail: 'test3@gamil.com', sp_vendor_contact_action_status: '1', email_state: 'save' },
    // { sp_vendor_contact_mail: 'test4@gamil.com', sp_vendor_contact_action_status: '1', email_state: 'save' },
  ]);
  const emailStatusDropdown = [
    { value: '0', label: 'Exclude' },
    { value: '1', label: 'Include' },
  ];
  const EmailListData = [];

  // Email list method
  async function loadEmailList() {
    await axios.get(`${getVendorData.ses_URL}vendor_contact/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/2/${params.id}`)
      // axios.get('http://127.0.0.1:8000/user_type/1/30888374364/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        console.log(res.data.data);
        Object.keys(res.data.data).map((keyName, index) => {
          if (res.data.data[keyName].sp_vendor_contact_status !== 2) {
            EmailListData.push({
              sp_vendor_contact_dir_id: `${res.data.data[keyName].sp_vendor_contact_dir_id}`,
              sp_vendor_contact_id: `${res.data.data[keyName].sp_vendor_contact_id}`,
              sp_vendor_contact_mail: `${res.data.data[keyName].sp_vendor_contact_mail}`,
              sp_vendor_contact_action_status: `${res.data.data[keyName].sp_vendor_contact_action_status}`,
              email_state: 'save',
            });
          }
        });
        console.log(EmailListData);
        setEmailData(EmailListData);
      });
  }

  const handleChangeEmail = (index, e) => {
    const updatedEmailData = [...emailData];
    updatedEmailData[index].sp_vendor_contact_mail = e.target.value;
    setEmailData(updatedEmailData);
  };
  const handleChangeState = (index, state) => {
    const updatedEmailData = [...emailData];
    updatedEmailData[index].email_state = state;
    setEmailData(updatedEmailData);
  };
  const handleChangeStatus = (index, selectedOption) => {
    const updatedEmailData = [...emailData];
    updatedEmailData[index].sp_vendor_contact_action_status = selectedOption.value;
    setEmailData(updatedEmailData);
  };
  const handleAddEmail = () => {
    setEmailData(prevEmailData => [
      ...prevEmailData,
      {
        sp_vendor_contact_dir_id: `${formData.sp_vendor_dir_id}`, sp_vendor_contact_id: '0', sp_vendor_contact_mail: '', sp_vendor_contact_action_status: '1', email_state: 'edit',
      },
    ]);
  };
  const handleDeleteEmail = (index, deleteID) => {
    axios.delete(`${getVendorData.ses_URL}vendor_contact/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          setEmailData((prevEmailData) => {
            const updatedEmailData = [...prevEmailData];
            updatedEmailData.splice(index, 1);
            return updatedEmailData;
          });
        } else {
          toast.error('Try Again!');
        }
      });
  };
  const onSubmitMailData = async (index, e) => {
    e.preventDefault();
    console.log('data', formData);
    const updatedEmailData = [...emailData];
    const email = updatedEmailData[index].sp_vendor_contact_mail.trim();
    const submittedData = updatedEmailData[index];
    console.log('data mail', submittedData);
    if (email === '') {
      alert('Email is required.');
    } else {
      handleChangeState(index, 'save');
      console.log('Submitting email:', email);

      const currentDate = new Date();
      // eslint-disable-next-line no-undef
      const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

      const emailSubmitData = [{
        sp_vendor_contact_dir_id: `${formData.sp_vendor_dir_id}`,
        sp_vendor_contact_code: formData.sp_vendor_dir_code,
        sp_vendor_contact_mail: submittedData.sp_vendor_contact_mail,
        sp_vendor_contact_action_status: submittedData.sp_vendor_contact_action_status,
        sp_vendor_contact_status: '1',
        sp_vendor_contact_modified_by: '1',
        sp_vendor_contact_modified_on: currentDateFormate,
      }];

      console.log('Submit', emailSubmitData);
      console.log('id', submittedData.sp_vendor_contact_id);

      let configAPI;
      if (submittedData.sp_vendor_contact_id === '0') {
        console.log('condition', 0);
        configAPI = axios.post(`${getVendorData.ses_URL}vendor_contact/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/1/0`, emailSubmitData);
      } else {
        console.log('condition', 1);
        configAPI = axios.put(`${getVendorData.ses_URL}vendor_contact/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/2/${submittedData.sp_vendor_contact_id}`, emailSubmitData);
      }
      await configAPI
        // axios.get('http://127.0.0.1:8000/user_type/1/30888374364/1/0')
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success('Email updated successfully');
          } else if (submittedData.sp_vendor_contact_id === '0') {
              toast.error(`${res.data.status_message}, Please try another`);
              setEmailData((prevEmailData) => {
                const deleteUnSavedData = [...prevEmailData];
                deleteUnSavedData.splice(index, 1);
                return deleteUnSavedData;
              });
            } else {
              toast.error(`${res.data.status_message}, Please try another`);
              handleChangeState(index, 'edit');
            }
        });
    }
    // console.log(submittedData);
  };

  async function onSubmitData(data) {
    console.log('submit', data);
    setEditedData(
      editedData[0].sp_vendor_dir_code = data.sp_vendor_dir_code,
      editedData[0].sp_vendor_dir_name = data.sp_vendor_dir_name,
      editedData[0].sp_vendor_dir_contact = data.sp_vendor_dir_contact,
      editedData[0].sp_vendor_dir_phone = data.sp_vendor_dir_phone,
      editedData[0].sp_vendor_dir_address = data.sp_vendor_dir_address,
      editedData[0].sp_vendor_dir_liability_ins = data.sp_vendor_dir_liability_ins,
      editedData[0].sp_vendor_dir_ws_ins = data.sp_vendor_dir_ws_ins,
      editedData[0].sp_vendor_dir_status = data.sp_vendor_dir_status,
    );
    if (inputActive === true) {
      setEditedData(editedData[0].sp_vendor_dir_status = '1');
    } else {
      setEditedData(editedData[0].sp_vendor_dir_status = '0');
    }
    setEditedData(editedData[0].sp_vendor_dir_modified_by = '1');
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    setEditedData(editedData[0].sp_vendor_dir_modified_on = currentDateFormate);
    // if (data.sp_vendor_dir_created_at === '') {
    //   // console.log('data is there');
    //   setEditedData(editedData[0].sp_vendor_dir_created_at = currentDateFormate);
    // } else {
    //   const crDate = new Date(data.sp_vendor_dir_created_at);
    //   setEditedData(editedData[0].sp_vendor_dir_created_at = `${crDate.getFullYear()}-${crDate.getMonth() + 1}-${crDate.getDate()}`);
    // }

    console.log(editedData);
    if (params.action === 'create') {
      await axios.post(`${getVendorData.ses_URL}vendor_directory/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/1/0`, editedData)
        // axios.post('http://127.0.0.1:8000/project/1/30888374364/1/0', editedData)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success('New Vendor added successfully');
            document.getElementById('backButton_master_vendor').click();
          } else {
            toast.error('Try Again!');
          }
        });
    } else if (params.action === 'edit') {
      await axios.put(`${getVendorData.ses_URL}vendor_directory/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/2/${params.id}`, editedData)
        // axios.put(`http://127.0.0.1:8000/project/1/30888374364/2/${params.id}`, editedData)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success('Vendor updated successfully');
            document.getElementById('backButton_master_vendor').click();
          } else {
            toast.error('Try Again!');
          }
          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          // setTestData(res.data.data[0]);
        });
    }
  }

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setDefaultSelect({
        lang: '',
        time: '',
        state: '',
        type: '',
      });
      setTestData({
        sp_vendor_dir_code: '',
        sp_vendor_dir_name: '',
        sp_vendor_dir_contact: '',
        sp_vendor_dir_phone: '',
        sp_vendor_dir_address: '',
        sp_vendor_dir_liability_ins: '',
        sp_vendor_dir_ws_ins: '',
        sp_vendor_dir_status: '',
      });
      setLoading(false);
      handleAddEmail();
    }, [params.id]);
    // setLoading(false);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      axios.get(`${getVendorData.ses_URL}vendor_directory/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/user/1/30888374364/2/${params.id}`)
        .then((res) => {
          // console.log(res.data.data);

          setTestData(res.data.data[0]);
          if (res.data.data[0].sp_vendor_dir_status === 1) {
            setinputActive(true);
          } else {
            setinputActive(false);
          }
          setLoading(false);
        });
      loadEmailList();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
    // reset(defaultSelect);
    // console.log(defaultSelect);
  }, [formData]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/master/vendor">
              <Button className="icon" id="backButton_master_vendor" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Vendor {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmitData)}>
            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_code">Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_vendor_dir_code"
                      type="text"
                      placeholder="Code"
                      id="sp_vendor_dir_code"
                      defaultValue={formData.sp_vendor_dir_code ? formData.sp_vendor_dir_code : ''}
                      {...register('sp_vendor_dir_code', {
                        required: 'Code is required',
                      })}
                    />
                    {errors?.sp_vendor_dir_code && <span className="form__form-group-error">{errors.sp_vendor_dir_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_name">Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_vendor_dir_name"
                      type="text"
                      placeholder="Name"
                      id="sp_vendor_dir_name"
                      defaultValue={formData.sp_vendor_dir_name ? formData.sp_vendor_dir_name : ''}
                      {...register('sp_vendor_dir_name', {
                        required: 'Name is required',
                      })}
                    />
                    {errors?.sp_vendor_dir_name && <span className="form__form-group-error">{errors.sp_vendor_dir_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_contact">Contact</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_vendor_dir_contact"
                      type="text"
                      placeholder="Contact"
                      id="sp_vendor_dir_contact"
                      defaultValue={formData.sp_vendor_dir_contact ? formData.sp_vendor_dir_contact : ''}
                      {...register('sp_vendor_dir_contact')}
                    />
                    {/* {errors?.sp_vendor_dir_contact && <span className="form__form-group-error">{errors.sp_vendor_dir_contact.message}</span>} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_phone">Mobile</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_vendor_dir_phone"
                      type="text"
                      placeholder="Mobile"
                      id="sp_vendor_dir_phone"
                      defaultValue={formData.sp_vendor_dir_phone ? formData.sp_vendor_dir_phone : ''}
                      {...register('sp_vendor_dir_phone')}
                    />
                    {/* {errors?.sp_vendor_dir_phone && <span className="form__form-group-error">{errors.sp_vendor_dir_phone.message}</span>} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_address">Address *</label>
                <div className="form__form-group-field">
                  <textarea
                    name="sp_vendor_dir_address"
                    type="text"
                    placeholder="Enter the text..."
                    id="sp_vendor_dir_address"
                    {...register('sp_vendor_dir_address')}
                    row="3"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_liability_ins">Liability Ins</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_vendor_dir_liability_ins"
                      type="textarea"
                      placeholder="Liability Ins"
                      id="sp_vendor_dir_liability_ins"
                      defaultValue={formData.sp_vendor_dir_liability_ins ? formData.sp_vendor_dir_liability_ins : ''}
                      {...register('sp_vendor_dir_liability_ins')}
                    />
                    {/* {errors?.sp_vendor_dir_liability_ins && <span className="form__form-group-error">{errors.sp_vendor_dir_liability_ins.message}</span>} */}
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="temp_type">Time Zone *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="temp_type"
                      id="temp_type"
                      control={control}
                      render={({ value, ref }) => (
                        <Select
                          defaultValue={timezone[0]}
                          inputRef={ref}
                          options={timezone}
                          components={animatedComponents}
                          value={timezone.find(option => option.value === value)}
                        />
                      )}
                      onChange={() => console.log('test')}
                    />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_vendor_dir_ws_ins">WC Ins</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_vendor_dir_ws_ins"
                      type="textarea"
                      placeholder="WC Ins"
                      id="sp_vendor_dir_ws_ins"
                      defaultValue={formData.sp_vendor_dir_ws_ins ? formData.sp_vendor_dir_ws_ins : ''}
                      {...register('sp_vendor_dir_ws_ins')}
                    />
                    {/* {errors?.sp_vendor_dir_ws_ins && <span className="form__form-group-error">{errors.sp_vendor_dir_ws_ins.message}</span>} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="sp_vendor_dir_status"
                          control={control}
                          value={inputActive}
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active"
                      {...register('sp_vendor_dir_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/master/vendor">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
          <div className="card__title col-sm-12 vendor-mail_list__head">
            <h5 className="bold-text mt-3 mr-2">Mail List</h5>
            <div className="vendor-mail_list">
              {
                (params.action === 'create' || params.action === 'edit')
                  ? (
                    <Button className="btn-mail_add btn-success btn-add" onClick={() => handleAddEmail()}>
                      <FontAwesomeIcon icon={faPlus} /> Add
                    </Button>
                  )
                  : ''
              }
            </div>
          </div>
          {emailData.map((data, index) => (
            <form className="form" onSubmit={e => onSubmitMailData(index, e)} key={index}>
              <div className="col-md-3">
                <div className="form__form-group">
                  <label className="form__form-group-label" htmlFor="sp_vendor_contact_mail">Email *</label>
                  <div className={`form__form-group-field ${data.email_state === 'save' ? 'input-disable' : ''}`}>
                    <div className="form__form-group-input-wrap">
                      <input
                        name="sp_vendor_contact_mail"
                        type="email"
                        // placeholder="abc@example.com"
                        id="sp_vendor_contact_mail"
                        value={data.sp_vendor_contact_mail ? data.sp_vendor_contact_mail : ''}
                        onChange={e => handleChangeEmail(index, e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form__form-group">
                  <label className="form__form-group-label" htmlFor="sp_vendor_contact_action_status">Mail Status *</label>
                  <div className={`form__form-group-field ${data.email_state === 'save' ? 'input-disable' : ''}`}>
                    <div className="form__form-group-input-wrap">
                      <Select
                        name="sp_vendor_contact_action_status"
                        id="sp_vendor_contact_action_status"
                        value={emailStatusDropdown.find(option => option.value === data.sp_vendor_contact_action_status)}
                        options={emailStatusDropdown}
                        onChange={selectedOption => handleChangeStatus(index, selectedOption)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="vendor-mail_list">
                {
                  (params.action === 'create' || params.action === 'edit')
                    ? (
                      <>
                        {
                          (data.email_state === 'save')
                            ? (
                              <>
                                <Button className="btn-mail_edit btn-primary" onClick={() => handleChangeState(index, 'edit')}>
                                  <FontAwesomeIcon icon={faPen} />
                                </Button>
                              </>
                            )
                            : ''
                        }
                        {
                          (data.email_state === 'edit')
                            ? (
                              <>
                                <Button className="btn-mail_save btn-success" type="submit">
                                  <FontAwesomeIcon icon={faSave} />
                                </Button>
                              </>
                            )
                            : ''
                        }
                        <Button className="btn-mail_delete btn-danger" onClick={() => handleDeleteEmail(index, data.sp_vendor_contact_id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </>
                    )
                    : ''
                }
              </div>
            </form>
          ))}
        </CardBody>
      </Card>
    </Col>
  );
};

export default VendorForm;
