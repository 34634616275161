import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserStateTableData from './components/Table/UserStateTableData';
import UserStateTableDesign from './components/Table/UserStateTableDesign';

const DataTable = () => {
    const reactTableData = UserStateTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">User State</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <UserStateTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
