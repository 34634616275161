
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import VendorHistoryDesign from './VendorHistoryDesign';
import VendorHistoryData from './VendorHistoryData';



const HistoryIndex = () => {
    const { t } = useTranslation('common');
    const reactTableData = VendorHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Vendor</h3>
            <h3 className="page-subhead subhead">
              History
            </h3>
          </Col>
        </Row>
        <Row>
          <VendorHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default HistoryIndex;
