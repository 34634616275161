/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const SchedulerHistoryData = () => {
  const [getSchedulerData, setGetSchedulerData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getSchedulerData === null) {
    setGetSchedulerData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function historyData() {
      // axios.get(`${getSchedulerData.ses_URL}ftp/1/${getSchedulerData.ses_jwt}/1/0`)
    await axios.get(`${getSchedulerData.ses_URL}schedule/${getSchedulerData.gn_usr_id}/${getSchedulerData.ses_jwt}/3/${params.id}`)
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
       data.push({
        hst_id: `${res.data.data[keyName].hst_id}`,
        hst_sp_sch_name: `${res.data.data[keyName].hst_sp_sch_name}`,
        hst_sp_sch_code: `${res.data.data[keyName].hst_sp_sch_code}`,
        hst_sp_sch_project_typ: `${res.data.data[keyName].hst_sp_sch_project_typ}`,
        hst_sp_sch_typ: `${res.data.data[keyName].hst_sp_sch_typ}`,
        hst_sp_sch_business_day: moment(`${res.data.data[keyName].hst_sp_sch_business_day}`).format('YYYY-MM-DD'),
        hst_sp_sch_prior: `${res.data.data[keyName].hst_sp_sch_prior}`,
        hst_sp_sch_remarks: `${res.data.data[keyName].hst_sp_sch_remarks}`,
        hst_sp_sch_status: `${res.data.data[keyName].hst_sp_sch_status}`,
        hst_updated_on: `${res.data.data[keyName].hst_updated_on}`,
        hst_operation_type: `${res.data.data[keyName].hst_operation_type}`,
         }); 
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
    }
historyData();
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Name',
        accessor: 'hst_sp_sch_name',
      },
      {
        Header: 'Code',
        accessor: 'hst_sp_sch_code',
      },
      {
        Header: 'Type',
        accessor: 'hst_sp_sch_project_typ',
      },
      {
        Header: 'Business Day',
        accessor: 'hst_sp_sch_business_day',
      },
      {
        Header: 'Prior Day',
        accessor: 'hst_sp_sch_prior',
      },
      {
        Header: 'Remarks',
        accessor: 'hst_sp_sch_remarks',
      },
      {
        Header: 'Status',
        accessor: 'hst_sp_sch_status',
        Cell: ({ value }) => (
          <div>
            {(value === '1')
              ? 'Active'
              : 'Inactive'}
          </div>
        ),
      },
      {
        Header: 'Modified Date',
        accessor: 'hst_updated_on',
      },
      // {
      //   Header: 'Operation',
      //   accessor: 'hst_operation_type',
      // },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default SchedulerHistoryData;
