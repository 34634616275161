import React from 'react';
import {
  DiJsBadge, DiCss3, DiHtml5, DiReact, 
} from 'react-icons/di';
import { 
  FaRegFilePdf, FaRegFileWord, FaRegFileExcel, FaRegFile, FaRegFileImage,
 } from 'react-icons/fa';

const FILE_ICONS = {
  js: <DiJsBadge />,
  css: <DiCss3 />,
  html: <DiHtml5 />,
  jsx: <DiReact />,
  pdf: <FaRegFilePdf />,
  xlsx: <FaRegFileExcel />,
  doc: <FaRegFileWord />,
  txt: <FaRegFile />,
  img: <FaRegFileImage />,
};

export default FILE_ICONS;
