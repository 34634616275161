/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonToolbar,
} from "reactstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { toast } from "react-toastify";
import DpProject from "../../../../Components/Dropdowns/DpProject";
import DpTemplate from "../../../../Components/Dropdowns/DpTemplate";
// import DpLanguage from '../../../../Components/Dropdowns/DpLanguage';
import Loader from "react-loader";

const FileType = [
  { value: "1", label: "Vendor" },
  { value: "2", label: "Process Payment" },
  { value: "3", label: "Template" },
  { value: "4", label: "Holiday List" },
  { value: "5", label: "Bank Property" },
];
const Project = DpProject;
const Template = DpTemplate;

const DataUploadForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getUserData === null) {
    setGetUserData({
      ses_URL: "",
      ses_jwt: "",
    });
  }

  const { t } = useTranslation("common");
  const animatedComponents = makeAnimated();

  const [selectedOptionType, setSelectedOptionType] = useState(1);
  const [formData, setFormData] = useState({});
  const [loader, setloader] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });

  const onSubmitData = async (data) => {
    console.log(data);
    setloader(true);
    const formDataForFileUpload = new FormData();

    console.log(data.du_file[0]);

    if (data.du_type.value === "1") {
      formDataForFileUpload.append("du_file", data.du_file[0]);
      formDataForFileUpload.append("du_type", data.du_type.value);
    } else if (data.du_type.value === "2") {
      formDataForFileUpload.append("du_file", data.du_file[0]);
      formDataForFileUpload.append("du_type", data.du_type.value);
      // formDataForFileUpload.append('du_project', data.du_project.value);
      formDataForFileUpload.append("du_project", "1");
    } else if (data.du_type.value === "3") {
      formDataForFileUpload.append("du_file", data.du_file[0]);
      formDataForFileUpload.append("du_type", data.du_type.value);
      formDataForFileUpload.append("du_temp_id", data.du_temp_id.value);
    } else if (data.du_type.value === "4") {
      formDataForFileUpload.append("du_file", data.du_file[0]);
      formDataForFileUpload.append("du_type", data.du_type.value);
    } else if (data.du_type.value === "5") {
      formDataForFileUpload.append("du_file", data.du_file[0]);
      formDataForFileUpload.append("du_type", data.du_type.value);
      formDataForFileUpload.append("du_project", "1");
    }

    formDataForFileUpload.append("du_file", data.du_file[0]);
    formDataForFileUpload.append("du_type", data.du_type.value);
    console.log(formDataForFileUpload);

    await axios
      .post(
        `${getUserData.ses_URL}data_upload/${getUserData.gn_usr_id}/${getUserData.ses_jwt}`,
        formDataForFileUpload
      )
      // axios.post('http://127.0.0.1:8000/project/1/30888374364/1/0', editedData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(
            `File uploaded successfully \n New Count:${res.data.new_count}  \n Updated Count:${res.data.updated_count} `
          );
          reset();
          setloader(false);
        } else if (res.data.status_code === 403) {
          toast.error(`${res.data.status_message}`);
          alert(`${res.data.status_message}`);
          setloader(false);
          // reset();
        } else {
          toast.error("Try Again!");
          // reset();
          setloader(false);
        }
      });
  };

  const handleChange = (event) => {
    console.log(event.value);
    setSelectedOptionType(event.value);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <h5 className="bold-text mt-3">Data Upload-Form</h5>
          </div>
          <form
            className="form"
            onSubmit={handleSubmit((data) => onSubmitData({ ...data }))}
          >
            {/* <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="du_type">File Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="du_type"
                      id="du_type"
                      control={control}
                      render={({ value, field, ref }) => (
                        <Select
                          {...field}
                          // inputRef={ref}
                          options={FileType}
                          defaultValue={FileType[0]}
                          components={animatedComponents}
                          onChange={(event) => {
                            console.log(event.value);
                            setSelectedOptionType(event.value);
                          }}
                        // value={FileType.find(option => option.value === value)}
                        />
                        // render={({ value, ref }) => (
                        //   <Select
                        //     defaultValue={FileType[0]}
                        //     inputRef={ref}
                        //     options={FileType}
                        //     onChange={handleChange}
                        //     components={animatedComponents}
                        //     value={FileType.find(option => option.value === value)}
                        //   />
                      )}
                      rules={{
                        required: 'File type is required',
                      }}
                    // onChange={() => console.log('test')}
                    />
                    {errors.du_type && <span className="form__form-group-error">{errors.du_type.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="du_type">
                  File Type *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="du_type"
                      id="du_type"
                      control={control}
                      render={({ value, field }) => (
                        <Select
                          {...field}
                          options={FileType}
                          components={animatedComponents}
                          // value={FileType.find(option => option.value === value)}
                          // value={selectedOptionType}
                          onChange={(event) => {
                            console.log(event.value);
                            setSelectedOptionType(event.value);
                            field.onChange(event);
                            // setSelectedOptionType(event.value);
                          }}
                          // onChange={(event) => {
                          //   console.log(event.value);
                          //   // console.log(selectedOption);
                          //   // field.onChange(selectedOption); // Manually trigger field's onChange function
                          // }}
                        />
                      )}
                      rules={{
                        required: "File Type is required",
                      }}
                    />
                    {errors.du_type && (
                      <span className="form__form-group-error">
                        {errors.du_type.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedOptionType === "2" && (
              <div className="col-md-4">
                <div className="form__form-group">
                  <label
                    className="form__form-group-label"
                    htmlFor="du_project"
                  >
                    Project *
                  </label>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <Controller
                        name="du_project"
                        id="du_project"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={Project}
                            components={animatedComponents}
                          />
                        )}
                        rules={{
                          required: "Project is required",
                        }}
                      />
                      {errors.du_project && (
                        <span className="form__form-group-error">
                          {errors.du_project.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedOptionType === "3" && (
              <div className="col-md-4">
                <div className="form__form-group">
                  <label
                    className="form__form-group-label"
                    htmlFor="du_temp_id"
                  >
                    Template Type *
                  </label>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <Controller
                        name="du_temp_id"
                        id="du_temp_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={Template}
                            components={animatedComponents}
                          />
                        )}
                        rules={{
                          required: "Template is required",
                        }}
                      />
                      {errors.du_temp_id && (
                        <span className="form__form-group-error">
                          {errors.du_temp_id.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="du_file">
                  File *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="du_file"
                      type="file"
                      id="du_file"
                      {...register("du_file", {
                        required: "Please choose the file to upload",
                      })}
                    />
                    {errors.du_file && (
                      <span className="form__form-group-error">
                        {errors.du_file.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                <Button color="success" type="submit">
                  Submit
                </Button>
              </ButtonToolbar>
            </div>
          </form>
          {loader ? <Loader loaded={!loader}>
            <div
              style={{ height: 100, width: 100, backgroundColor: "lightgray" }}
            >
              Loading...
            </div>
          </Loader>: null}
        </CardBody>
      </Card>
    </Col>
  );
};

export default DataUploadForm;
