
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TemplateHistoryDesign from './TemplateHistoryDesign';
import TemplateHistoryData from './TemplateHistoryData';



const TemplateHistoryIndex = () => {
    const reactTableData = TemplateHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Template History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <TemplateHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default TemplateHistoryIndex;
