/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
// import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const UserStateTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const data = [];
  const link = {
    toCreateLink: '/systemparameter/userstate/view/',
    // toHistoryLink: '/systemparameter/userstate/history/',
    toEditLink: '/systemparameter/userstate/edit/',
    // toDeleteLink: '/systemparameter/userstate',
  };
  const [tableData, setTableData] = useState([]);

  async function loadSysUserState() {
    await axios.get(`${getUserData.ses_URL}user_state/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/user_state/1/30888374364/1/0')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].usr_sta_status !== 2) {
       data.push({
        usr_sta_id: `${res.data.data[keyName].usr_sta_id}`,
        usr_sta_name: `${res.data.data[keyName].usr_sta_name}`,
        usr_sta_code: `${res.data.data[keyName].usr_sta_code}`,
           action: <ActionGroupButton
             btnID={`${res.data.data[keyName].usr_sta_id}`}
             toSection="sys-UserState"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].usr_sta_id}`}
            //  toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].usr_sta_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].usr_sta_id}`}
            //  toDeleteMethod={() => deleteSysUserState(`${res.data.data[keyName].usr_sta_id}`)}
             noDelete
             noHistory
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  // async function deleteSysUserState(deleteID) {
  //   console.log(deleteID);
  //   const confirmBox = window.confirm('Do you want to delete this UserState?');
  //   if (confirmBox === true) {
  //     await axios.delete(`${getUserData.ses_URL}user_state/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
  //     // axios.delete(`http://127.0.0.1:8000/user_state/1/30888374364/2/${deleteID}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       if (res.data.status_code === 200) {
  //         toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
  //         data.length = 0;
  //         setTableData([]);
  //         loadSysUserState();       
  //       } else {
  //         toast.error('Try Again!');
  //       }
  //     });
  //   } 
  // }
  useEffect(() => {
    loadSysUserState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'usr_sta_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'User State Name',
        accessor: 'usr_sta_name',
      },
      {
        Header: 'User State Code',
        accessor: 'usr_sta_code',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default UserStateTableData;
