
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import SheetAccountTypeForm from './SheetAccountTypeForm';
import showResults from '../../../../Form/Show';

const SheetAccountTypeFormIndex = () => {
  const params = useParams();
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Sheet Account Type</h3>
            <h3 className="page-subhead subhead">
              Sheet Type {params.action}
            </h3>
          </Col>
        </Row>
        <Row>
          <SheetAccountTypeForm onSubmit={showResults} />
        </Row>
      </Container>
    );    
};
  
export default SheetAccountTypeFormIndex;
