/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import DpProject from '../../../../Components/Dropdowns/DpProject';
import DpTemplate from '../../../../Components/Dropdowns/DpTemplate';
import DpVendor from '../../../../Components/Dropdowns/DpVendor';



const TemplateForm = ({ pristine, submitting }) => {
  const animatedComponents = makeAnimated();
  const [getTemplateData, setGetTemplateData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getTemplateData === null) {
    setGetTemplateData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const [isLoading, setLoading] = useState(true);
  const [formData, setTestData] = useState();
  // const [formData, setTestData] = useState({});
  const [inputActive, setinputActive] = useState(false);
  const [templateDefaultSelect, setTemplateDefaultSelect] = useState();
  const [vendorDefaultSelect, setVendorDefaultSelect] = useState();
  const [priorDefaultSelect, setPriorDefaultSelect] = useState();
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });

  const [receivedDate, setReceivedDate] = useState();

  const FileType = [
    { value: '1', label: 'Vendor' },
    { value: '2', label: 'Process Payment' },
    { value: '3', label: 'Template' },
    { value: '4', label: 'Holiday List' },
  ];

  const TemplateType = DpTemplate;
  const VendorType = DpVendor;

  // console.log(VendorType);

  const PriorDayDropdown = [
    { value: '0', label: '0' },
    { value: '-1', label: '-1' },
    { value: '-2', label: '-2' },
    { value: '-3', label: '-3' },
    { value: '-4', label: '-4' },
    { value: '-5', label: '-5' },
  ];

  const [editedData, setEditedData] = useState([{
    sp_sch_name: '',
    sp_sch_code: '',
    sp_sch_project_typ: '',
    sp_sch_mail_template: '',
    sp_sch_vendor_code: '',
    sp_sch_follow_up_day: '',
    sp_sch_prior: '',
    sp_sch_remarks: '',
    sp_sch_mail_subject: '',
    sp_sch_mail_body: '',
    sp_sch_status: '',
  }]);

  async function onSubmit(data) {
    console.log(data);
    // const editedData = [];
    console.log(inputActive);

    setEditedData(
      editedData[0].sp_sch_name = data.sp_sch_name,
      editedData[0].sp_sch_code = data.sp_sch_code,
      editedData[0].sp_sch_project_typ = 'Vendor Followup',
      editedData[0].sp_sch_typ = '0', // default value
      // editedData[0].sp_sch_follow_up_day = moment(data.sp_sch_follow_up_day).format('YYYY-MM-DD'),
      editedData[0].sp_sch_vendor_code = data.sp_sch_vendor_code1.value,
      editedData[0].sp_sch_follow_up_day = data.sp_sch_follow_up_day.toString(),
      editedData[0].sp_sch_prior = '0',// default value //data.sp_sch_prior1.value,
      editedData[0].sp_sch_mail_template = data.sp_sch_mail_template1.value,
      editedData[0].sp_sch_remarks = data.sp_sch_remarks,
      editedData[0].sp_sch_mail_subject = data.sp_sch_mail_subject,
      editedData[0].sp_sch_mail_body = data.sp_sch_mail_body,
      editedData[0].sp_sch_modified_by = '1',
    );



    if (inputActive === true) {
      setEditedData(editedData[0].sp_sch_status = '1');
    } else {
      setEditedData(editedData[0].sp_sch_status = '0');
    }
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    setEditedData(editedData[0].sp_sch_modified_on = currentDateFormate);

    console.log('send data', editedData);

    if (params.action === 'create') {
      await axios.post(`${getTemplateData.ses_URL}schedule/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/1/0`, editedData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success('Template updated successfully');
            document.getElementById('backButton_sys_Template').click();
          } else {
            toast.error('Try Again!');
          }
        });
    } else if (params.action === 'edit') {
      await axios.put(`${getTemplateData.ses_URL}schedule/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/2/${params.id}`, editedData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            // alert('Template updated successfully');
            toast.success('Template updated successfully');
            document.getElementById('backButton_sys_Template').click();
          } else {
            // alert('Try Again!');
            toast.error('Try Again!');
          }
          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          // setTestData(res.data.data[0]);
        });
    }
  }

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setTestData({
        sp_sch_name: '',
        sp_sch_code: '',
        sp_sch_project_typ: 'Vendor Followup',
        sp_sch_mail_template: '',
        sp_sch_vendor_code: '',
        sp_sch_follow_up_day: '',
        sp_sch_prior: '',
        sp_sch_remarks: '',
        sp_sch_mail_subject: '',
        sp_sch_mail_body: '',
        sp_sch_status: '',
      });
      setLoading(false);
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      axios.get(`${getTemplateData.ses_URL}schedule/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/2/${params.id}`)
        .then((res) => {
          // console.log(res.data.data[0]);
          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          // setReceivedDate(res.data.data[0].hst_sp_sch_follow_up_day !== 'null' ? new Date(res.data.data[0].sp_sch_follow_up_day) : '');

          // const dateString = res.data.data[0].sp_sch_follow_up_day;
          // const dateObj = new Date(dateString);
          // const month = dateObj.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0-11)
          // const day = dateObj.getDate();
          // const year = dateObj.getFullYear();


          // const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
          // console.log(formattedDate);

          // setReceivedDate(new Date(res.data.data[0].sp_sch_follow_up_day));

          setTemplateDefaultSelect(TemplateType.findIndex(item => item.value === res.data.data[0].sp_sch_mail_template.toString()));
          if (res.data.data[0].sp_sch_vendor_code !== null) {
            setVendorDefaultSelect(VendorType.findIndex(item => item.value === res.data.data[0].sp_sch_vendor_code.toString()));
          } else {
            setVendorDefaultSelect('0');
          }
          setPriorDefaultSelect(PriorDayDropdown.findIndex(item => item.value === res.data.data[0].sp_sch_prior.toString()));

          setTestData(res.data.data[0]);
          if (res.data.data[0].sp_sch_status === 1) {
            setinputActive(true);
          } else {
            setinputActive(false);
          }
          setLoading(false);
        });
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);


  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/master/template">
              <Button className="icon" id="backButton_sys_Template" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Template {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_name">Template Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_sch_name"
                      type="text"
                      placeholder="Template"
                      id="sp_sch_name"
                      defaultValue={formData.sp_sch_name ? formData.sp_sch_name : ''}
                      {...register('sp_sch_name', {
                        required: 'Template name is required',
                      })}
                    />
                    {errors?.sp_sch_name && <span className="form__form-group-error">{errors.sp_sch_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_code">Template Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_sch_code"
                      type="text"
                      placeholder="Template Code"
                      id="sp_sch_code"
                      defaultValue={formData.sp_sch_code ? formData.sp_sch_code : ''}
                      {...register('sp_sch_code', {
                        required: 'Template Code is required',
                      })}
                    />
                    {errors?.sp_sch_code && <span className="form__form-group-error">{errors.sp_sch_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_project_typ">Project Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="sp_sch_project_typ"
                      id="sp_sch_project_typ"
                      control={control}
                      defaultValue={FileType[defaultSelect.project]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={FileType}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Project Type is required',
                      }}
                    />
                    {errors.sp_sch_project_typ && <span className="form__form-group-error">{errors.sp_sch_project_typ.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_mail_template1">Template Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="sp_sch_mail_template1"
                      id="sp_sch_mail_template1"
                      control={control}
                      defaultValue={TemplateType[templateDefaultSelect]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={TemplateType}
                          components={animatedComponents}
                          onChange={(event) => {
                            field.onChange(event);
                          }}
                        />
                      )}
                      rules={{
                        required: 'Template is required',
                      }}
                    />
                    {errors.sp_sch_mail_template1 && <span className="form__form-group-error">{errors.sp_sch_mail_template1.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_vendor_code1">Vendor *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="sp_sch_vendor_code1"
                      id="sp_sch_vendor_code1"
                      control={control}
                      defaultValue={VendorType[vendorDefaultSelect]}
                      isSearchable
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={VendorType}
                          components={animatedComponents}
                          onChange={(event) => {
                            field.onChange(event);
                          }}
                        />
                      )}
                      rules={{
                        required: 'Vendor is required',
                      }}
                    />
                    {errors.sp_sch_vendor_code1 && <span className="form__form-group-error">{errors.sp_sch_vendor_code1.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_follow_up_day">Followup Day *</label>
                <div className="form__form-group-field date-full">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sp_sch_follow_up_day"
                      type="text"
                      placeholder="0"
                      id="sp_sch_follow_up_day"
                      // defaultValue={formData.sp_sch_follow_up_day ? formData.sp_sch_follow_up_day : ''}
                      {...register('sp_sch_follow_up_day', {
                        required: 'Followup Day is required',
                      })}
                    />
                    {errors?.sp_sch_follow_up_day && <span className="form__form-group-error">{errors.sp_sch_follow_up_day.message}</span>}
                  </div>
                  {/* <div className="form__form-group-input-wrap">
                    <Controller
                      name="sp_sch_follow_up_day"
                      id="sp_sch_follow_up_day"
                      control={control}
                      rules={{ required: 'Followup date required' }}
                      defaultValue={receivedDate}
                      placeholder="MM/DD/YYYY"
                      render={({ value, ref, field }) => (
                        <DatePicker
                          {...field}
                          inputRef={ref}
                          selected={receivedDate}
                          onChange={(date) => {
                            setReceivedDate(date);
                            field.onChange(date);
                          }}
                        />
                      )}
                    />
                    {errors.sp_sch_follow_up_day && <span className="form__form-group-error">{errors.sp_sch_follow_up_day.message}</span>}
                  </div> */}
                </div>
              </div>
            </div>

           {/* <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_prior1">Prior Day *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="sp_sch_prior1"
                      id="sp_sch_prior1"
                      control={control}
                      defaultValue={PriorDayDropdown[priorDefaultSelect]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={PriorDayDropdown}
                          components={animatedComponents}
                          onChange={(event) => {
                            field.onChange(event);
                          }}
                        />
                      )}
                      rules={{
                        required: 'Prior Day is required',
                      }}
                    />
                    {errors.sp_sch_prior1 && <span className="form__form-group-error">{errors.sp_sch_prior1.message}</span>}
                  </div>
                </div>
              </div>
                    </div>*/}

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sp_sch_remarks">Template Remarks</label>
                <div className="form__form-group-field">
                  <textarea
                    name="sp_sch_remarks"
                    type="text"
                    placeholder="Enter the text..."
                    id="sp_sch_remarks"
                    {...register('sp_sch_remarks')}
                    row="3"
                  />
                </div>
              </div>
            </div>

            <div className="row col-sm-12 Template-mail_section">
              <div className="col-sm-12 pb-2">
                <div className="sms-head">Mail Section</div>
                <div><span className="sms-data">Note:</span> Please use <span className="text-bold">$year</span> for year, <span className="text-bold">$month</span> for month, <span className="text-bold">$vendor</span> for vendor name</div>
              </div>
              <div className="col-md-6">
                <div className="form__form-group">
                  <label className="form__form-group-label" htmlFor="sp_sch_mail_subject">Mail Subject</label>
                  <div className="form__form-group-field">
                    <textarea
                      name="sp_sch_mail_subject"
                      type="text"
                      placeholder="Enter the text..."
                      id="sp_sch_mail_subject"
                      {...register('sp_sch_mail_subject')}
                      row="3"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form__form-group">
                  <label className="form__form-group-label" htmlFor="sp_sch_mail_body">Mail Body</label>
                  <div className="form__form-group-field">
                    <textarea
                      name="sp_sch_mail_body"
                      type="text"
                      placeholder="Enter the text..."
                      id="sp_sch_mail_body"
                      {...register('sp_sch_mail_body')}
                      row="3"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="sp_sch_status"
                          control={control}
                          value={inputActive}
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active"
                      {...register('sp_sch_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/master/template">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TemplateForm;
