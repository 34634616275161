
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import VendorForm from './VendorForm';
import showResults from '../../../../Form/Show';


const FormIndex = () => {
  const params = useParams();
  // console.log(params.action);
  const { t } = useTranslation('common');
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('Vendor')}</h3>
            <h3 className="page-subhead subhead">
              {params.action}
            </h3>
          </Col>
        </Row>
        <Row>
          <VendorForm onSubmit={showResults} />
        </Row>
      </Container>
    );    
};
  

export default FormIndex;
