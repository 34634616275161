import axios from 'axios';

const DpPropertyReport = () => {
    const data = [];
    const uniqueLabels = new Set();
    const getUserData = JSON.parse(localStorage.getItem('hismartData'));

    async function dropDownData() {
        await axios.get(`${getUserData.ses_URL}invoices/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
            .then((res) => {
                // eslint-disable-next-line array-callback-return
                Object.keys(res.data.data).map((keyName) => {
                    // if (res.data.data[keyName].sp_tracker_status_status === 1) {
                    // console.log(res.data.data);
                    if (res.data.data[keyName].trans_invoices_follow_up_history_dates !== null) {
                        const label = res.data.data[keyName].trans_invoices_property_name;
                        if (!uniqueLabels.has(label)) {
                            uniqueLabels.add(label);
                            data.push({
                                value: `${res.data.data[keyName].trans_invoices_property_name}`,
                                label: label,
                            });
                        }
                    }
                    // }
                });
            });
    }

    if (getUserData !== null) {
        dropDownData();
    }

    return data;
};

export default DpPropertyReport();
