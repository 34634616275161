import React from 'react';
import {
    Button,
    ButtonToolbar,
    
} from 'reactstrap';
import { Link } from 'react-router-dom';
import FolderCogOutlineIcon from 'mdi-react/FolderCogOutlineIcon';
import FileCogOutlineIcon from 'mdi-react/FileCogOutlineIcon';

const CustomActionButton = ({
    // eslint-disable-next-line react/prop-types
    btnID, goToLink, iconName, iconToolTip, iconColor,
}) => (
  <ButtonToolbar>
    <Link to={goToLink}>
      <Button id={`btn-map-${btnID}`} color={`${iconColor} btn-icon__small`}>
        {
            // eslint-disable-next-line consistent-return
            (() => {
                if (iconName === 'FolderCogOutlineIcon') {
                    return (
                      <FolderCogOutlineIcon style={{ width: 18, height: 18 }} />
                    );
                    // eslint-disable-next-line no-else-return
                } else if (iconName === 'FileCogOutlineIcon') {
                    return (
                      <FileCogOutlineIcon style={{ width: 18, height: 18 }} />
                    );
                    // eslint-disable-next-line no-empty
                }
            })()
        }
      </Button>
      {/* <UncontrolledTooltip placement="top" target={`btn-map-${btnID}`}>
        {iconToolTip}
      </UncontrolledTooltip> */}
    </Link>
  </ButtonToolbar>
);

export default CustomActionButton;
