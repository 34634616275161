/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';

const UserStatusForm = ({ pristine, submitting }) => {
  const params = useParams();
  const [formData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  const onSubmit = data => console.log(data);

  if (params.action !== 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setTestData({
        sta_name: 'Active',
        sta_code: 'AC',
        sta_remarks: 'Text',
      });
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/systemparameter/userstatus">
              <Button className="icon" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">User Status {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sta_name">User Status Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sta_name"
                      type="text"
                      placeholder="Name"
                      id="sta_name"
                      defaultValue={formData.sta_name ? formData.sta_name : ''}
                      {...register('sta_name', {
                        required: 'User Status name is required',
                      })}
                    />
                    {errors?.sta_name && <span className="form__form-group-error">{errors.sta_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sta_code">User Status Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="sta_code"
                      type="text"
                      placeholder="Code"
                      id="sta_code"
                      defaultValue={formData.sta_code ? formData.sta_code : ''}
                      {...register('sta_code', {
                        required: 'User Status Code is required',
                      })}
                    />
                    {errors?.sta_code && <span className="form__form-group-error">{errors.sta_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sta_remarks">User Status Remarks</label>
                <div className="form__form-group-field">
                  <textarea
                    name="sta_remarks"
                    type="text"
                    placeholder="Enter the text..."
                    id="sta_remarks"
                    {...register('sta_remarks')}
                    row="3"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/systemparameter/userstatus">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserStatusForm;
