
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserTypeHistoryDesign from './UserTypeHistoryDesign';
import UserTypeHistoryData from './UserTypeHistoryData';



const UserTypeHistoryIndex = () => {
    const reactTableData = UserTypeHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">User Type History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <UserTypeHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default UserTypeHistoryIndex;
