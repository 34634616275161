/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const FTPAccountHistoryData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function historyData() {
      await axios.get(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/3/${params.id}`)
    // axios.get(`http://127.0.0.1:8000/ftp/1/30888374364/3/${params.id}`)
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
       data.push({
        hst_id: `${res.data.data[keyName].hst_id}`,
        hst_gn_ftp_acc_name: `${res.data.data[keyName].hst_gn_ftp_acc_name}`,
        hst_gn_ftp_acc_code: `${res.data.data[keyName].hst_gn_ftp_acc_code}`,
        hst_gn_ftp_acc_type: `${res.data.data[keyName].hst_gn_ftp_acc_type}`,
        hst_gn_ftp_acc_remarks: `${res.data.data[keyName].hst_gn_ftp_acc_remarks}`,
        hst_gn_ftp_acc_status: `${res.data.data[keyName].hst_gn_ftp_acc_status}`,
        hst_updated_on: `${res.data.data[keyName].hst_updated_on}`,
        hst_opreation_type: `${res.data.data[keyName].hst_opreation_type}`,
         }); 
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
    }
historyData();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'FTP',
        accessor: 'hst_gn_ftp_acc_name',
      },
      {
        Header: 'FTP Code',
        accessor: 'hst_gn_ftp_acc_code',
      },
      {
        Header: 'FTP Type',
        accessor: 'hst_gn_ftp_acc_type',
      },
      {
        Header: 'Remarks',
        accessor: 'hst_gn_ftp_acc_remarks',
      },
      {
        Header: 'Status',
        accessor: 'hst_gn_ftp_acc_status',
      },
      {
        Header: 'Modified Date',
        accessor: 'hst_updated_on',
      },
      {
        Header: 'Operation',
        accessor: 'hst_opreation_type',
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default FTPAccountHistoryData;
