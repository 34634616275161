/* eslint-disable max-len */
import React, { useState } from 'react';
import { 
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
// import { CgFolder, CgFileAdd, CgFileDocument } from 'react-icons/cg';
import NotifiactionModal from '../../Components/CustomNotificationModel';
import MatTableHead from './NotificationTableHead';
import MatTableToolbar from './NotificationTableToolbar';

let counter = 0;


const createData = (notification, date, action) => {
  counter += 1;
  return {
    id: counter, notification, date, action,
  };
};

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const ModelNotification = (nmessage, ndate) => (
  <NotifiactionModal
    color="primary"
    title={ndate}
    header
    btn="View"
    message={nmessage}
  />
);

const NotificationTable = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([
    createData('Cristoper has started a new project', 'September 30, 2021 17:23', ModelNotification('New project added and 10 sheet assigned to that project.', 'September 30, 2021 17:23')),
    createData('Sveta has closed a project', 'September 30, 2021 17:23', ModelNotification('New project added and 10 sheet assigned to that project.', 'September 30, 2021 17:23')), 
    createData('New File added in the Project 1', 'September 30, 2021 17:23', ModelNotification('New project added and 10 sheet assigned to that project.', 'September 30, 2021 17:23')),
    createData('One more sheet added in the Sheet 1 worksheet', 'September 30, 2021 17:23', ModelNotification('New project added and 10 sheet assigned to that project.', 'September 30, 2021 17:23')),
    createData('Project2 reached the 50 sheets', 'September 30, 2021 17:23', ModelNotification('New project added and 10 sheet assigned to that project.', 'September 30, 2021 17:23')),
  ]);

  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = 'desc';
    if (orderBy === property && order === 'desc') { orderTemp = 'asc'; }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const isSelected = id => !!selected.get(id);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">All Notification</h5>
          </div>
          <MatTableToolbar
            numSelected={[...selected].filter(el => el[1]).length}
            handleDeleteSelected={handleDeleteSelected}
            onRequestSort={handleRequestSort}
          />
          <div className="material-table__wrap">
            <Table className="material-table notification-table">
              <MatTableHead
                numSelected={[...selected].filter(el => el[1]).length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                  .map((d) => {
                    const select = isSelected(d.id);
                    return (
                      <TableRow
                        className="material-table__row msg-notview"
                        role="checkbox"
                        onClick={event => handleClick(event, d.id)}
                        aria-checked={select}
                        tabIndex={-1}
                        key={d.id}
                        selected={select}
                      >
                        <TableCell className="material-table__cell" padding="checkbox">
                          <Checkbox checked={select} className="material-table__checkbox" />
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.notification}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.date}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right notifi-view__btn"
                        >{d.action}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            dir="ltr"
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default NotificationTable;
