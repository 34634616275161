/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { AiOutlineFile } from 'react-icons/ai';

// eslint-disable-next-line import/no-cycle
import { FolderName } from './Folder/TreeFolder';
import { StyledFolder } from './Folder/TreeFolder.style';
import { StyledFile } from './File/TreeFile.style';
import FILE_ICONS from './FileIcons';

const FileEdit = ({
 // eslint-disable-next-line react/prop-types
 ext, inputRef, updateExt, defaultValue, style, 
}) => {
  const extension = FILE_ICONS[ext] ? FILE_ICONS[ext] : <AiOutlineFile />;

  return (
    <StyledFile className="tree__file" style={style}>
      {extension}
      &nbsp;&nbsp;
      <input
        ref={inputRef}
        onChange={updateExt}
        defaultValue={defaultValue}
        className="tree__input"
      />
    </StyledFile>
  );
};

const FolderEdit = ({
 name, inputRef, defaultValue, style, 
}) => (
  <StyledFolder id={v4()} name={name} style={style}>
    <FolderName
      isOpen
      handleClick={() => {}}
      name={(
        <input
          ref={inputRef}
          className="tree__input"
          defaultValue={defaultValue}
        />
        )}
    />
  </StyledFolder>
  );

const PlaceholderInput = ({
  type,
  name,
  onSubmit,
  onCancel,
  defaultValue,
  style,
}) => {
  const [ext, setExt] = useState('');
  const inputRef = useRef();

  const updateExt = (e) => {
    const splitted = e.target.value.split('.');
    const ext = splitted && splitted[splitted.length - 1];
    setExt(ext);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
    inputRef.current.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') onSubmit(e.target.value);
      if (e.key === 'Escape') {
        // eslint-disable-next-line no-unused-expressions
        onCancel && onCancel();
      }
    });
  }, [inputRef]);

  return type === 'file' ? (
    <FileEdit
      ext={ext}
      style={style}
      updateExt={updateExt}
      inputRef={inputRef}
      defaultValue={defaultValue}
    />
  ) : (
    <FolderEdit
      style={style}
      name={name}
      inputRef={inputRef}
      defaultValue={defaultValue}
    />
  );
};

// eslint-disable-next-line import/prefer-default-export
export { PlaceholderInput };
