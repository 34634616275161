/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import {
 Col, Container, Row, Breadcrumb, BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MappingTableData from './components/Table/MappingTableData';
import MappingTableDesign from './components/Table/MappingDesign';
import { toDataSet } from '../../../redux/custom/actions/testActions';

const DataTable = () => {
    const reactTableData = MappingTableData();

    useEffect(() => {
      // console.log(props.testData);
      // props.toDataSetAction('set data');
      // console.log(props.testData);
      // props.toDataGetAction();
      // console.log(props.testData);
    }, []);
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Mapping Project</h3>
            {/* <h3 className="page-subhead subhead">
              List
            </h3> */}
            <Breadcrumb className="mt-2">
              <BreadcrumbItem>
                <Link to="/master/project">
                  Project
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Mapping Project - List</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <MappingTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };

  // const mapStateToProps = (state) => {
  //   const todoElements = state.todo;
  //   return ({
  //     newData: state.testData.dataOne,
  //   });
  // };
  const mapStateToProps = state => ({
      testData: state.testData,
    });

  const mapDispatchToProps = dispatch => ({
      // toDataGetAction: () => dispatch(toDataGet()),
      toDataSetAction: text => dispatch(toDataSet(text)),
    });
  
  export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
