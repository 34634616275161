/* eslint-disable max-len */
import axios from 'axios';

const DpEmailFilterTemplate = () => {
    const data = [];
    const getUserData = JSON.parse(localStorage.getItem('hismartData'));
    // console.log(getUserData);
    async function dropDownData() {
        await axios.get(`${getUserData.ses_URL}schedule/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
        .then((res) => {
            // eslint-disable-next-line array-callback-return
            Object.keys(res.data.data).map((keyName) => {
                if (res.data.data[keyName].sp_sch_status === 1) {
                // console.log(res.data.data);
               data.push({
                // value: `${(res.data.data[keyName].sp_sch_vendor_code !== null) ? res.data.data[keyName].sp_sch_vendor_code : '0'}`,
                value: `${res.data.data[keyName].sp_sch_id}`,
                selectedValue: `${res.data.data[keyName].sp_sch_vendor_code}`,
                label: `${res.data.data[keyName].sp_sch_name} - ${res.data.data[keyName].sp_sch_temp_typ_name}`,
                 }); 
                }
               }); 
        });
    }
    if (getUserData !== null) {
        dropDownData();
    }
    return data;
};
export default DpEmailFilterTemplate();
