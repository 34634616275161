/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const FTPAccountTypeHistoryData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
   async function historyData() {
    await axios.get(`${getUserData.ses_URL}ftp_account_type/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/3/${params.id}`)
    // axios.get(`http://127.0.0.1:8000/ftp_account_type/1/30888374364/3/${params.id}`)
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
       data.push({
        hst_id: `${res.data.data[keyName].hst_id}`,
        hst_sp_ftp_acc_typ_name: `${res.data.data[keyName].hst_sp_ftp_acc_typ_name}`,
        hst_sp_ftp_acc_typ_code: `${res.data.data[keyName].hst_sp_ftp_acc_typ_code}`,
        hst_sp_ftp_acc_typ_remarks: `${res.data.data[keyName].hst_sp_ftp_acc_typ_remarks}`,
        hst_sp_ftp_acc_typ_status: `${res.data.data[keyName].hst_sp_ftp_acc_typ_status}`,
        hst_updated_on: `${res.data.data[keyName].hst_updated_on}`,
        hst_operation_type: `${res.data.data[keyName].hst_operation_type}`,
         }); 
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
   }
   historyData();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Account Type',
        accessor: 'hst_sp_ftp_acc_typ_name',
      },
      {
        Header: 'Account Code',
        accessor: 'hst_sp_ftp_acc_typ_code',
      },
      {
        Header: 'Remarks',
        accessor: 'hst_sp_ftp_acc_typ_remarks',
      },
      {
        Header: 'Status',
        accessor: 'hst_sp_ftp_acc_typ_status',
      },
      {
        Header: 'Modified Date',
        accessor: 'hst_updated_on',
      },
      {
        Header: 'Operation',
        accessor: 'hst_operation_type',
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default FTPAccountTypeHistoryData;
