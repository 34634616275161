import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FTPAccountTableData from './components/Table/FTPAccountTableData';
import FTPAccountTableDesign from './components/Table/FTPAccountTableDesign';

const DataTable = () => {
    const reactTableData = FTPAccountTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">FTP Account</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <FTPAccountTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
