/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';
import CustomActionButton from '../../../../Components/CustomActionButton';

const FTPAccountTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const iColor = 'primary';
  const data = [];
  const link = {
    toCreateLink: '/master/ftpaccount/view/',
    toHistoryLink: '/master/ftpaccount/history/',
    toEditLink: '/master/ftpaccount/edit/',
    // toDeleteLink: '/master/ftpaccount',
    goToLink: '/filemanager/filesection/',
  };
  const [tableData, setTableData] = useState([]);

  async function loadMasterFTP() {
    await axios.get(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/ftp/1/30888374364/1/0')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].gn_ftp_acc_status !== 2) {
       data.push({
        gn_ftp_acc_id: `${res.data.data[keyName].gn_ftp_acc_id}`,
        gn_ftp_acc_name: `${res.data.data[keyName].gn_ftp_acc_name}`,
        gn_ftp_acc_code: `${res.data.data[keyName].gn_ftp_acc_code}`,
        acc_type_name: `${res.data.data[keyName].acc_type_name}`,
        gn_acc_link: <CustomActionButton btnID="1" iconName="FolderCogOutlineIcon" goToLink={`${link.goToLink}1`} iconToolTip="FTP File Manager" iconColor={iColor} />,
        status_name: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={res.data.data[keyName].gn_ftp_acc_id}
             toSection="master-ftp"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].gn_ftp_acc_id}`}
             toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].gn_ftp_acc_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].gn_ftp_acc_id}`}
             toDeleteMethod={() => deleteMasterFTP(`${res.data.data[keyName].gn_ftp_acc_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  async function deleteMasterFTP(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this FTP Account?');
    if (confirmBox === true) {
      await axios.delete(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      // axios.delete(`http://127.0.0.1:8000/ftp/1/30888374364/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadMasterFTP();    
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    } 
  }
  useEffect(() => {
    loadMasterFTP();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'gn_ftp_acc_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'FTP Account Name',
        accessor: 'gn_ftp_acc_name',
      },
      {
        Header: 'FTP Account Code',
        accessor: 'gn_ftp_acc_code',
      },
      {
        Header: 'FTP Account Type',
        accessor: 'acc_type_name',
      },
      {
        Header: 'FTP Manager',
        accessor: 'gn_acc_link',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status_name',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default FTPAccountTableData;
