/* eslint-disable max-len */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import FileSection from '../../../FileManager/index';
import SheetManagerIndex from '../../../SheetManager/SheetManagerIndex';


export default () => (
  <Switch>
    {/* <Route exact path="/filemanager/filesection/:id" component={FileSection} /> */}
    <Route exact path="/sheetmanager/sheetsection/:id" component={SheetManagerIndex} />
  </Switch>
);
