/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const PaymentTypeTableData = () => {
  // const getPaymentData = JSON.parse(localStorage.getItem('hismartData'));
  // const [isLoading, setLoading] = useState(true);
  const [getPaymentData, setGetPaymentData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getPaymentData === null) {
    setGetPaymentData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const data = [];
  const link = {
    toCreateLink: '/systemparameter/paymenttype/view/',
    toHistoryLink: '/systemparameter/paymenttype/history/',
    toEditLink: '/systemparameter/paymenttype/edit/',
    // toDeleteLink: '/systemparameter/paymenttype',
  };
  const [tableData, setTableData] = useState([]);

  async function loadSysPaymentType() {
    await axios.get(`${getPaymentData.ses_URL}payment_type/${getPaymentData.gn_usr_id}/${getPaymentData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/payment_type/1/30888374364/1/0')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].sp_payment_typ_status !== 2) {
       data.push({
        payment_typ_id: `${res.data.data[keyName].sp_payment_typ_id}`,
        payment_typ_name: `${res.data.data[keyName].sp_payment_typ_name}`,
        payment_typ_code: `${res.data.data[keyName].sp_payment_typ_code}`,
        payment_typ_remarks: `${res.data.data[keyName].sp_payment_typ_remarks}`,
        payment_typ_status: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={`${res.data.data[keyName].sp_payment_typ_id}`}
             toSection="sys-PaymentType"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].sp_payment_typ_id}`}
             toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].sp_payment_typ_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].sp_payment_typ_id}`}
             toDeleteMethod={() => deleteSysPaymentType(`${res.data.data[keyName].sp_payment_typ_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
    });
  }
  async function deleteSysPaymentType(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Payment type?');
    if (confirmBox === true) {
      await axios.delete(`${getPaymentData.ses_URL}payment_type/${getPaymentData.gn_usr_id}/${getPaymentData.ses_jwt}/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          // window.location = 'http://localhost:3000/hismart/systemparameter/ftpaccounttype';
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadSysPaymentType();
          // window.location.reload(false);       
        } else {
          toast.error('Try Again!');
        }
      });
    } 
  }
  useEffect(() => {
    loadSysPaymentType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'payment_typ_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Payment Type Name',
        accessor: 'payment_typ_name',
      },
      {
        Header: 'Payment Type Code',
        accessor: 'payment_typ_code',
      },
      {
        Header: 'Remarks',
        accessor: 'payment_typ_remarks',
      },
      {
        Header: 'Status',
        accessor: 'payment_typ_status',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );
// console.log(isLoading);
  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  // if (isLoading) {
  //   return <div className="App">Loading...</div>;
  // }
  return reactTableData;
};

export default PaymentTypeTableData;
