/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TestHistoryData = () => {
  const [getVendorData, setGetVendorData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getVendorData === null) {
    setGetVendorData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios.get(`${getVendorData.ses_URL}vendor_directory/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/3/${params.id}`)
      // axios.get(`http://127.0.0.1:8000/vendor/1/30888374364/3/${params.id}`)
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName) => {
          // console.log(res.data.data);  
          // console.log(res.data.status);
          data.push({
            hst_id: `${res.data.data[keyName].hst_id}`,
            hst_sp_vendor_dir_code: `${res.data.data[keyName].hst_sp_vendor_dir_code}`,
            hst_sp_vendor_dir_name: `${res.data.data[keyName].hst_sp_vendor_dir_name}`,
            hst_sp_vendor_dir_contact: `${res.data.data[keyName].hst_sp_vendor_dir_contact}`,
            hst_sp_vendor_dir_phone: `${res.data.data[keyName].hst_sp_vendor_dir_phone}`,
            hst_sp_vendor_dir_address: `${res.data.data[keyName].hst_sp_vendor_dir_address}`,
            hst_sp_vendor_dir_liability_ins: `${res.data.data[keyName].hst_sp_vendor_dir_liability_ins}`,
            hst_sp_vendor_dir_ws_ins: `${res.data.data[keyName].hst_sp_vendor_dir_ws_ins}`,
            hst_sp_vendor_dir_status: `${res.data.data[keyName].hst_sp_vendor_dir_status}`,
          });
        });
        //  console.log(data); 
        setTableData(data);
        // window.location.reload(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Code',
        accessor: 'hst_sp_vendor_dir_code',
      },
      {
        Header: 'Name',
        accessor: 'hst_sp_vendor_dir_name',
      },
      {
        Header: 'Contact',
        accessor: 'hst_sp_vendor_dir_contact',
      },
      {
        Header: 'Mobile',
        accessor: 'hst_sp_vendor_dir_phone',
      },
      {
        Header: 'Address',
        accessor: 'hst_sp_vendor_dir_address',
      },
      {
        Header: 'Liability Ins',
        accessor: 'hst_sp_vendor_dir_liability_ins',
      },
      {
        Header: 'WC Ins',
        accessor: 'hst_sp_vendor_dir_ws_ins',
      },
      {
        Header: 'Status',
        accessor: 'hst_sp_vendor_dir_status',
        Cell: ({ value }) => (
          <div>
            {(value === '1')
              ? 'Active'
              : 'Inactive'}
          </div>
        ),
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default TestHistoryData;
