import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import NotificationTable from './components/NotificationTable';

const MaterialTable = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Notification</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
          information
        </h3> */}
      </Col>
    </Row>
    <Row>
      <NotificationTable />
    </Row>
  </Container>
);

export default MaterialTable;
