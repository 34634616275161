import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Card, CardBody, Col, Button, 
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';


const TimeZoneHistoryDesign = ({ reactTableData }) => {
  const rows = reactTableData.tableRowsData;
  const dNone = 'd-none';
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by ...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title">
              <Link to="/systemparameter/timezone">
                <Button className="icon" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
              </Link>
              <h5 className="bold-text  mt-3">History-list</h5>
            </div>
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
              fullCustomizer
              advanceCustomizerOff={dNone}
            />
          </div>
          <ReactTableBase
            key={withSearchEngine ? 'modified' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={rows}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

TimeZoneHistoryDesign.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default TimeZoneHistoryDesign;
