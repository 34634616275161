export const GLOBAL_DATA_SET = 'GLOBAL_DATA_SET';
export const GLOBAL_DATA_GET = 'GLOBAL_DATA_GET';

export function toGlobalDataSet(toUrl, toToken) {
  return {
    type: GLOBAL_DATA_SET,
    payload: [toUrl, toToken],
    // payload,
  };
}

export function toGlobalDataGet() {
  return {
    type: GLOBAL_DATA_GET,
  };
}
