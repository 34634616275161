/* eslint-disable no-inner-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Field, reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import axios from "axios";
import { toast } from "react-toastify";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import renderCheckBoxField from "../form/CheckBox";
// import { toDataSet } from '../../../redux/custom/actions/testActions';
// import { toGlobalDataSet } from '../../../redux/custom/actions/globalDataActions';

const validate = (values) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "Email field shouldn’t be empty";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.user_name)
  ) {
    errors.user_name = "Invalid email address";
  }
  if (!values.pass_word) {
    errors.pass_word = "Password field shouldn’t be empty";
  }
  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

const LogInForm = ({ props, handleSubmit, pristine, reset, submitting }) => {
  //
  // props.toGlobalDataSetAction('url', 'token');
  // console.log(props);

  useEffect(() => {
    // setGlobalDataMethod();
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    gn_usr_id: "",
    gn_usr_first_name: "",
    gn_usr_username: "",
    gn_usr_profile_img_url: "",
    gn_usr_state: "",
    state_name: "",
    gn_usr_status: "",
    status_name: "",
    gn_usr_typ: "",
    typ_name: "",
    ses_usr_id: "",
    ses_jwt: "",
    ses_URL: "",
  });
  const [editedData, setEditedData] = useState([
    {
      user_name: "",
      pass_word: "",
    },
  ]);
  const onSubmit = (data) => {
    console.log(data);
    setEditedData(
      (editedData[0].user_name = `${data.user_name}`),
      (editedData[0].pass_word = `${data.pass_word}`)
    );
    console.log(editedData);
    try {
      // axios.post("http://127.0.0.1:8000/user/login", editedData)
      axios.post('https://tgwsaz01.terragroup.com:444/user/login', editedData)
        .then((res) => {
          console.log(`${process.env.REACT_APP_URL}user/login`);
          if (res.data.status_code === 200) {
            toast.success(
              <div>
                <strong>Welcome to the Terra Group!</strong>
                <br /> Your login has been successful. You are now able to look
                around the dashboard. Thank you!
              </div>
            );
            // setUserData(userData.push(res.data.data));
            // props.toGlobalDataSetAction('url', 'token');
            // console.log(props);
            setUserData(
              (userData.gn_usr_id = res.data.data.user_data[0].gn_usr_id),
              (userData.gn_usr_first_name =
                res.data.data.user_data[0].gn_usr_first_name),
              (userData.gn_usr_username =
                res.data.data.user_data[0].gn_usr_username),
              (userData.gn_usr_profile_img_url =
                res.data.data.user_data[0].gn_usr_profile_img_url),
              (userData.gn_usr_state = res.data.data.user_data[0].gn_usr_state),
              (userData.state_name = res.data.data.user_data[0].state_name),
              (userData.gn_usr_status =
                res.data.data.user_data[0].gn_usr_status),
              (userData.status_name = res.data.data.user_data[0].status_name),
              (userData.gn_usr_typ = res.data.data.user_data[0].gn_usr_typ),
              (userData.typ_name = res.data.data.user_data[0].typ_name),
              (userData.ses_usr_id = res.data.data.user_session[0].ses_usr_id),
              (userData.ses_jwt = res.data.data.user_session[0].ses_jwt),
              // userData.ses_URL = 'https://tgwsaz01.terragroup.com:444/',
              (userData.ses_URL = `${process.env.REACT_APP_URL}`)
            );
            console.log(userData);
            // setGlobalDataMethod('1', '2');
            // set global data
            // props.toGlobalDataSetAction('url update', 'token update');
            // console.log(props);
            // console.log(props);
            // set local storage
            localStorage.setItem("hismartData", JSON.stringify(userData));
            document.getElementById("link-to-dashboard").click();
            // window.location.assign('/dashboard');
            // props.history.push('/dashboard');
            // const getUserData = JSON.parse(localStorage.getItem('data'));
            // console.log(getUserData);
          } else {
            // alert('Your Email or Password were wrong');
            document.getElementById("input-form-clear").click();
            toast.warn("Your Email or Password were wrong!");
          }
        });
    } catch (e) {
      // alert('Your Email or Password were wrong');
      toast.danger("Your Email or Password were wrong!");
    }

    // localStorage.setItem('data', JSON.stringify(userData));
    // const getUserData = JSON.parse(localStorage.getItem('data'));
    // console.log(getUserData);
    // console.log(getUserData[0].baseURL);
    // setBasicData('test');
    // const { REACT_APP_URL, REACT_APP_TOKEN } = process.env;
    // console.log(basicData);
    // window.open('/dashboard');
    // window.location.assign('/dashboard');
    // window.location = '/dashboard';
  };

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form
      className="form login-form"
      id="login-section-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* disabled links start */}
      <NavLink id="link-to-dashboard" className="d-none" to="/dashboard">
        Dashboard{" "}
      </NavLink>
      {/* disabled links end */}
      <div className="form__form-group">
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="user_name"
            component={renderField}
            type="email"
            placeholder="Enter Email"
            className="input-without-border-radius"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="pass_word"
            component={renderField}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? " active" : ""
              }`}
            onClick={showPasswordToggle}
          >
            <EyeIcon />
          </button>
          <div className="account__forgot-password">
            <NavLink to="/reset_password">Forgot a password?</NavLink>
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <div className="form__form-group form__form-group-field">
          <Field
            name="remember_me"
            component={renderCheckBoxField}
            label="Remember me"
          />
        </div>
      </div>
      <div className="account__btns">
        <Button className="account__btn" type="submit" color="primary">
          Sign In
        </Button>
        <Button
          className="d-none"
          type="button"
          id="input-form-clear"
          onClick={reset}
          disabled={pristine || submitting}
        >
          Cancel
        </Button>
        {/* {
          form === 'modal_login'
            ? <Button className="account__btn" type="submit" color="primary">Sign In</Button>
            : (
              <NavLink className="account__btn btn btn-primary" to="/dashboard">
                Sign In
              </NavLink>
            )
        } */}

        {/* <NavLink className="btn btn-outline-primary account__btn" to="/register">Create
          Account
        </NavLink> */}
      </div>
    </Form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

// const mapStateToProps = state => ({
//   testData: state.testData,
//   globalData: state.globalData,
// });
// const mapDispatchToProps = dispatch => ({
//   toDataSetAction: text => dispatch(toDataSet(text)),
//   toGlobalDataSetAction: (url, token) => dispatch(toGlobalDataSet(url, token)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
//   form: 'login_validation', // a unique identifier for this form
//   validate,
// })(LogInForm));

export default connect((state) => ({
  errorMsg: state.user.error,
}))(
  reduxForm({
    form: "login_validation", // a unique identifier for this form
    validate,
  })(LogInForm)
);
