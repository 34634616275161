/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TrackerStatusHistoryData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function historyData() {
      // axios.get(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
      await axios.get(`${getUserData.ses_URL}tracker_status/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/3/${params.id}`)
        .then((res) => {
          // eslint-disable-next-line array-callback-return
          Object.keys(res.data.data).map((keyName) => {
            // console.log(res.data.data);
            // console.log(res.data.status);
            data.push({
              hst_id: `${res.data.data[keyName].hst_id}`,
              hst_sp_tracker_status_name: `${res.data.data[keyName].hst_sp_tracker_status_name}`,
              hst_sp_tracker_status_code: `${res.data.data[keyName].hst_sp_tracker_status_code}`,
              hst_sp_tracker_status_remarks: `${res.data.data[keyName].hst_sp_tracker_status_remarks}`,
              hst_sp_tracker_status_status: `${res.data.data[keyName].hst_sp_tracker_status_status}`,
              hst_sp_tracker_status_modified_on: `${res.data.data[keyName].hst_sp_tracker_status_modified_on}`,
            });
          });
          console.log(data);
          setTableData(data);
          // window.location.reload(false);
        });
    }
    historyData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Tracker Status',
        accessor: 'hst_sp_tracker_status_name',
      },
      {
        Header: 'Tracker Status Code',
        accessor: 'hst_sp_tracker_status_code',
      },
      {
        Header: 'Remarks',
        accessor: 'hst_sp_tracker_status_remarks',
      },
      {
        Header: 'Status',
        accessor: 'hst_sp_tracker_status_status',
        Cell: ({ value }) => (
          <div>
            {
              (value === '1')
                ? <div>Active</div>
                : <div>Inactive</div>
            }
          </div>
        ),
      },
      {
        Header: 'Modified Date',
        accessor: 'hst_sp_tracker_status_modified_on',
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default TrackerStatusHistoryData;
