import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TestTableData from './components/Table/TestTableData';
import TestTableDesign from './components/Table/TestTableDesign';

const DataTable = () => {
    const reactTableData = TestTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Test</h3>
            <h3 className="page-subhead subhead">
              Test List
            </h3>
          </Col>
        </Row>
        <Row>
          <TestTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
