const createActionTypes = name => ({
    CREATE: `${name}_CREATE`,
    EDIT: `${name}_EDIT`,
    DELETE: `${name}_DELETE`,
  });

const FILE = createActionTypes('FILE');
const FOLDER = createActionTypes('FOLDER');

export { FILE, FOLDER };
