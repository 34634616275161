/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Button, Modal, ButtonToolbar, ButtonGroup, Badge, Container, Row,
} from 'reactstrap';
import ActionGroupButton from '../../../../Components/ActionGroupButton';



const VendorTableData = () => {
  const [getVendorData, setGetVendorData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getVendorData === null) {
    setGetVendorData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  // Table Data
  const data = [];
  // const data = [];
  const link = {
    toCreateLink: '/master/vendor/view/',
    toHistoryLink: '/master/vendor/history/',
    toEditLink: '/master/vendor/edit/',
    // toDeleteLink: '/master/vendor',
  };
  const [tableData, setTableData] = useState([]);

  function loadMasterVendor() {
    axios.get(`${getVendorData.ses_URL}vendor_directory/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/1/0`)
      // axios.get('http://127.0.0.1:8000/vendor/1/30888374364/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName) => {
          // console.log(res.data.data);
          // console.log(res.data.status);
          if (res.data.data[keyName].sp_vendor_dir_status !== 2) {
            data.push({
              sp_vendor_dir_code: `${res.data.data[keyName].sp_vendor_dir_code}`,
              sp_vendor_dir_name: `${res.data.data[keyName].sp_vendor_dir_name}`,
              // sp_vendor_dir_contact: (res.data.data[keyName].sp_vendor_dir_contact) ? `${res.data.data[keyName].sp_vendor_dir_contact}` : '-',
              sp_vendor_dir_phone: (res.data.data[keyName].sp_vendor_dir_phone) ? `${res.data.data[keyName].sp_vendor_dir_phone}` : '-',
              sp_vendor_dir_address: `${res.data.data[keyName].sp_vendor_dir_address}`,
              // sp_vendor_dir_liability_ins: (res.data.data[keyName].sp_vendor_dir_liability_ins) ? `${res.data.data[keyName].sp_vendor_dir_liability_ins}` : '-',
              // sp_vendor_dir_ws_ins: (res.data.data[keyName].sp_vendor_dir_ws_ins) ? `${res.data.data[keyName].sp_vendor_dir_ws_ins}` : '-',
              status_name: `${res.data.data[keyName].status_name}`,
              action: <ActionGroupButton
                btnID={res.data.data[keyName].sp_vendor_dir_id}
                toSection="master-vendor"
                toCreateLink={`${link.toCreateLink}${res.data.data[keyName].sp_vendor_dir_id}`}
                toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].sp_vendor_dir_id}`}
                toEditLink={`${link.toEditLink}${res.data.data[keyName].sp_vendor_dir_id}`}
                toDeleteMethod={() => deleteMasterVendor(`${res.data.data[keyName].sp_vendor_dir_id}`)}
              />,
            });
          }
        });
        // console.log(data);
        setTableData(data);
        // window.location.reload(false);
      });
  }
  function deleteMasterVendor(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Vendor?');
    if (confirmBox === true) {
      axios.delete(`${getVendorData.ses_URL}vendor_directory/${getVendorData.gn_usr_id}/${getVendorData.ses_jwt}/2/${deleteID}`)
        // axios.delete(`http://127.0.0.1:8000/ftp_account/1/30888374364/2/${deleteID}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
            data.length = 0;
            setTableData([]);
            loadMasterVendor();
          } else {
            toast.error('Try Again!');
          }
          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          // setTestData(res.data.data[0]);
        });
    }
  }
  useEffect(() => {
    loadMasterVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'sp_vendor_dir_code',
      },
      {
        Header: 'Name',
        accessor: 'sp_vendor_dir_name',
      },
      // {
      //   Header: 'Contact',
      //   accessor: 'sp_vendor_dir_contact',
      // },
      {
        Header: 'Mobile',
        accessor: 'sp_vendor_dir_phone',
      },
      {
        Header: 'Address',
        accessor: 'sp_vendor_dir_address',
      },
      // {
      //   Header: 'Liability Ins',
      //   accessor: 'sp_vendor_dir_liability_ins',
      // },
      // {
      //   Header: 'WC Ins',
      //   accessor: 'sp_vendor_dir_ws_ins',
      // },
      {
        Header: 'Status',
        accessor: 'status_name',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default VendorTableData;
