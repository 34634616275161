import {
    SHEET_DATA_SET,
    SHEET_DATA_GET,
  } from '../actions/sheetTableDataActions';
  
  const initialState = [{
        id: '1', 
        fname: 'name',
        fdate: 'September 30, 2021-11-23',
        ftype: 'Worksheet', 
        fsize: '200kb',
        fnameorder: 'sheet_sample',
      }];
  
  const sheetTableDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHEET_DATA_SET: {
        initialState.length = 0;
        // eslint-disable-next-line array-callback-return
        Object.keys(action.payload[0]).map((keyName) => {
          initialState.push(
            {
              fdate: `${action.payload[0][keyName].fdate}`,
              fname: `${action.payload[0][keyName].fname}`,
              // fname: 'test',
              fnameorder: `${action.payload[0][keyName].fnameorder}`,
              fpermalink: `${action.payload[0][keyName].fpermalink}`,
              fsize: `${action.payload[0][keyName].fsize}`,
              ftype: `${action.payload[0][keyName].ftype}`,
              id: `${action.payload[0][keyName].id}`,
            },
          );
        });

        // console.log(action.payload[0]);
        // initialState.push(action.payload[0]);
        // console.log(state);
        return {
          ...state,   
        //   toUrl: action.payload[0], 
        //   toToken: action.payload[1], 
        };
      }
      case SHEET_DATA_GET:
        return state;
        default:
          return state;
    }
  };
  
  export default sheetTableDataReducer;
