/* eslint-disable max-len */
import {
  React, useState,
} from 'react';
import {
  Card, CardBody, Breadcrumb, BreadcrumbItem,
} from 'reactstrap';
// import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { CgFolder, CgFileDocument } from 'react-icons/cg';
import { BiSpreadsheet } from 'react-icons/bi';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';


let counter = 0;

const createData = (fname, fdate, ftype, fsize, fnameorder) => {
  counter += 1;
  return {
    id: counter, fname, fdate, ftype, fsize, fnameorder,
  };
};

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const FTPMatTable = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);

  const [data, setData] = useState([
    createData(<div className="file-manager_icons"><CgFolder /> <span>Folder 1</span></div>, 'September 30, 2021 17:23', 'File Folder', '', 'Folder 1'),
    createData(<div className="file-manager_icons"><CgFolder /> <span>Folder 2</span></div>, 'October 01, 2021 01:23', 'File Folder', '', 'Folder 2'),
    createData(<div className="file-manager_icons"><CgFolder /> <span>Folder 3</span></div>, 'October 02, 2021 18:23', 'File Folder', '', 'Folder 3'),
    createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet</span></div>, 'September 29, 2021 07:23', 'Worksheet', '22KB', 'Sheet'),
    createData(<div className="file-manager_icons"><CgFileDocument /> <span>File</span></div>, 'September 20, 2021 18:23', 'Text Doc', '4KB', 'File'),
    createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet_copy</span></div>, 'October 03, 2021 20:23', 'Worksheet', '22KB', 'Sheet_copy'),
    createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet 1</span></div>, 'September 30, 2021 11:23', 'Worksheet', '79KB', 'Sheet 1'),
    createData(<div className="file-manager_icons"><CgFileDocument /> <span>Test File</span></div>, 'October 03, 2021 23:23', 'Text Doc', '32KB', 'Test File'),
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(data.length);

  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = 'desc';
    if (orderBy === property && order === 'desc') { orderTemp = 'asc'; }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const isSelected = id => !!selected.get(id);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

  return (
    <Card>
      <CardBody className="mt-custom">

        <div className="">
          {/* <h5 className="bold-text mb-3">Files</h5> */}
          <Breadcrumb className="mt-2 mb-5">
            <BreadcrumbItem>Folder 1</BreadcrumbItem>
            <BreadcrumbItem>Folder 2</BreadcrumbItem>
            <BreadcrumbItem>Sub Sheets</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <MatTableToolbar
          numSelected={[...selected].filter(el => el[1]).length}
          handleDeleteSelected={handleDeleteSelected}
          onRequestSort={handleRequestSort}
        />
        <div className="material-table__wrap">
          <Table stickyHeader aria-label="sticky table" className="material-table material-table__orderhide material-table__custom">
            <MatTableHead
              numSelected={[...selected].filter(el => el[1]).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((d) => {
                  const select = isSelected(d.id);
                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={event => handleClick(event, d.id)}
                      aria-checked={select}
                      tabIndex={-1}
                      key={d.id}
                      selected={select}
                    >
                      <TableCell className="material-table__cell" padding="checkbox">
                        <Checkbox checked={select} className="material-table__checkbox" />
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {d.fname}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.fdate}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.ftype}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.fsize}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.fnameorder}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          className="material-table__pagination material-table__hide"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[data.length, 5, 10, 15]}
          dir="ltr"
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        />
      </CardBody>
    </Card>
  );
};

export default FTPMatTable;
