/* eslint-disable max-len */
import { useMemo } from 'react';

const UserHistoryData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Modified Date',
        accessor: 'modified_date',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    data.push({
      id: 1,
      username: 'Admin1',
      role: 'Admin',
      modified_date: 'Date: 03/09/2021, Time: 01:12',
      message: 'Basic change',

    },
      {
        id: 2,
        username: 'User1',
        role: 'User',
        modified_date: 'Date: 04/09/2021, Time: 22:12',
        message: 'Basic change',
      },
      {
        id: 3,
        username: 'Admin2',
        role: 'Admin',
        modified_date: 'Date: 05/09/2021, Time: 20:22',
        message: 'Advance change',
      },
      {
        id: 4,
        username: 'User2',
        role: 'User',
        modified_date: 'Date: 05/09/2021, Time: 23:55',
        message: 'Normal change',
      },
      {
        id: 5,
        username: 'User3',
        role: 'User',
        modified_date: 'Date: 06/09/2021, Time: 05:55',
        message: 'Normal change',
      });
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default UserHistoryData;
