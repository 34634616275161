import React from 'react';
import {
  Card, CardBody, Col
} from 'reactstrap';

const EventLabels = () => (
  <Col md={12} lg={12} xl={3}>
    <Card className="card--not-full-height">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">sections</h5>
        </div>
        <p className="typography-message">
          <span className="calendar-label calendar-label--red" /> Upcoming FollowUps
        </p> 
        <p className="typography-message">
          <span className="calendar-label calendar-label--accent" /> Followed
        </p>
        {/* <p className="typography-message">
          <span className="calendar-label calendar-label--blue" /> FTP Account
        </p>
        <p className="typography-message">
          <span className="calendar-label calendar-label--yellow" /> User
        </p> */}
        {/* <ButtonToolbar>
          <ButtonGroup className="btn-group--justified">
            <Button color="primary" href="#">Create new event</Button>
          </ButtonGroup>
        </ButtonToolbar> */}
      </CardBody>
    </Card>
  </Col>
);

export default EventLabels;
