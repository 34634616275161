/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useMemo, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Button, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import ArrowLeftBoldIcon from "mdi-react/ArrowLeftBoldIcon";
import CloseThickIcon from "mdi-react/CloseThickIcon";

const TrackerPopup = ({ invoicesNumber, setFollowPopup }) => {
  const [getTemplateData, setGetTemplateData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getTemplateData === null) {
    setGetTemplateData({
      ses_URL: "",
      ses_jwt: "",
    });
  }
  // const params = useParams();

  const data = [];
  const [tableData, setTableData] = useState([]);
  console.log(invoicesNumber);

  const followHistory = [
    {
      trans_follow_up_invoices_id: invoicesNumber.toString(),
    },
  ];

  async function historyData() {
    console.log(followHistory);

    const url = `${getTemplateData.ses_URL}follow_up_history/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/1/0`;
    console.log(url, followHistory);

    axios.post(url, followHistory).then((res) => {
      console.log(res.data.data);
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName, index) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        data.push({
          s_no: `${index + 1}`,
          trans_follow_up_name: `${res.data.data[keyName].trans_follow_up_name}`,
          // hst_sp_sch_project_typ: `${res.data.data[keyName].hst_sp_sch_project_typ}`,
          // hst_sp_sch_typ: `${res.data.data[keyName].hst_sp_sch_typ}`,
          trans_follow_up_date: moment(
            `${res.data.data[keyName].trans_follow_up_date}`
          ).format("YYYY-MM-DD"),
          trans_follow_up_invoices_id: `${res.data.data[keyName].trans_follow_up_invoices_id}`,
          // hst_sp_sch_remarks: `${res.data.data[keyName].hst_sp_sch_remarks}`,
          trans_follow_up_status: `${res.data.data[keyName].trans_follow_up_status}`,
          // hst_updated_on: `${res.data.data[keyName].hst_updated_on}`,
          // hst_operation_type: `${res.data.data[keyName].hst_operation_type}`,
        });
      });
      console.log(data);
      setTableData(data);
      // window.location.reload(false);
    });
  }
  useEffect(() => {
    historyData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "s_no",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Date",
        accessor: "trans_follow_up_date",
      },
      {
        Header: "Invoices Id",
        accessor: "trans_follow_up_invoices_id",
      },
      {
        Header: "Teamplate Name",
        accessor: "trans_follow_up_name",
      },

      // {
      //   Header: "Business Day",
      //   accessor: "hst_sp_sch_business_day",
      // },
      // {
      //   Header: "Prior Day",
      //   accessor: "hst_sp_sch_prior",
      // },
      // {
      //   Header: "Remarks",
      //   accessor: "hst_sp_sch_remarks",
      // },
      {
        Header: "No of Times",
        accessor: "trans_follow_up_status",
      },
      // {
      //   Header: "Modified Date",
      //   accessor: "hst_updated_on",
      // },
      // {
      //   Header: 'Operation',
      //   accessor: 'hst_operation_type',
      // },
    ],
    []
  );
  const reactTableData = {
    tableHeaderData: columns,
    tableRowsData: tableData,
  };
  const rows = reactTableData.tableRowsData;
  console.log(rows);
  const dNone = "d-none";
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by ...",
  };

  return (
    // <Modal
    //   isOpen={setFollowPopup}
    //   // toggle={toggleModalMailSingle}
    //   backdrop="static"
    //   keyboard={false}
    //   modalClassName="ltr-support"
    //   size="lg"
    //   fullscreen="lg"
    //   className="modal-dialog modal-dialog--primary modal-dialog--header hs-modal hs-modal__pay"
    // >
    <div className="TrackerPopup">
      <Col md={12} lg={12} className="TrackerPopup-container">
        <Card>
          <CardBody>
            <div className="">
              <div className="card__title">
                <h5 className="bold-text my-3">follow-up-history</h5>

                <Link to="/master/tracker/">
                  <Button
                    className="icon"
                    onClick={() => setFollowPopup((old) => !old)}
                    color="success"
                  >
                    <p>
                      <CloseThickIcon />
                    </p>
                  </Button>
                </Link>
              </div>
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
                fullCustomizer
                advanceCustomizerOff={dNone}
              />
            </div>
            <ReactTableBase
              key={withSearchEngine ? "modified" : "common"}
              columns={reactTableData.tableHeaderData}
              data={rows}
              tableConfig={tableConfig}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
    // {/* </Modal> */}
  );
};

TrackerPopup.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default TrackerPopup;
