/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-len */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Country from '../../../SystemParameter/Country/index';
import Test from '../../../SystemParameter/Test';
import FormIndex from '../../../SystemParameter/Test/components/Form/FormIndex';
import HistoryIndex from '../../../SystemParameter/Test/components/History/HistoryIndex';
import FTPAccountType from '../../../SystemParameter/FTPAccountType';
import FTPAccountTypeHistoryIndex from '../../../SystemParameter/FTPAccountType/components/History/FTPAccountTypeHistoryIndex';
import FTPAccountTypeFormIndex from '../../../SystemParameter/FTPAccountType/components/Form/FTPAccountTypeFormIndex';
import SPLanguage from '../../../SystemParameter/SPLanguage';
import SPLanguageHistoryIndex from '../../../SystemParameter/SPLanguage/components/History/SPLanguageHistoryIndex';
import SPLanguageFormIndex from '../../../SystemParameter/SPLanguage/components/Form/SPLanguageFormIndex';
import SPNotification from '../../../SystemParameter/SPNotification';
import SPNotificationHistoryIndex from '../../../SystemParameter/SPNotification/components/History/SPNotificationHistoryIndex';
import SPNotificationFormIndex from '../../../SystemParameter/SPNotification/components/Form/SPNotificationFormIndex';
import ProjectType from '../../../SystemParameter/ProjectType';
import ProjectTypeHistoryIndex from '../../../SystemParameter/ProjectType/components/History/ProjectTypeHistoryIndex';
import ProjectTypeFormIndex from '../../../SystemParameter/ProjectType/components/Form/ProjectTypeFormIndex';
import SheetAccountType from '../../../SystemParameter/SheetAccountType';
import SheetAccountTypeHistoryIndex from '../../../SystemParameter/SheetAccountType/components/History/SheetAccountTypeHistoryIndex';
import SheetAccountTypeFormIndex from '../../../SystemParameter/SheetAccountType/components/Form/SheetAccountTypeFormIndex';
import TimeZone from '../../../SystemParameter/TimeZone';
import TimeZoneHistoryIndex from '../../../SystemParameter/TimeZone/components/History/TimeZoneHistoryIndex';
import TimeZoneFormIndex from '../../../SystemParameter/TimeZone/components/Form/TimeZoneFormIndex';
import UserType from '../../../SystemParameter/UserType';
import UserTypeHistoryIndex from '../../../SystemParameter/UserType/components/History/UserTypeHistoryIndex';
import UserTypeFormIndex from '../../../SystemParameter/UserType/components/Form/UserTypeFormIndex';
import UserStatus from '../../../SystemParameter/UserStatus';
import UserStatusHistoryIndex from '../../../SystemParameter/UserStatus/components/History/UserStatusHistoryIndex';
import UserStatusFormIndex from '../../../SystemParameter/UserStatus/components/Form/UserStatusFormIndex';
import UserState from '../../../SystemParameter/UserState';
import UserStateHistoryIndex from '../../../SystemParameter/UserState/components/History/UserStateHistoryIndex';
import UserStateFormIndex from '../../../SystemParameter/UserState/components/Form/UserStateFormIndex';
import PaymentType from '../../../SystemParameter/PaymentType';
import PaymentTypeHistoryIndex from '../../../SystemParameter/PaymentType/components/History/PaymentTypeHistoryIndex';
import PaymentTypeFormIndex from '../../../SystemParameter/PaymentType/components/Form/PaymentTypeFormIndex';
import PaymentMode from '../../../SystemParameter/PaymentMode';
import PaymentModeHistoryIndex from '../../../SystemParameter/PaymentMode/components/History/PaymentModeHistoryIndex';
import PaymentModeFormIndex from '../../../SystemParameter/PaymentMode/components/Form/PaymentModeFormIndex';
import Scheduler from '../../../SystemParameter/Scheduler';
import SchedulerHistoryIndex from '../../../SystemParameter/Scheduler/components/History/SchedulerHistoryIndex';
import SchedulerFormIndex from '../../../SystemParameter/Scheduler/components/Form/SchedulerFormIndex';
import TemplateType from '../../../SystemParameter/Template';
import TemplateTypeFormIndex from '../../../SystemParameter/Template/components/Form/TemplateTypeFormIndex';
import TemplateTypeHistoryIndex from '../../../SystemParameter/Template/components/History/TemplateTypeHistoryIndex';
import TrackerStatus from '../../../SystemParameter/TrackerStatus';
import TrackerStatusHistoryIndex from '../../../SystemParameter/TrackerStatus/components/History/TrackerStatusHistoryIndex';
import TrackerStatusFormIndex from '../../../SystemParameter/TrackerStatus/components/Form/TrackerStatusFormIndex';


export default () => (
  <Switch>
    <Route exact path="/systemparameter/country" component={Country} />
    {/* <Route exact path="/master/user/history" component={HistoryIndex} />
    <Route exact path="/master/user/:action/" component={FormIndex} />
    <Route exact path="/master/user/:action/:id" component={FormIndex} /> */}

    {/* Test */}
    <Route exact path="/systemparameter/test" component={Test} />
    <Route exact path="/systemparameter/test/history/:id" component={HistoryIndex} />
    <Route exact path="/systemparameter/test/:action/" component={FormIndex} />
    <Route exact path="/systemparameter/test/:action/:id" component={FormIndex} />

    {/* FTP AccountType */}
    <Route exact path="/systemparameter/ftpaccounttype" component={FTPAccountType} />
    <Route exact path="/systemparameter/ftpaccounttype/history/:id" component={FTPAccountTypeHistoryIndex} />
    <Route exact path="/systemparameter/ftpaccounttype/:action/" component={FTPAccountTypeFormIndex} />
    <Route exact path="/systemparameter/ftpaccounttype/:action/:id" component={FTPAccountTypeFormIndex} />

    {/* SP Language */}
    <Route exact path="/systemparameter/splanguage" component={SPLanguage} />
    <Route exact path="/systemparameter/splanguage/history/:id" component={SPLanguageHistoryIndex} />
    <Route exact path="/systemparameter/splanguage/:action/" component={SPLanguageFormIndex} />
    <Route exact path="/systemparameter/splanguage/:action/:id" component={SPLanguageFormIndex} />

    {/* SP Notification */}
    <Route exact path="/systemparameter/spnotification" component={SPNotification} />
    <Route exact path="/systemparameter/spnotification/history/:id" component={SPNotificationHistoryIndex} />
    <Route exact path="/systemparameter/spnotification/:action/" component={SPNotificationFormIndex} />
    <Route exact path="/systemparameter/spnotification/:action/:id" component={SPNotificationFormIndex} />

    {/* Project Type */}
    <Route exact path="/systemparameter/projecttype" component={ProjectType} />
    <Route exact path="/systemparameter/projecttype/history/:id" component={ProjectTypeHistoryIndex} />
    <Route exact path="/systemparameter/projecttype/:action/" component={ProjectTypeFormIndex} />
    <Route exact path="/systemparameter/projecttype/:action/:id" component={ProjectTypeFormIndex} />

    {/* Sheet Account Type */}
    <Route exact path="/systemparameter/sheetaccounttype" component={SheetAccountType} />
    <Route exact path="/systemparameter/sheetaccounttype/history/:id" component={SheetAccountTypeHistoryIndex} />
    <Route exact path="/systemparameter/sheetaccounttype/:action/" component={SheetAccountTypeFormIndex} />
    <Route exact path="/systemparameter/sheetaccounttype/:action/:id" component={SheetAccountTypeFormIndex} />

    {/* Time Zone */}
    <Route exact path="/systemparameter/timezone" component={TimeZone} />
    <Route exact path="/systemparameter/timezone/history/:id" component={TimeZoneHistoryIndex} />
    <Route exact path="/systemparameter/timezone/:action/" component={TimeZoneFormIndex} />
    <Route exact path="/systemparameter/timezone/:action/:id" component={TimeZoneFormIndex} />

    {/* User Type */}
    <Route exact path="/systemparameter/usertype" component={UserType} />
    <Route exact path="/systemparameter/usertype/history/:id" component={UserTypeHistoryIndex} />
    <Route exact path="/systemparameter/usertype/:action/" component={UserTypeFormIndex} />
    <Route exact path="/systemparameter/usertype/:action/:id" component={UserTypeFormIndex} />

    {/* User Status */}
    <Route exact path="/systemparameter/userstatus" component={UserStatus} />
    <Route exact path="/systemparameter/userstatus/history" component={UserStatusHistoryIndex} />
    <Route exact path="/systemparameter/userstatus/:action/" component={UserStatusFormIndex} />
    <Route exact path="/systemparameter/userstatus/:action/:id" component={UserStatusFormIndex} />

    {/* User State */}
    <Route exact path="/systemparameter/userstate" component={UserState} />
    <Route exact path="/systemparameter/userstate/history/:id" component={UserStateHistoryIndex} />
    <Route exact path="/systemparameter/userstate/:action/" component={UserStateFormIndex} />
    <Route exact path="/systemparameter/userstate/:action/:id" component={UserStateFormIndex} />

    {/* Payment Type */}
    <Route exact path="/systemparameter/paymenttype" component={PaymentType} />
    <Route exact path="/systemparameter/paymenttype/history/:id" component={PaymentTypeHistoryIndex} />
    <Route exact path="/systemparameter/paymenttype/:action/" component={PaymentTypeFormIndex} />
    <Route exact path="/systemparameter/paymenttype/:action/:id" component={PaymentTypeFormIndex} />

    {/* Payment Mode */}
    <Route exact path="/systemparameter/paymentmode" component={PaymentMode} />
    <Route exact path="/systemparameter/paymentmode/history/:id" component={PaymentModeHistoryIndex} />
    <Route exact path="/systemparameter/paymentmode/:action/" component={PaymentModeFormIndex} />
    <Route exact path="/systemparameter/paymentmode/:action/:id" component={PaymentModeFormIndex} />

    {/* Scheduler */}
    <Route exact path="/systemparameter/scheduler" component={Scheduler} />
    <Route exact path="/systemparameter/scheduler/history/:id" component={SchedulerHistoryIndex} />
    <Route exact path="/systemparameter/scheduler/:action/" component={SchedulerFormIndex} />
    <Route exact path="/systemparameter/scheduler/:action/:id" component={SchedulerFormIndex} />

    {/* Template */}
    <Route exact path="/systemparameter/Template" component={TemplateType} />
    <Route exact path="/systemparameter/Template/history/:id" component={TemplateTypeHistoryIndex} />
    <Route exact path="/systemparameter/Template/:action/" component={TemplateTypeFormIndex} />
    <Route exact path="/systemparameter/Template/:action/:id" component={TemplateTypeFormIndex} />

    {/* Tracker Status */}
    <Route exact path="/systemparameter/trackerstatus" component={TrackerStatus} />
    <Route exact path="/systemparameter/trackerstatus/history/:id" component={TrackerStatusHistoryIndex} />
    <Route exact path="/systemparameter/trackerstatus/:action/" component={TrackerStatusFormIndex} />
    <Route exact path="/systemparameter/trackerstatus/:action/:id" component={TrackerStatusFormIndex} />

  </Switch>
);
