/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
// import DatePicker from 'react-datepicker';
import ReactDatePicker from 'react-datepicker';
// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
// import moment from 'moment';
// import DpSysSheet from '../../../../Components/Dropdowns/DpSysSheet';

const SchedulingForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const [isLoading, setLoading] = useState(true);
  const [inputActive, setinputActive] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState({});
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [formData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  const schedulType = [
    { value: '1', label: 'Hourly' },
    { value: '2', label: 'Daily' },
    { value: '3', label: 'Weekly' },
    { value: '4', label: 'Monthly' },
    { value: '5', label: 'Quarterly' },
    { value: '6', label: 'Half Yearly' },
    { value: '7', label: 'Yearly' },
  ];

  // const onSubmit = data => console.log(data);
  const [editedData, setEditedData] = useState([{
    map_project_id: `${params.proid}`,
    map_schdl_name: '',
    map_schdl_detail: [],
    map_schdl_type: '',
    map_schdl_from: '',
    map_schdl_to: '',
    map_schdl_remarks: '',
    map_schdl_status: '',
    map_schdl_modified_by: '',
    map_schdl_modified_on: '',
}]);
async function onSubmit(data) {
    console.log(data);
    setEditedData( 
      // editedData[0].map_pro_pro_id = data.map_pro_pro_id,
      editedData[0].map_project_id = `${params.proid}`,
      editedData[0].map_schdl_name = data.map_schdl_name,
      editedData[0].map_schdl_detail = '',
      editedData[0].map_schdl_type = `${data.schdl_type.value}`,
      // editedData[0].map_schdl_from = data.map_schdl_from,
      // editedData[0].map_schdl_to = data.map_schdl_to,
      editedData[0].map_schdl_remarks = '',
    );
    if (data.mapFromDate === undefined) {
      if (data.map_schdl_from) {
        editedData[0].map_schdl_from = data.map_schdl_from;
      } else {
        editedData[0].map_schdl_from = '1970-01-01T05:30:00';
      }
    } else {
      // const fromDateSlice = new Date(data.mapFromDate).toISOString().slice(0, 19);
      const fromDateSlice = new Date(data.mapFromDate);
      fromDateSlice.setHours(fromDateSlice.getHours() + 5);
      fromDateSlice.setMinutes(fromDateSlice.getMinutes() + 30);
      // console.log(new Date(data.mapFromDate));
      // console.log(fromDateSlice);
      // editedData[0].map_schdl_from = fromDateSlice;
      editedData[0].map_schdl_from = fromDateSlice.toISOString().slice(0, 19);
    }
    if (data.mapToDate === undefined) {
      if (data.map_schdl_to) {
        editedData[0].map_schdl_to = data.map_schdl_to;
      } else {
        editedData[0].map_schdl_to = '1970-01-01T05:30:00';
      }
    } else {
      // const toDateSlice = new Date(data.mapToDate).toISOString().slice(0, 19);
      const toDateSlice = new Date(data.mapToDate);
      toDateSlice.setHours(toDateSlice.getHours() + 5);
      toDateSlice.setMinutes(toDateSlice.getMinutes() + 30);
      editedData[0].map_schdl_to = toDateSlice.toISOString().slice(0, 19);
    }
    console.log(inputActive);
    if (inputActive === true) {
      setEditedData(editedData[0].map_schdl_status = '1');
    } else {
      setEditedData(editedData[0].map_schdl_status = '0');
    }
    setEditedData(editedData[0].map_schdl_modified_by = '1');
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    setEditedData(editedData[0].map_schdl_modified_on = currentDateFormate);
    // editedData.push(data); 
    console.log(editedData);
    if (params.action === 'create') {
      // setEditedData( 
      //   editedData[0].map_project_id = '2',
      // );
      await axios.post(`${getUserData.ses_URL}project/schedule_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
    // axios.post('http://127.0.0.1:8000/project/schedule_map/1/30888374364/1/0', editedData)
    .then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success('New Schedule Map added successfully');
        document.getElementById('backButton_master_project_sch').click();
      } else {
        toast.error('Try Again!');
      }
    });
    } else if (params.action === 'edit') {
      await axios.put(`${getUserData.ses_URL}project/schedule_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
      // axios.put(`http://127.0.0.1:8000/project/schedule_map/1/30888374364/2/${params.id}`, editedData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success('Schedule Map updated successfully');
          document.getElementById('backButton_master_project_sch').click();
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    }
  }

  // if (params.action !== 'create') {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     setTestData({
  //       map_project_id: 'New A',
  //       map_schdl_type: '',
  //       map_schdl_name: 'Schedule 1',
  //       map_schdl_detail: 'Schedule Details',
  //       map_schdl_from: 'September 27, 2021 12:00 AM',
  //       map_schdl_to: 'September 28, 2021 12:00 AM',
  //       map_schdl_remarks: 'Remark Text',
  //       map_schdl_status: true,
  //     });
  //   }, [params.id]);
  // }

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setTestData({
        map_project_id: '',
        map_schdl_id: '',
        pro_name: '',
        map_schdl_name: '',
        map_schdl_type: '',
        map_schdl_from: '',
        map_schdl_to: '',
      });
      setLoading(false);
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      async function viewData() {
        await axios.get(`${getUserData.ses_URL}project/schedule_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/project/schedule_map/1/30888374364/2/${params.id}`)
          .then((res) => {
            console.log(res.data.data.detail[0]);

            // dropdown list check
            // console.log(schedulType);
            const dpIndex = schedulType.findIndex(item => item.value === `${res.data.data.detail[0].map_schdl_type}`);
            console.log(dpIndex);
            setDefaultSelect({
              sch: dpIndex,
            });

            const currentFromDate = new Date(res.data.data.detail[0].map_schdl_from).getFullYear();
            setFromDate(
              currentFromDate === 1970 ? '' : new Date(res.data.data.detail[0].map_schdl_from),
              );
            const currentToDate = new Date(res.data.data.detail[0].map_schdl_to).getFullYear();
            setToDate(
              currentToDate === 1970 ? '' : new Date(res.data.data.detail[0].map_schdl_to),
              );
            // console.log(fromDate);
            setTestData(
              {
                map_schdl_id: res.data.data.detail[0].map_schdl_id,
                map_project_id: res.data.data.detail[0].map_project_id,
                pro_name: res.data.data.detail[0].pro_name,
                map_schdl_name: res.data.data.detail[0].map_schdl_name,
                map_schdl_from: res.data.data.detail[0].map_schdl_from,
                map_schdl_to: res.data.data.detail[0].map_schdl_to,
                map_schdl_detail: res.data.data.detail[0].map_schdl_detail,
              },
              );
            if (res.data.data.detail[0].map_schdl_status === 1) {
              setinputActive(true);
            } else {
              setinputActive(false);
            }
            setLoading(false);
          });
      }
  viewData();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to={`/master/Scheduling/${params.proid}`}>
              <Button className="icon" id="backButton_master_project_sch" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Scheduling Project {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-3">
              <div className="form__form-group disabled-form-item">
                <label className="form__form-group-label" htmlFor="pro_name">Project Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="pro_name"
                      type="text"
                      placeholder="Project Name"
                      id="pro_name"
                      defaultValue={formData.pro_name ? formData.pro_name : ''}
                      {...register('pro_name')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_schdl_name">Schedule Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="map_schdl_name"
                      type="text"
                      placeholder="Schedule Name"
                      id="map_schdl_name"
                      defaultValue={formData.map_schdl_name ? formData.map_schdl_name : ''}
                      {...register('map_schdl_name', {
                        required: 'Schedule Name is required',
                      })}
                    />
                    {errors?.map_schdl_name && <span className="form__form-group-error">{errors.map_schdl_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_schdl_type">Schedule Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="schdl_type"
                      id="schdl_type"
                      control={control}
                      defaultValue={schedulType[defaultSelect.sch]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={schedulType}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Select option is required',
                      }}
                    />
                    {errors.schdl_type && <span className="form__form-group-error">{errors.schdl_type.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_schdl_from">From Schedule</label>
                <div className="form__form-group-field width-full">
                  {/* <Controller
                    control={control}
                    name="date-input-from"
                    id="map_schdl_from"
                    // defaultDate="November 3, 2021 12:00 AM"
                    // defaultValue={formData.map_schdl_from ? formData.map_schdl_from : ''}
                    render={({ field }) => (
                      <>
                        <ReactDatePicker
                          placeholderText="Select date"
                          onChange={date => field.onChange(date)}
                          selected={field.value}
                          className="form__form-group-datepicker"
                          timeFormat="HH:mm"
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          // selected={field.value
                          //   ? moment(field.value).format('MMMM d, yyyy h:mm aa')
                          //   : formData.map_schdl_to}
                        />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </>
                    )}
                  /> */}
                  <Controller
                    control={control}
                    name="mapFromDate"
                    id="map_schdl_from"
                    render={({ field }) => (
                      <><ReactDatePicker
                        placeholderText="Select from date"
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        onBlur={field.onBlur}
                        onChange={(date) => {
                          field.onChange(date);
                          setFromDate(date);
                          console.log(fromDate);
                        }}
                        selected={fromDate}
                      />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_schdl_to">To Schedule</label>
                <div className="form__form-group-field width-full">
                  {/* <Controller
                    control={control}
                    name="date-input-to"
                    id="map_schdl_to"
                    defaultValue={formData.map_schdl_to ? formData.map_schdl_to : ''}
                    render={({ field }) => (
                      <>
                        <ReactDatePicker
                          placeholderText="Select date"
                          onChange={date => field.onChange(date)}
                          // onChange={(date)=>this.handleChange(date, 'dateOne')}
                          selected={field.value}
                          className="form__form-group-datepicker"
                          timeFormat="HH:mm"
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </>
                    )}
                  /> */}
                  <Controller
                    control={control}
                    name="mapToDate"
                    id="map_schdl_to"
                    render={({ field }) => (
                      <><ReactDatePicker
                        placeholderText="Select to date"
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        onBlur={field.onBlur}
                        onChange={(date) => {
                          field.onChange(date);
                          setToDate(date);
                          console.log(toDate);
                        }}
                        selected={toDate}
                      />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_schdl_detail">Schedule Details</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_schdl_detail"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_schdl_detail"
                    {...register('map_schdl_detail')}
                    row="3"
                    defaultValue={formData.map_schdl_detail ? formData.map_schdl_detail : ''}
                  />
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_schdl_remarks">Remarks</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_schdl_remarks"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_schdl_remarks"
                    {...register('map_schdl_remarks')}
                    row="3"
                    defaultValue={formData.map_schdl_remarks ? formData.map_schdl_remarks : ''}
                  />
                </div>
              </div>
            </div> */}

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox 
                          name="map_schdl_status" 
                          control={control}
                          value={inputActive}  
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}                   
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active" 
                      {...register('map_schdl_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to={`/master/Scheduling/${params.proid}`}>
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SchedulingForm;
