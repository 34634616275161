import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlexLayout, usePagination } from 'react-table';
import ReactTableConstructor from './components/ReactTableConstructor';
import ReactTableCell from './components/ReactTableCell';
import ReactTableCellEditable from './components/ReactTableEditableCell';

const ReactTableBase = ({
  tableConfig,
  columns,
  data,
  updateDraggableData,
  updateEditableData,
  pageset,
  pageIndex: initialPageIndex,
  onPageChange,
  pageSize: initialPageSize,
  onPageSizeChange,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize,
  } = tableConfig;

  const [filterValue, setFilterValue] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(initialPageIndex || 0);
  const [currentPageSize, setCurrentPageSize] = useState(initialPageSize || (manualPageSize ? manualPageSize[0] : 10));

  const handlePageChange = (newPageIndex) => {
    setCurrentPageIndex(newPageIndex);
    if (onPageChange) onPageChange(newPageIndex);
  };

  const handlePageSizeChange = (size) => {
    setCurrentPageSize(size);
    if (onPageSizeChange) onPageSizeChange(size);
  };

  const tableOptions = {
    columns,
    data,
    updateDraggableData,
    updateEditableData,
    setFilterValue,
    defaultColumn: {},
    isEditable,
    withDragAndDrop: withDragAndDrop || false,
    dataLength: data.length,
    autoResetPage: false,
    disableSortBy: !isSortable,
    manualSortBy: !isSortable,
    manualGlobalFilter: !withSearchEngine,
    manualPagination: !withPagination,
    initialState: {
      pageIndex: currentPageIndex,
      pageSize: currentPageSize,
      globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
    },
    stateReducer: (newState, action) => {
      switch (action.type) {
        case 'setPageSize':
          handlePageSizeChange(newState.pageSize);
          return newState;
        case 'setPageIndex':
          handlePageChange(newState.pageIndex);
          return newState;
        default:
          return newState;
      }
    },
  };

  let tableOptionalHook = [];
  if (isResizable) tableOptionalHook.push(useFlexLayout);
  if (withPagination) tableOptionalHook.push(usePagination);
  if (withSearchEngine) {
    tableOptions.defaultColumn.Cell = ReactTableCell;
  }
  if (isEditable) {
    tableOptions.defaultColumn.Cell = ReactTableCellEditable;
  }

  return (
    <ReactTableConstructor
      tableConfig={tableConfig}
      tableOptions={tableOptions}
      tableOptionalHook={tableOptionalHook}
      pageset={pageset}
      pageIndex={currentPageIndex}
      onPageChange={handlePageChange}
      pageSize={currentPageSize}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

ReactTableBase.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
  })),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateDraggableData: PropTypes.func,
  updateEditableData: PropTypes.func,
  pageset: PropTypes.any,
  pageIndex: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
};

ReactTableBase.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 50],
  },
  columns: [
    { Header: '#', accessor: 'id' },
    { Header: 'Header Example Title one', accessor: 'first' },
    { Header: 'Header Example Title two', accessor: 'last' },
  ],
  data: [
    { id: 1, first: 'Cell Example Data one', last: 'Cell Example Data two' },
    { id: 2, first: 'Cell Example Data three', last: 'Cell Example Data four' },
  ],
  updateDraggableData: () => { },
  updateEditableData: () => { },
  pageset: {},
  pageIndex: 0,
  onPageChange: () => { },
  pageSize: 10,
  onPageSizeChange: () => { },
};

export default ReactTableBase;
