import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TrackerStatusTableData from './components/Table/TrackerStatusTableData';
import TrackerStatusTableDesign from './components/Table/TrackerStatusTableDesign';

const DataTable = () => {
    const reactTableData = TrackerStatusTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Tracker Status</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <TrackerStatusTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
