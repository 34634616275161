import {
    GLOBAL_DATA_SET,
    GLOBAL_DATA_GET,
  } from '../actions/globalDataActions';
  
  const initialState = {
        toUrl: '',
        toToken: '',
  };
  
  const globalDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case GLOBAL_DATA_SET: {
        // console.log(action.payload);
        // console.log(state);
        return {
          ...state,
          toUrl: action.payload[0], 
          toToken: action.payload[1], 
        };
      }
      case GLOBAL_DATA_GET:
        return state;
        default:
          return state;
    }
  };
  
  export default globalDataReducer;
