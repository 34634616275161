
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TimeZoneHistoryDesign from './TimeZoneHistoryDesign';
import TimeZoneHistoryData from './TimeZoneHistoryData';



const TimeZoneHistoryIndex = () => {
    const reactTableData = TimeZoneHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Time Zone History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <TimeZoneHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default TimeZoneHistoryIndex;
