export const TEST_DATA_SET = 'TEST_DATA_SET';
export const TEST_DATA_GET = 'TEST_DATA_GET';

export function toDataSet(payload) {
  return {
    type: TEST_DATA_SET,
    payload,
  };
}

export function toDataGet() {
  return {
    type: TEST_DATA_GET,
  };
}
