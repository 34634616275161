import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Row, Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip, Modal,
} from 'reactstrap';
// import ReportsTableData from './components/Table/ReportsTableData';
import ReportsTable from './components/Table/ReportsTable';

const DataTable = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('master.reports.title')}</h3>
          <h3 className="page-subhead subhead">
            Report List
          </h3>
        </Col>
      </Row>
      <Row>
        <ReportsTable />
      </Row>
    </Container>
  );
};

export default DataTable;
