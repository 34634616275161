/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import { toast } from 'react-toastify';

const UserStateForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const [formData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  
  async function onSubmit(data) {
    console.log(data);
    const editedData = [];
    editedData.push(data); 
    console.log(editedData);
    
    if (params.action === 'create') {
    // axios.post('http://127.0.0.1:8000/user_state/1/30888374364/1/0', editedData)
    await axios.post(`${getUserData.ses_URL}user_state/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
    .then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success('New User state added successfully');
        document.getElementById('backButton_sys_userstate').click();
      } else {
        toast.error('Try Again!');
      }
    });
    } else if (params.action === 'edit') {
      // axios.put(`http://127.0.0.1:8000/user_state/1/30888374364/2/${params.id}`, editedData)
      await axios.put(`${getUserData.ses_URL}user_state/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success('User state updated successfully');
          document.getElementById('backButton_sys_userstate').click();
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    }
  }

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setTestData({
        usr_sta_name: '',
        usr_sta_code: '',
      });
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      async function viewData() {
      // axios.get(`http://127.0.0.1:8000/user_state/1/30888374364/2/${params.id}`)
      await axios.get(`${getUserData.ses_URL}user_state/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        .then((res) => {
          // console.log(res.data.data[0]);
          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          setTestData(res.data.data[0]);
        });
      }
  viewData();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/systemparameter/userstate">
              <Button className="icon" id="backButton_sys_userstate" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">User State {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_sta_name">User State Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="usr_sta_name"
                      type="text"
                      placeholder="Name"
                      id="usr_sta_name"
                      defaultValue={formData.usr_sta_name ? formData.usr_sta_name : ''}
                      {...register('usr_sta_name', {
                        required: 'User State name is required',
                      })}
                    />
                    {errors?.usr_sta_name && <span className="form__form-group-error">{errors.usr_sta_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_sta_code">User State Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="usr_sta_code"
                      type="text"
                      placeholder="Code"
                      id="usr_sta_code"
                      defaultValue={formData.usr_sta_code ? formData.usr_sta_code : ''}
                      {...register('usr_sta_code', {
                        required: 'User State Code is required',
                      })}
                    />
                    {errors?.usr_sta_code && <span className="form__form-group-error">{errors.usr_sta_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/systemparameter/userstate">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserStateForm;
