/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const TimeZoneTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const data = [];
  const link = {
    toCreateLink: '/systemparameter/timezone/view/',
    toHistoryLink: '/systemparameter/timezone/history/',
    toEditLink: '/systemparameter/timezone/edit/',
    // toDeleteLink: '/systemparameter/timezone',
  };
  const [tableData, setTableData] = useState([]);

  async function loadSysTimezone() {
    // axios.get('http://127.0.0.1:8000/time_zone/1/30888374364/1/0')
    await axios.get(`${getUserData.ses_URL}time_zone/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].sp_tz_status !== 2) {
       data.push({
        tz_id: `${res.data.data[keyName].sp_tz_id}`,
        tz_name: `${res.data.data[keyName].sp_tz_name}`,
        tz_code: `${res.data.data[keyName].sp_tz_code}`,
        tz_remarks: `${res.data.data[keyName].sp_tz_remarks}`,
        tz_status: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={`${res.data.data[keyName].sp_tz_id}`}
             toSection="sys-Timezone"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].sp_tz_id}`}
             toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].sp_tz_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].sp_tz_id}`}
             toDeleteMethod={() => deleteSysTimezone(`${res.data.data[keyName].sp_tz_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  async function deleteSysTimezone(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Timezone type?');
    if (confirmBox === true) {
      // axios.delete(`http://127.0.0.1:8000/time_zone/1/30888374364/2/${deleteID}`)
      await axios.delete(`${getUserData.ses_URL}time_zone/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadSysTimezone();    
        } else {
          toast.error('Try Again!');
        }
      });
    } 
  }
  useEffect(() => {
    loadSysTimezone();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'tz_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Time Zone Name',
        accessor: 'tz_name',
      },
      {
        Header: 'Time Zone Code',
        accessor: 'tz_code',
      },
      {
        Header: 'Remarks',
        accessor: 'tz_remarks',
      },
      {
        Header: 'Status',
        accessor: 'tz_status',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default TimeZoneTableData;
