
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SectionHistoryDesign from './SectionHistoryDesign';
import SectionHistoryData from './SectionHistoryData';



const SectionHistoryIndex = () => {
    const reactTableData = SectionHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Section History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <SectionHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default SectionHistoryIndex;
