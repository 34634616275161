/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const UserStatusTableData = () => {
  const data = [];
  const link = {
    toCreateLink: '/systemparameter/userstatus/view/',
    toHistoryLink: '/systemparameter/userstatus/history',
    toEditLink: '/systemparameter/userstatus/edit/',
    toDeleteLink: '/systemparameter/userstatus',
  };
  const [tableData, setTableData] = useState([]);
 
  useEffect(() => {
    data.push({
      sta_id: 1,
      sta_name: 'InActive',
      sta_code: 'IA',
      sta_remarks: 'Text',
      action: <ActionGroupButton
        btnID="1"
        toCreateLink={`${link.toCreateLink}1`}
        toHistoryLink={link.toHistoryLink}
        toEditLink={`${link.toEditLink}1`}
        toDeleteLink={link.toDeleteLink}
      />,
    },
    {
      sta_id: 2,
      sta_name: 'Active',
      sta_code: 'AC',
      sta_remarks: 'Text',
      action: <ActionGroupButton
        btnID="2"
        toCreateLink={`${link.toCreateLink}2`}
        toHistoryLink={link.toHistoryLink}
        toEditLink={`${link.toEditLink}2`}
        toDeleteLink={link.toDeleteLink}
      />,
    },
    {
      sta_id: 3,
      sta_name: 'Removed',
      sta_code: 'RE',
      sta_remarks: 'Text',
      action: <ActionGroupButton
        btnID="3"
        toCreateLink={`${link.toCreateLink}3`}
        toHistoryLink={link.toHistoryLink}
        toEditLink={`${link.toEditLink}3`}
        toDeleteLink={link.toDeleteLink}
      />,
    });
    setTableData(data);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'sta_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'User Status Name',
        accessor: 'sta_name',
      },
      {
        Header: 'User Status Code',
        accessor: 'sta_code',
      },
      {
        Header: 'Remarks',
        accessor: 'sta_remarks',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default UserStatusTableData;
