import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserStatusTableData from './components/Table/UserStatusTableData';
import UserStatusTableDesign from './components/Table/UserStatusTableDesign';

const DataTable = () => {
    const reactTableData = UserStatusTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">User Status</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <UserStatusTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
