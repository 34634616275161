
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SheetAccountHistoryDesign from './SheetAccountHistoryDesign';
import SheetAccountHistoryData from './SheetAccountHistoryData';



const SheetAccountHistoryIndex = () => {
    const reactTableData = SheetAccountHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Sheet Account History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <SheetAccountHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default SheetAccountHistoryIndex;
