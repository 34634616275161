import React, { useRef, useState } from 'react';
import { AiOutlineFile, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';


import { FILE } from '../state/constants';
import FILE_ICONS from '../FileIcons';
import { PlaceholderInput } from '../TreePlaceholderInput';
import { ActionsWrapper, StyledName } from '../Tree.style';
import { useTreeContext } from '../state';
import { StyledFile } from './TreeFile.style';

// eslint-disable-next-line react/prop-types
const File = ({ name, id, node }) => {
  const { dispatch, isImparative, onNodeClick } = useTreeContext();
  const [isEditing, setEditing] = useState(false);
  const ext = useRef('');

  // eslint-disable-next-line react/prop-types
  const splitted = name?.split('.');
  ext.current = splitted[splitted.length - 1];

  const toggleEditing = () => setEditing(!isEditing);
  // eslint-disable-next-line no-shadow
  const commitEditing = (name) => {
    dispatch({ type: FILE.EDIT, payload: { id, name } });
    setEditing(false);
  };
  const commitDelete = () => {
    dispatch({ type: FILE.DELETE, payload: { id } });
  };
  const handleNodeClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      onNodeClick({ node });
    },
    [node],
  );
  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <StyledFile onClick={handleNodeClick} className="tree__file">
      {isEditing ? (
        <PlaceholderInput
          type="file"
          style={{ paddingLeft: 0 }}
          defaultValue={name}
          onSubmit={commitEditing}
          onCancel={handleCancel}
        />
      ) : (
        <ActionsWrapper>
          <StyledName>
            {FILE_ICONS[ext.current] ? (
              FILE_ICONS[ext.current]
            ) : (
              <AiOutlineFile />
            )}
            &nbsp;&nbsp;{name}
          </StyledName>
          {isImparative && (
            <div className="actions">
              <AiOutlineEdit onClick={toggleEditing} />
              <AiOutlineDelete onClick={commitDelete} />
            </div>
          )}
        </ActionsWrapper>
      )}
    </StyledFile>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { File };
