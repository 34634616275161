/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Button,
  Modal,
  ButtonToolbar,
  ButtonGroup,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
// import PlusBoldIcon from 'mdi-react/PlusBoldIcon';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
//import { Checkbox, FormControlLabel, FormGroup,} from '@material-ui/core';
import makeAnimated from "react-select/animated";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
// import { DateLocalizer } from 'react-big-calendar';
// import { classNames } from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faCalendarPlus,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import DpEmailFilterTemplate from "../../../../Components/Dropdowns/DpEmailFilterTemplate";
import DpTrackerStatus from "../../../../Components/Dropdowns/DpTrackerStatus";
import DpProperty from "../../../../Components/Dropdowns/DpProperty";
import DpVendorFilter from "../../../../Components/Dropdowns/DpVendorFilter";
import TrackerPopup from "./TrackerPopup";
// import { Data } from '@react-google-maps/api';

const TrackerTable = () => {
  const [followPopup, setFollowPopup] = useState(false);
  const [invoicesNumber, setInvoicesNumber] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const inputRef = useRef();
  const animatedComponents = makeAnimated();
  const [getUserData, setGetUserData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getUserData === null) {
    setGetUserData({
      ses_URL: "",
      ses_jwt: "",
    });
  }
  const [isLoading, setLoading] = useState(true);
  let data = [];
  const [tableData, setTableData] = useState([]);
  const [pageset, setPageset] = useState(false);

  var DocumentandPropertyFilter = {};
  const handelHollowHistory = (invoices_number) => {
    console.log(invoices_number, "invoices_number");
    setFollowPopup((old) => !old);
    setInvoicesNumber(invoices_number);
  };
  const filterloadTrackerData = () => {
    if (DocumentandPropertyFilter.key === "filterProperty") {
      console.log(DocumentandPropertyFilter);

      const Propertyformdata = new FormData();
      Propertyformdata.append(
        "property_name",
        DocumentandPropertyFilter.property_name !== null &&
          DocumentandPropertyFilter.property_name !== undefined &&
          DocumentandPropertyFilter.property_name !== ""
          ? `${DocumentandPropertyFilter.property_name.value}`
          : ""
      );
      Propertyformdata.append(
        "vendor_name",
        DocumentandPropertyFilter.vendor_name !== null &&
          DocumentandPropertyFilter.vendor_name !== undefined &&
          DocumentandPropertyFilter.vendor_name !== ""
          ? `${DocumentandPropertyFilter.vendor_name.value}`
          : ""
      );
      Propertyformdata.append(
        "tracker_status",
        DocumentandPropertyFilter.tracker_status !== null &&
          DocumentandPropertyFilter.tracker_status !== undefined &&
          DocumentandPropertyFilter.tracker_status !== ""
          ? `${DocumentandPropertyFilter.tracker_status.label}`
          : ""
      );
      Propertyformdata.append(
        "mail_status",
        DocumentandPropertyFilter.mail_send_status !== null &&
          DocumentandPropertyFilter.mail_send_status !== undefined &&
          DocumentandPropertyFilter.mail_send_status !== ""
          ? `${DocumentandPropertyFilter.mail_send_status.value}`
          : "-1"
      );
      loadTrackerData("filterProperty", Propertyformdata, 0);
    } else if (DocumentandPropertyFilter.key === "filter") {
      console.log(DocumentandPropertyFilter);

      const formdata = new FormData();
      if (DocumentandPropertyFilter.filter_document_status.value === "1") {
        formdata.append(
          "month_year",
          `${DocumentFilterData.filter_month.value}-${DocumentFilterData.filter_year.value}`
        );
        loadTrackerData("filter", formdata, 1);
      } else {
        formdata.append("month_year", "");
        loadTrackerData("filter", formdata, 0);
      }
    } else {
      loadTrackerData("initial");
    }
  };
  // console.log(DocumentFilterData);
  let TemplateDropdown;
  let TrackerStatusDropdown;

  // Handler to change the page index
  const handlePageChange = (newPageIndex) => {
    console.log('Changing to page:', newPageIndex);
    setCurrentPage(newPageIndex);
    // if (onPageChange) onPageChange(newPageIndex);
  };

  // Handler to change the page size
  const handlePageSizeChange = (size) => {
    console.log('Changing page size to:', size);
    setPageSize(size);
    // if (onPageSizeChange) onPageSizeChange(size);
  };

  const applyRowStyle = (index) => {
    const styleSheet = document.styleSheets[0];
    // Calculate the index on the current page
    const pageIndex = (index % pageSize) + 1;
    // Rule to apply the style
    const ruleText = `.TrackerCustomStyle table tbody tr:nth-child(${pageIndex}) {
    background: rgb(76, 255, 182);
    transition: background 0.5s; 
  }`;
    // Rule to revert the style
    const revertRuleText = `.TrackerCustomStyle table tbody tr:nth-child(${pageIndex}) {
    background: initial; 
    transition: background 0.5s; 
  }`;
    // Insert the rule to apply the style
    styleSheet.insertRule(ruleText, styleSheet.cssRules.length);
    console.log('Inserted rule:', ruleText);
    // Remove the rule and add the revert rule after 2 seconds
    setTimeout(() => {
      console.log('Removing rule...');
      const rules = styleSheet.cssRules;
      for (let i = 0; i < rules.length; i++) {
        if (rules[i].cssText.includes(`tr:nth-child(${pageIndex})`)) {
          styleSheet.deleteRule(i);
          console.log('Deleted rule at index:', i);
          // Insert the rule to revert the style
          styleSheet.insertRule(revertRuleText, styleSheet.cssRules.length);
          console.log('Inserted revert rule:', revertRuleText);
          break;
        }
      }
    }, 2000);
  }

  async function loadTrackerData(dataStatus, filterData, filterType) {
    data = [];
    console.log("Filter Loading..");
    console.log(dataStatus);
    console.log(filterData);
    console.log(filterType);
    setLoading(true);
    let configAPI;
    if (dataStatus === "initial") {
      configAPI = axios.get(
        `${getUserData.ses_URL}invoices/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`
      );
    } else if (dataStatus === "filter") {
      configAPI = axios.post(
        `${getUserData.ses_URL}invoices_filter/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${filterType}/0`,
        filterData
      );
    } else if (dataStatus === "filterProperty") {
      configAPI = axios.post(
        `${getUserData.ses_URL}invoices_filter_property/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${filterType}/0`,
        filterData
      );
    }

    // eslint-disable-next-line no-const-assign
    await configAPI
      // axios.get('http://127.0.0.1:8000/user_type/1/30888374364/1/0')
      .then((res) => {
        console.log(res.data.data);
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName, index) => {
          // console.log('New');
          // console.log(keyName);
          // console.log(res.data);
          // if (res.data.data[keyName].trans_invoices_follow_up_upcoming_dates !== null) {
          // console.log(
          //   "test" + `${res.data.data[keyName].trans_invoices_vendor_code}`
          // );
          // TemplateDropdown = DpEmailFilterTemplate.filter((item) => {
          //   // console.log(item); // Log each item in the array
          //   return (
          //     item.selectedValue ===
          //       `${res.data.data[keyName].trans_invoices_vendor_code}` ||
          //     item.selectedValue === "All"
          //   );
          // });

          TemplateDropdown = DpEmailFilterTemplate;
          // console.log("Filtered TemplateDropdown:", TemplateDropdown);
          TrackerStatusDropdown = DpTrackerStatus;

          // console.log(TrackerStatusDropdown);

          data.push({
            // trans_invoices_id: `${res.data.data[keyName].trans_invoices_id}`,
            trans_invoices_id: `${index + 1}`,
            trans_invoices_vendor_name:
              res.data.data[keyName].trans_invoices_vendor_name !== null
                ? `${res.data.data[keyName].trans_invoices_vendor_name}`
                : "",
            trans_invoices_bank_code:
              res.data.data[keyName].trans_invoices_bank_code !== null
                ? `${res.data.data[keyName].trans_invoices_bank_code}`
                : "",
            trans_invoices_property_name:
              res.data.data[keyName].trans_invoices_property_name !== null
                ? `${res.data.data[keyName].trans_invoices_property_name}`
                : "",
            trans_invoices_vendor_code: `${res.data.data[keyName].trans_invoices_vendor_code}`,
            trans_invoices_due_date: moment(
              `${res.data.data[keyName].trans_invoices_due_date}`
            ).format("YYYY-MM-DD"),
            trans_invoices_amount: `${res.data.data[keyName].trans_invoices_amount}`,
            trans_invoices_amount_typ: `${res.data.data[keyName].trans_invoices_amount_typ}`,
            trans_invoices_number: `${res.data.data[keyName].trans_invoices_number}`,
            trans_invoices_doc_received: `${res.data.data[keyName].trans_invoices_doc_received}`,
            trans_invoices_doc_received_date: moment(
              `${res.data.data[keyName].trans_invoices_doc_received_date}`
            ).format("YYYY-MM-DD"),
            // trans_invoices_follow_up_upcoming_dates: `${res.data.data[keyName].trans_invoices_follow_up_upcoming_dates}`,
            trans_invoices_upload_date: res.data.data[keyName].trans_invoices_upload_date !== null
              ? moment(
                `${res.data.data[keyName].trans_invoices_upload_date}`
              ).format("YYYY-MM-DD")
              : "-",
            trans_invoices_tracker_status_name: (
              <Select
                name="tracker_status"
                id="tracker_status"
                value={TrackerStatusDropdown.find(
                  (option) => option.label === res.data.data[keyName].trans_invoices_tracker_status_name
                ) || null}
                options={TrackerStatusDropdown}
                onChange={(selectedOption) =>
                  handleChangeTrackerStatus(
                    index,
                    selectedOption,
                    `${res.data.data[keyName].trans_invoices_id}`,
                    `${res.data.data[keyName].trans_invoices_vendor_name}`,
                    `${res.data.data[keyName].trans_invoices_vendor_code}`,
                    `${res.data.data[keyName].trans_invoices_tracker_status_name}`
                  )
                }
              />
            ),
            trans_invoices_mail_delivered: `${res.data.data[keyName].trans_invoices_mail_delivered}`,
            trans_invoices_mail_date: res.data.data[keyName].trans_invoices_mail_date !== null
              ? moment(
                `${res.data.data[keyName].trans_invoices_mail_date}`
              ).format("YYYY-MM-DD")
              : "-",
            trans_email_status:
              res.data.data[keyName].trans_invoices_mail_status === 3 ? (
                <div className="mail-exclude text-hs">Group Include</div>
              ) : (
                <Select
                  name="email_status"
                  id="email_status"
                  value={emailStatusDropdown.find(
                    (option) => option.value === String(res.data.data[keyName].trans_invoices_mail_status)
                  ) || null}
                  // value={emailStatusDropdown.find(
                  //   (option) =>
                  //     option.value ===
                  //       `${res.data.data[keyName].trans_invoices_mail_status}`
                  //       ? `${res.data.data[keyName].trans_invoices_mail_status}`
                  //       : ""
                  // )}
                  options={emailStatusDropdown}
                  onChange={(selectedOption) =>
                    handleChangeEmailStatus(
                      index,
                      selectedOption,
                      `${res.data.data[keyName].trans_invoices_id}`,
                      `${res.data.data[keyName].trans_invoices_vendor_name}`,
                      `${res.data.data[keyName].trans_invoices_vendor_code}`,
                      `${res.data.data[keyName].trans_invoices_mail_status}`
                    )
                  }
                />
              ),
            trans_template:
              res.data.data[keyName].trans_invoices_mail_status === 3 ? (
                <div className="mail-exclude text-hs">Grouped mail</div>
              ) : res.data.data[keyName].trans_invoices_mail_status === 0 ? (
                <div className="mail-exclude text-danger">Exclude the mail</div>
              ) : (
                <>
                  <Select
                    name="email_template"
                    id="email_template"
                    value={TemplateDropdown.find(
                      (option) => option.value === String(res.data.data[keyName].trans_invoices_mail_temp_id)
                    ) || null}
                    // value={TemplateDropdown.find(
                    //   (option) =>
                    //     option.value ===
                    //       `${res.data.data[keyName].trans_invoices_mail_temp_id}`
                    //       ? `${res.data.data[keyName].trans_invoices_mail_temp_id}`
                    //       : ""
                    // )}
                    options={TemplateDropdown}
                    onChange={(selectedOption) => {
                      // console.log(selectedOption);
                      handleChangeTemplate(
                        index,
                        selectedOption,
                        `${res.data.data[keyName].trans_invoices_id}`,
                        `${res.data.data[keyName].trans_invoices_vendor_name}`,
                        `${res.data.data[keyName].trans_invoices_vendor_code}`,
                        `${res.data.data[keyName].trans_invoices_mail_status}`,
                        filterData
                      );
                    }}
                  />
                  <ButtonToolbar>
                    <ButtonGroup className="btn-group--icons" dir="ltr">
                      <div>
                        <Button
                          id="btn-view-master-tracker-4"
                          className="icon-send"
                          color="success"
                          onClick={() =>
                            toggleModalMailSingle(
                              `${res.data.data[keyName].trans_invoices_id}`,
                              `${res.data.data[keyName].trans_invoices_vendor_name}`,
                              `${res.data.data[keyName].trans_invoices_vendor_code}`,
                              `${res.data.data[keyName].trans_invoices_mail_status}`,
                              `${res.data.data[keyName].trans_invoices_mail_temp_id}`
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      </div>
                    </ButtonGroup>
                  </ButtonToolbar>
                </>
              ),
            action: (
              <ButtonToolbar>
                <ButtonGroup className="btn-group--icons" dir="ltr">
                  <Link to="/master/tracker/">
                    <Button
                      id="btn-view-master-tracker-3"
                      className={
                        res.data.data[keyName]
                          .trans_invoices_doc_received_date === null
                          ? "icon-add"
                          : "icon-edit"
                      }
                      color="success"
                      onClick={() =>
                        handleOpenModal(
                          true,
                          `${res.data.data[keyName].trans_invoices_id}`,
                          `${res.data.data[keyName].trans_invoices_vendor_name}`,
                          `${res.data.data[keyName].trans_invoices_vendor_code}`,
                          `${res.data.data[keyName].trans_invoices_doc_remarks}`,
                          `${res.data.data[keyName].trans_invoices_doc_received_date}`,
                          res.data.data[keyName]
                            .trans_invoices_doc_received_date !== null,
                          `${res.data.data[keyName].trans_invoices_service_date}`,
                          res.data.data[keyName].trans_invoices_service_date !==
                          null,
                          `${res.data.data[keyName].trans_invoices_service_thru_date}`,
                          res.data.data[keyName]
                            .trans_invoices_service_thru_date !== null
                        )
                      }
                    >
                      {res.data.data[keyName]
                        .trans_invoices_doc_received_date === null ? (
                        <FontAwesomeIcon icon={faCalendarPlus} />
                      ) : (
                        <FontAwesomeIcon icon={faEdit} />
                      )}
                    </Button>
                  </Link>

                  {/* {
                  (res.data.data[keyName].trans_invoices_doc_received_date === null)
                  && (
                    <div className="ml-1">
                      <Button id="btn-view-master-tracker-4" className="icon-send" color="success" onClick={() => toggleModalMailSingle(`${res.data.data[keyName].trans_invoices_id}`, `${res.data.data[keyName].trans_invoices_vendor_name}`, `${res.data.data[keyName].trans_invoices_vendor_code}`)}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </Button>
                    </div>
                  )
                } */}

                  <Link to="/master/tracker/">
                    <Button
                      id="btn-view-master-tracker-4"
                      className="icon-view"
                      color="warning"
                      onClick={() =>
                        handelHollowHistory(
                          `${res.data.data[keyName].trans_invoices_id}`
                        )
                        // alert(`${res.data.data[keyName].trans_invoices_number}`)
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </Link>
                </ButtonGroup>
              </ButtonToolbar>
            ),
          });
          // }
        });
        // console.log(data);
        setTableData(data);
        setLoading(false);
      });
  }

  const handleChangeEmailStatus = (
    index,
    selectedOptionEmail,
    TrackerID,
    vendorName,
    vendorCode,
    mailStatus
  ) => {
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;
    const updatedData = [...data];
    // updatedData[index].trans_email_status = selectedOption.value;
    updatedData[index].trans_email_status = (
      <Select
        name="email_status"
        id="email_status"
        value={emailStatusDropdown.find(
          (option) => option.value === selectedOptionEmail.value
        )}
        options={emailStatusDropdown}
        onChange={(selectedOptionEmail) =>
          handleChangeEmailStatus(
            index,
            selectedOptionEmail,
            TrackerID,
            vendorName,
            vendorCode,
            mailStatus
          )
        }
      />
    );
    if (selectedOptionEmail.value === "0") {
      updatedData[index].trans_template = (
        <div className="mail-exclude text-danger">Exclude the mail</div>
      );
      const emailStateBody = [
        {
          trans_invoices_modified_by: "1",
          trans_invoices_modified_on: currentDateFormate,
        },
      ];
      axios
        .put(
          `${getUserData.ses_URL}invoices_mailstatus/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${selectedOptionEmail.value}/${TrackerID}`,
          emailStateBody
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            toast.success("Email state updated successfully");
            filterloadTrackerData();
          } else {
            toast.error("Try Again!");
          }
        });
    } else if (
      selectedOptionEmail.value === "1" ||
      selectedOptionEmail.value === "2"
    ) {
      updatedData[index].trans_template = (
        <>
          <Select
            name="email_template"
            id="email_template"
            // value={TemplateDropdown.find(option => option.value === '0')}
            value={null}
            options={TemplateDropdown}
            onChange={(selectedOptionTemplate) =>
              handleChangeTemplate(
                index,
                selectedOptionTemplate,
                TrackerID,
                vendorName,
                vendorCode,
                mailStatus,
                (filterPropsData = filterData)
              )
            }
          />
          <ButtonToolbar>
            <ButtonGroup className="btn-group--icons" dir="ltr">
              <div>
                <Button
                  id="btn-view-master-tracker-4"
                  className="icon-send"
                  color="success"
                  onClick={() =>
                    toggleModalMailSingle(
                      TrackerID,
                      vendorName,
                      vendorCode,
                      mailStatus,
                      tempId
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </div>
            </ButtonGroup>
          </ButtonToolbar>
        </>
      );
      const emailStateBody = [
        {
          trans_invoices_modified_by: "1",
          trans_invoices_modified_on: currentDateFormate,
        },
      ];
      axios
        .put(
          `${getUserData.ses_URL}invoices_mailstatus/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${selectedOptionEmail.value}/${TrackerID}`,
          emailStateBody
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            toast.success("Email state updated successfully");
            filterloadTrackerData();
          } else {
            toast.error("Try Again!");
          }
        });
    }
    setTableData(updatedData);
    // Style Changes
    applyRowStyle(index);
  };

  const handleChangeTemplate = (index, selectedOption, TrackerID, vendorName, vendorCode, mailStatus, filterPropsData) => {
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;
    console.log(index, selectedOption, TrackerID, vendorName, vendorCode, mailStatus);
    console.log(filterPropsData);
    //   console.log(TemplateDropdown.find((option) => option.value === selectedOption.value)
    //);
    const updatedData = [...data];
    // updatedData[index].trans_template = selectedOption.value;
    updatedData[index].trans_template = (
      <>
        <Select
          name="email_template"
          id="email_template"
          value={TemplateDropdown.find(
            (option) => option.value === selectedOption.value
          )}
          options={TemplateDropdown}
          onChange={(selectedOption) => {
            handleChangeTemplate(index, selectedOption, TrackerID, vendorName, vendorCode, mailStatus, filterPropsData);
          }}
        />
        <ButtonToolbar>
          <ButtonGroup className="btn-group--icons" dir="ltr">
            <div>
              <Button
                id="btn-view-master-tracker-4"
                className="icon-send"
                color="success"
                onClick={() =>
                  toggleModalMailSingle(
                    TrackerID,
                    vendorName,
                    vendorCode,
                    mailStatus,
                    tempId
                  )
                }
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </div>
          </ButtonGroup>
        </ButtonToolbar>
      </>
    );
    const templateBody = [
      {
        trans_invoices_mail_temp_id: `${selectedOption.value}`,
        trans_invoices_modified_by: "1",
        trans_invoices_modified_on: currentDateFormate,
      },
    ];

    axios
      .put(
        `${getUserData.ses_URL}invoices_template/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${TrackerID}`,
        templateBody
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success("Email template updated successfully");
          filterloadTrackerData();
        } else {
          toast.error("Try Again!");
        }
      });
    setTableData(updatedData);
    // Style Changes
    applyRowStyle(index);
  };

  // Tracker status change
  const handleChangeTrackerStatus = (index, selectedOption, TrackerID, vendorName, vendorCode, trackerStatus) => {

    console.log(index, selectedOption, TrackerID, vendorName, vendorCode, trackerStatus);
    console.log(index);

    const currentDate = new Date();

    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;

    const updatedData = [...data];
    // updatedData[index].trans_template = selectedOption.value;
    updatedData[index].trans_invoices_tracker_status_name = (
      <>
        <Select
          name="tracker_status"
          id="tracker_status"
          value={TrackerStatusDropdown.find(
            (option) => option.value === selectedOption.value
          )}
          options={TrackerStatusDropdown}
          onChange={(selectedOption) => handleChangeTrackerStatus(index, selectedOption, TrackerID, vendorName, vendorCode, trackerStatus)}
        />
      </>
    );
    const trackerStatusBody = [
      {
        trans_invoices_tracker_status_id: `${selectedOption.value}`,
        trans_invoices_modified_by: "1",
        trans_invoices_modified_on: currentDateFormate,
      },
    ];
    axios
      .put(
        `${getUserData.ses_URL}invoices_tracker_status/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${TrackerID}`,
        trackerStatusBody
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success("Tracker Status updated successfully");
          filterloadTrackerData();
        } else {
          toast.error("Try Again!");
        }
      });
    setTableData(updatedData);
    // alert(currentPage)
    // setCurrentPage(2);
    console.log("Current Page:", currentPage); // Log current page
    // Style Changes
    applyRowStyle(index);
  };

  const SearchMonth = [
    { value: "Jan", label: "Jan" },
    { value: "Feb", label: "Feb" },
    { value: "Mar", label: "Mar" },
    { value: "Apr", label: "Apr" },
    { value: "May", label: "May" },
    { value: "Jun", label: "Jun" },
    { value: "Jul", label: "Jul" },
    { value: "Aug", label: "Aug" },
    { value: "Sep", label: "Sep" },
    { value: "Oct", label: "Oct" },
    { value: "Nov", label: "Nov" },
    { value: "Dec", label: "Dec" },
  ];
  const SearchYear = [
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    // { value: "2029", label: "2029" },
    // { value: "2030", label: "2030" },
  ];
  const docStatus = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];
  const PayType = [
    { value: "1", label: "Auto Debit" },
    { value: "2", label: "Cheque" },
    { value: "3", label: "Cash" },
  ];
  // const TemplateDropdown = [
  //   { value: '1', label: 'Follow IP 1' },
  //   { value: '2', label: 'Follow IP 2' },
  //   { value: '3', label: 'Follow IP 3' },
  // ];

  const emailStatusDropdown = [
    { value: "0", label: "Exclude" },
    { value: "1", label: "Include" },
    { value: "2", label: "Group" },
  ];

  const emailSendStatus = [
    { value: "0", label: "Mail Not Send" },
    { value: "1", label: "Mail Sent" },
    { value: "2", label: "Template Not Selected" },
  ];



  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const handleSaveChanges = () => {
  //   // Save your changes logic here

  //   // After saving, ensure the table renders the same page
  //   setCurrentPage(currentPage);
  // };

  async function onSubmit(data1) {
    console.log(data1);
  }

  const columns = useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "trans_invoices_id",
        disableGlobalFilter: true,
      },
      {
        Header: "Property",
        accessor: "trans_invoices_property_name",
        // Cell: ({ value }) => (
        //   <div>
        //     {/* {value.split(',').map((nameAndDate, index) => (
        //       <div>{nameAndDate.trim()},</div>
        //     ))} */}
        //     {
        //       (value === 'null')
        //         ? <div>No Followups</div>
        //         : value.split(',').map((date, index) => (

        //           <div key={date}>Followup-{index + 1} / {date}</div>
        //         ))
        //     }
        //   </div>
        // ),
      },
      {
        Header: "Bank Code",
        accessor: "trans_invoices_bank_code",
      },
      {
        Header: "Vendor",
        accessor: "trans_invoices_vendor_name",
      },
      {
        Header: "Code",
        accessor: "trans_invoices_vendor_code",
      },
      {
        Header: "Due Date",
        accessor: "trans_invoices_due_date",
      },
      {
        // Header: () => (<div style={{ textAlign: 'right' }}>Amount</div>),
        Header: "Amount",
        accessor: "trans_invoices_amount",
        Cell: ({ value }) => <div style={{ paddingLeft: 10 }}>{value}</div>,
      },
      {
        Header: "Type",
        accessor: "trans_invoices_amount_typ",
        Cell: ({ value }) => (
          <div
            className={
              value === "Credit" ? "hs-text-danger" : "hs-text-success"
            }
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Invoice No.",
        accessor: "trans_invoices_number",
        Cell: ({ value }) => <div style={{ paddingLeft: 0 }}>{value}</div>,
      },
      {
        Header: "Tracker Status",
        accessor: "trans_invoices_tracker_status_name",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ value }) => <div className="email_col">{value}</div>,
      },
      {
        Header: "Inv. Upload Date",
        accessor: "trans_invoices_upload_date",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Doc-Received",
        accessor: "trans_invoices_doc_received",
        // accessor: row => `${row.trans_invoices_doc_received}, ${row.trans_invoices_doc_received_date}`,
        Cell: ({ value }) => (
          <div
            style={{ paddingLeft: 10 }}
            className={
              value === "Yes"
                ? "hs-text-success"
                : // : (value === 'Partially Paid')
                // ? 'hs-text-warning'
                "hs-text-danger"
            }
          >
            {value}
          </div>
        ),
      },
      // {
      //   Header: 'Upcoming Followups',
      //   accessor: 'trans_invoices_follow_up_upcoming_dates',
      //   Cell: ({ value }) => (
      //     <div>
      //       {/* {value.split(',').map((nameAndDate, index) => (
      //         <div>{nameAndDate.trim()},</div>
      //       ))} */}
      //       {
      //         (value === 'null')
      //           ? <div>No Followups</div>
      //           : value.split(',').map((date, index) => (

      //             <div key={date}>Followup-{index + 1} / {date}</div>
      //           ))
      //       }
      //     </div>
      //   ),
      // },
      {
        Header: "Email Status",
        accessor: "trans_invoices_mail_delivered",
        // accessor: row => `${row.trans_invoices_doc_received}, ${row.trans_invoices_doc_received_date}`,
        Cell: ({ value }) => (
          <div
            className={
              value === '0'
                ? "hs-text-warning"
                : value === '1'
                  ? 'hs-text-success'
                  : ''
            }
          >
            {
              value === '0'
                ? 'Mail Not Send'
                : value === '1'
                  ? 'Mail Sent'
                  : 'Template Not Selected'
            }
          </div>
        ),
      },
      {
        Header: "Email Sent Date",
        accessor: "trans_invoices_mail_date",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Email",
        accessor: "trans_email_status",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => <div className="email_col">{value}</div>,
      },
      {
        Header: "Email Template",
        accessor: "trans_template",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ value }) => <div className="template_col">{value}</div>,
      },
      {
        Header: "Action",
        accessor: "action",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    []
  );
  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };

  // const [addDate, setAddDate] = useState(new Date('2023-05-16T00:00:00'));
  const rows = reactTableData.tableRowsData;
  const dNone = "d-none";
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40, 50],
    placeholder: "Search by ...",
  };

  const {
    register: registerFormFilter,
    handleSubmit: handleSubmitFormFilter,
    reset: resetFilter,
    control: controlFormFilter,
    setValue: setValueFilter,
    formState: { errors: errorsFormFilter },
  } = useForm();
  const {
    register: registerFormPropertyFilter,
    handleSubmit: handleSubmitFormPropertyFilter,
    reset: resetPropertyFilter,
    control: controlFormPropertyFilter,
    setValue: setValuePropertyFilter,
    formState: { errors: errorsFormPropertyFilter },
  } = useForm();
  const {
    register: registerFormAddDate,
    handleSubmit: handleSubmitFormAddDate,
    reset: resetAddDate,
    control: controlFormAddDate,
    setValue: setValue1AddDate,
    formState: { errors: errorsFormAddDate },
  } = useForm();

  const onSubmitFormFilter = (data) => {
    setPageset(!pageset);

    const formdata = new FormData();
    if (data.filter_document_status.value === "1") {
      formdata.append(
        "month_year",
        `${data.filter_month.value}-${data.filter_year.value}`
      );
      // formdata.append('month_year', '');
      console.log(`${data.filter_month.value}-${data.filter_year.value}`);
      loadTrackerData("filter", formdata, 1);
    } else {
      formdata.append("month_year", "");
      loadTrackerData("filter", formdata, 0);
    }
    // const sendFilterFromDate = [{
    //   month_year: '',
    // }];
    console.log("formdata", data);
    DocumentandPropertyFilter = { ...data, key: "filter" };
    // handlePropertyFormClear();
  };

  const onSubmitFormPropertyFilter = (data) => {
    setPageset(!pageset);
    console.log(data);
    const formdata = new FormData();
    // if (data.property_name.label) {

    formdata.append(
      "property_name",
      data.property_name !== null &&
        data.property_name !== undefined &&
        data.property_name !== ""
        ? `${data.property_name.value}`
        : ""
    );
    formdata.append(
      "vendor_name",
      data.vendor_name !== null &&
        data.vendor_name !== undefined &&
        data.vendor_name !== ""
        ? `${data.vendor_name.value}`
        : ""
    );
    formdata.append(
      "tracker_status",
      data.tracker_status !== null &&
        data.tracker_status !== undefined &&
        data.tracker_status !== ""
        ? `${data.tracker_status.label}`
        : ""
    );
    formdata.append(
      "mail_status",
      data.mail_send_status !== null &&
        data.mail_send_status !== undefined &&
        data.mail_send_status !== ""
        ? `${data.mail_send_status.value}`
        : "-1"
    );
    console.log("formdata", formdata);

    loadTrackerData("filterProperty", formdata, 0);
    DocumentandPropertyFilter = { ...data, key: "filterProperty" };
    // handleFormClear();
    // // }
  };

  async function onSubmitFormAddDate(data) {
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;
    // console.log(date, remarks);
    // console.log(document.getElementById('invoice_doc_received_date').value);
    // console.log(document.getElementById('invoice_doc_remarks').value);
    // console.log("Add Date data:", data);
    // console.log(moment(data.invoice_doc_received_date).format("YYYY-MM-DD"));
    const sendAddFromDate = [
      {
        trans_invoices_doc_received_date: data.invoice_doc_received_date
          ? moment(data.invoice_doc_received_date).format("YYYY-MM-DD")
          : null,
        trans_invoices_service_date:
          data.service_date === null || data.service_date === ""
            ? null
            : moment(data.service_date).format("YYYY-MM-DD"),
        trans_invoices_service_thru_date:
          data.service_thru_date === null || data.service_thru_date === ""
            ? null
            : moment(data.service_thru_date).format("YYYY-MM-DD"),
        // trans_invoices_service_date: null,
        trans_invoices_doc_remarks: remarksData,
        trans_invoices_modified_by: "1",
        trans_invoices_modified_on: currentDateFormate,
      },
    ];

    // console.log("test", sendAddFromDate);

    await axios
      .put(
        `${getUserData.ses_URL}invoices/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${invoiceID}`,
        sendAddFromDate
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success("Document received date added successfully");
          setModal(false);
          loadTrackerData("initial");
        } else {
          toast.error("Try Again!");
          setModal(false);
        }
      });
    // console.log(data.invoice_doc_received_date);
  }

  // modal
  const [modal, setModal] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const [modalMailSingle, setModalMailSingle] = useState(false);
  const [invoiceID, setInvoiceID] = useState();
  const [vendorName, setVendorName] = useState();
  const [vendorCode, setVendorCode] = useState();
  const [emailStatus, setEmailStatus] = useState();
  const [receivedDate, setReceivedDate] = useState();
  const [serviceDate, setServiceDate] = useState();
  const [serviceThruDate, setServiceThruDate] = useState();
  const [remarksData, setRemarksData] = useState();
  const [hasDate, setHasDate] = useState();
  const [emptyDate, setEmptyDate] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  const [selectedOptionDocStatus, setSelectedOptionDocStatus] = useState("1");

  const handleOpenModal = (
    state,
    id,
    name,
    code,
    remarks,
    date,
    isDate,
    dateService,
    isDateService,
    thruDateService,
    isThruDateService
  ) => {
    // setLoading(true);
    resetAddDate();
    console.log(
      state,
      id,
      name,
      code,
      remarks,
      date,
      isDate,
      dateService,
      isDateService,
      thruDateService,
      isThruDateService
    );
    setModal(state);
    setInvoiceID(id);
    setHasDate(isDate);
    setVendorName(name);
    setVendorCode(code);
    setRemarksData(remarks !== "null" ? remarks : "");
    setReceivedDate(date !== "null" ? new Date(date) : "");
    setServiceDate(dateService !== "null" ? new Date(dateService) : "");
    setServiceThruDate(
      thruDateService !== "null" ? new Date(thruDateService) : ""
    );
    // console.log(receivedDate);
    // console.log(serviceDate);
    // console.log(remarksData);
    // setTimeout(() => {
    //   setRemarksData(remarks);
    //   setLoading(false);
    // }, 1000);
    if (date !== "null") {
      setValue1AddDate("trans_invoices_doc_received_date", date);
    } else {
      setValue1AddDate("trans_invoices_doc_received_date", "");
    }
    if (dateService !== "null") {
      setValue1AddDate("trans_invoices_service_date", dateService);
    } else {
      setValue1AddDate("trans_invoices_service_date", "");
    }
    if (thruDateService !== "null") {
      setValue1AddDate("trans_invoices_doc_received_date", thruDateService);
    } else {
      setValue1AddDate("trans_invoices_doc_received_date", "");
    }
  };
  // const handleDateChange = (date) => {
  //   console.log(date);
  //   setReceivedDate(date);
  //   setServiceDate(date);
  // };

  const toggleModalMail = () => {
    setModalMail(!modalMail);
  };
  const handleCancelMail = () => {
    toggleModalMail();
  };
  const toggleModalMailSingle = (id, name, code, emailStatus, tempId) => {
    console.log(id, name, code, emailStatus, tempId);
    setEmailStatus(emailStatus);
    setInvoiceID(id);
    setVendorName(name);
    setVendorCode(code);
    // setModalMailSingle(!modalMailSingle);
    if (tempId === "0") {
      alert('Please select the "Email Template"');
    } else {
      setModalMailSingle(!modalMailSingle);
    }
  };
  const handleCancelMailSingle = () => {
    toggleModalMailSingle();
  };

  //Clear the all filters data
  const clearAllFilters = () => {
    resetFilter();
    setValueFilter("filter_document_status", "");
    setValueFilter("filter_month", "");
    setValueFilter("filter_year", "");
    loadTrackerData("initial");
    setSelectedOptionDocStatus("1");
    resetPropertyFilter();
    setValuePropertyFilter("property_name", "");
    setValuePropertyFilter("vendor_name", "");
    setValuePropertyFilter("tracker_status", "");
    setValuePropertyFilter("mail_status", "");
    loadTrackerData("initial");
  }

  //clear the filter1
  const handleFormClear = () => {
    clearAllFilters();
  };
  //clear the filter2
  const handlePropertyFormClear = () => {
    clearAllFilters();
  };
  // const handleSubmitMail = () => {
  //   console.log('Submitted mail');
  //   toggleModalMail();
  // };

  async function handleSubmitMail() {
    setIsButtonLoading(true);
    console.log("mail inprogress");

    await axios.post(`${getUserData.ses_URL}followup/4/0`).then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success(
          <div>
            <p>Mail Sent to all successfully</p>
            <b>{res.data.data}</b>
          </div>
        );
        toggleModalMail();
        setIsButtonLoading(false);
        // window.location.reload();
        loadTrackerData("initial");
      } else {
        toast.error("Try Again!");
        setModalMail(false);
        setIsButtonLoading(false);
      }
    });
  }
  async function handleSubmitMailSingle() {
    setIsButtonLoading(true);
    console.log(emailStatus);
    let configAPI;
    if (emailStatus === "2") {
      console.log("mail grouped inprogress");
      configAPI = axios.post(`${getUserData.ses_URL}followup/3/${invoiceID}`);
    } else {
      console.log("mail single inprogress");
      configAPI = axios.post(`${getUserData.ses_URL}followup/3/${invoiceID}`);
    }
    await configAPI.then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success(
          <div>
            <p>Mail Sent successfully</p>
            <b>{res.data.data}</b>
          </div>
        );
        toggleModalMailSingle();
        setIsButtonLoading(false);
        // window.location.reload();
        loadTrackerData("initial");
      } else {
        toast.error("Try Again!");
        setModalMailSingle(false);
        setIsButtonLoading(false);
      }
    });
  }

  useEffect(() => {
    loadTrackerData("initial");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="table-align-middle tracker-screen">
          <div className="react-table__wrapper">
            <div className="card__title d-flex align-items-baseline">
              <h5 className="bold-text">Table tracker-list</h5>
            </div>
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
              fullCustomizer
              advanceCustomizerOff={dNone}
            />
          </div>

          <div className="TrackerCustomStyle">
            <div className="row">
              <form
                className="form col-md-10"
                onSubmit={handleSubmitFormFilter(onSubmitFormFilter)}
              >
                {selectedOptionDocStatus === "1" && (
                  <>
                    <div className="col-md-2 pl-0">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="filter_month"
                        >
                          Month *
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            <Controller
                              name="filter_month"
                              id="filter_month"
                              control={controlFormFilter}
                              rules={{ required: "Please select the month" }}
                              render={({ value, ref, field }) => (
                                <Select
                                  // defaultValue={SearchMonth[0]}
                                  inputRef={ref}
                                  options={SearchMonth}
                                  components={animatedComponents}
                                  value={SearchMonth.find(
                                    (option) => option.value === value
                                  )}
                                  {...field}
                                />
                              )}
                            />
                            {errorsFormFilter.filter_month && (
                              <span className="form__form-group-error">
                                {errorsFormFilter.filter_month.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 pl-0">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="filter_year"
                        >
                          Year *
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            <Controller
                              name="filter_year"
                              id="filter_year"
                              control={controlFormFilter}
                              rules={{ required: "Please select the year" }}
                              render={({ value, ref, field }) => (
                                <Select
                                  // defaultValue={SearchYear[0]}
                                  inputRef={ref}
                                  options={SearchYear}
                                  components={animatedComponents}
                                  value={SearchYear.find(
                                    (option) => option.value === value
                                  )}
                                  {...field}
                                />
                              )}
                            />
                            {errorsFormFilter.filter_year && (
                              <span className="form__form-group-error">
                                {errorsFormFilter.filter_year.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className="col-md-2 pl-0"
                  style={{ position: "relative", zIndex: "1" }}
                >
                  <div className="form__form-group">
                    <label
                      className="form__form-group-label"
                      htmlFor="filter_document_status"
                    >
                      Doc-Received *
                    </label>
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap">
                        <Controller
                          name="filter_document_status"
                          id="filter_document_status"
                          control={controlFormFilter}
                          rules={{ required: "Please select the status" }}
                          render={({ value, ref, field }) => (
                            <Select
                              // defaultValue={docStatus[0]}
                              inputRef={ref}
                              options={docStatus}
                              components={animatedComponents}
                              value={docStatus.find(
                                (option) => option.value === value
                              )}
                              {...field}
                              onChange={(event) => {
                                console.log(event.value);
                                setSelectedOptionDocStatus(event.value);
                                field.onChange(event);
                              }}
                            />
                          )}
                        // onChange={() => console.log('test')}
                        />
                        {errorsFormFilter.filter_document_status && (
                          <span className="form__form-group-error">
                            {errorsFormFilter.filter_document_status.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 make-center-ed pl-0">
                  <ButtonToolbar>
                    <ButtonGroup className="" dir="ltr">
                      <Button onClick={handleFormClear}>
                        <span className="lnr" /> Clear
                      </Button>
                      {/* <Button className="ml-2" color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                    <ButtonGroup className="" dir="ltr">
                      <Button color="success" type="submit">
                        <span className="lnr" /> Search
                      </Button>
                      {/* <Button color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </form>
              <div className="col-md-2 send-to-all">
                <ButtonToolbar>
                  <ButtonGroup className="" dir="ltr">
                    <Button color="success" onClick={toggleModalMail}>
                      <span className="lnr lnr-envelope icon-edit" /> Send To
                      All
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            <div className="row">
              <form
                className="form col-md-12"
                onSubmit={handleSubmitFormPropertyFilter(
                  onSubmitFormPropertyFilter
                )}
              >
                <>
                  <div className="col-md-3 pl-0">
                    <div className="form__form-group">
                      <label
                        className="form__form-group-label"
                        htmlFor="property_name"
                      >
                        Property
                      </label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="property_name"
                            id="property_name"
                            control={controlFormPropertyFilter}
                            // rules={{ required: 'Please select the property' }}
                            render={({ value, ref, field }) => (
                              <Select
                                // defaultValue={SearchMonth[0]}
                                inputRef={ref}
                                options={DpProperty}
                                components={animatedComponents}
                                value={DpProperty.find(
                                  (option) => option.value === value
                                )}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                          {/* {errorsFormPropertyFilter.property_name && <span className="form__form-group-error">{errorsFormPropertyFilter.property_name.message}</span>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 pl-0">
                    <div className="form__form-group">
                      <label
                        className="form__form-group-label"
                        htmlFor="vendor_name"
                      >
                        Vendor
                      </label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="vendor_name"
                            id="vendor_name"
                            control={controlFormPropertyFilter}
                            render={({ value, ref, field }) => (
                              <Select
                                inputRef={ref}
                                options={DpVendorFilter}
                                components={animatedComponents}
                                value={DpVendorFilter.find(
                                  (option) => option.value === value
                                )}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 pl-0">
                    <div className="form__form-group">
                      <label
                        className="form__form-group-label"
                        htmlFor="tracker_status"
                      >
                        Tracker Status
                      </label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="tracker_status"
                            id="tracker_status"
                            control={controlFormPropertyFilter}
                            render={({ value, ref, field }) => (
                              <Select
                                inputRef={ref}
                                options={DpTrackerStatus}
                                components={animatedComponents}
                                value={DpTrackerStatus.find(
                                  (option) => option.value === value
                                )}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 pl-0">
                    <div className="form__form-group">
                      <label
                        className="form__form-group-label"
                        htmlFor="mail_status"
                      >
                        Email Send Status
                      </label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="mail_send_status"
                            id="mail_send_status"
                            control={controlFormPropertyFilter}
                            render={({ value, ref, field }) => (
                              <Select
                                inputRef={ref}
                                options={emailSendStatus}
                                components={animatedComponents}
                                value={emailSendStatus.find(
                                  (option) => option.value === value
                                )}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="col-md-3 make-center-ed pl-0">
                  <ButtonToolbar>
                    <ButtonGroup className="" dir="ltr">
                      <Button onClick={handlePropertyFormClear}>
                        <span className="lnr" /> Clear
                      </Button>
                      {/* <Button className="ml-2" color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                    <ButtonGroup className="" dir="ltr">
                      <Button color="success" type="submit">
                        <span className="lnr" /> Search
                      </Button>
                      {/* <Button color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </form>
            </div>
            <ReactTableBase
              key={withSearchEngine ? "modified" : "common"}
              columns={reactTableData.tableHeaderData}
              data={tableData}
              tableConfig={tableConfig}
              pageIndex={currentPage} // Pass currentPage
              onPageChange={handlePageChange} // Pass handlePageChange
              pageset={pageset}
              pageSize={pageSize} // Pass pageSize
              onPageSizeChange={handlePageSizeChange} // Pass onPageSizeChange
            />

          </div>

          <Modal
            isOpen={modal}
            toggle={toggle}
            backdrop="static"
            keyboard={false}
            modalClassName="ltr-support"
            size="lg"
            fullscreen="lg"
            className="modal-dialog modal-dialog--primary modal-dialog--header hs-modal hs-modal__pay"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              <h4 className="text-modal--primary modal__title">
                Add Document Received Date
              </h4>
            </div>
            <div
              className="modal__body"
              style={{ paddingRight: 40, paddingLeft: 40 }}
            >
              <div className="text-center">
                <Badge
                  color="primary"
                  className="hs-badge"
                  style={{ marginBottom: 20 }}
                >
                  {vendorName}
                </Badge>
                <span>{vendorCode}</span>
              </div>
              <Col md={12} lg={12} className="p-0">
                <Card className="p-0">
                  {/* <div className="payment-section">
                    <div className="payment-section__total">
                      <span>Total Amount: </span>
                      <span className="total-balance">1,50,000</span>
                    </div>
                    <div className="payment-section__paid">
                      <span>Paid Amount: </span>
                      <span className="paid-balance">1,00,000</span>
                    </div>
                  </div>
                  <div className="text-center">
                    <span>Remaining Amount: </span>
                    <span className="remaining-balance">50000</span>
                  </div> */}
                  <form
                    className="form hs-form__pay"
                    onSubmit={handleSubmitFormAddDate(onSubmitFormAddDate)}
                  >
                    <div className="col-md-6">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="invoice_doc_received_date"
                        >
                          Document Received Date
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            {/* {
                              (hasDate === true)
                                ? ( */}
                            <Controller
                              name="invoice_doc_received_date"
                              // id="invoice_doc_received_date"
                              control={controlFormAddDate}
                              // rules={{ required: 'Date is required' }}
                              // defaultValue={(hasDate === true) ? receivedDate : emptyDate}
                              defaultValue={receivedDate}
                              // defaultValue={receivedDate || emptyDate}
                              render={({ value, ref, field }) => (
                                <DatePicker
                                  {...field}
                                  inputRef={ref}
                                  ref={inputRef}
                                  selected={receivedDate}
                                  // selected={receivedDate || field.value}
                                  // onChange={handleDateChange}
                                  // onChange={date => field.onChange(date)}
                                  onChange={(date) => {
                                    setReceivedDate(date);
                                    field.onChange(date);
                                  }}
                                />
                              )}
                            />
                            {/* ) 
                                : (
                                  <Controller
                                    name="invoice_doc_received_date"
                                    id="invoice_doc_received_date"
                                    control={controlFormAddDate}
                                    rules={{ required: 'Date is required' }}
                                    defaultValue={emptyDate}
                                    render={({ value, ref, field }) => (
                                      <DatePicker
                                        {...field}
                                        inputRef={ref}
                                        selected={emptyDate || field.value}
                                        onChange={date => field.onChange(date)}
                                      />
                                    )}
                                  />
                                )
                            } */}
                            {/* {errorsFormAddDate.invoice_doc_received_date && <span className="form__form-group-error">{errorsFormAddDate.invoice_doc_received_date.message}</span>} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="service_date"
                        >
                          Service Date
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            <Controller
                              name="service_date"
                              // id="service_date"
                              control={controlFormAddDate}
                              defaultValue={serviceDate}
                              render={({ value, ref, field }) => (
                                <DatePicker
                                  {...field}
                                  inputRef={ref}
                                  ref={inputRef}
                                  selected={serviceDate}
                                  onChange={(date1) => {
                                    setServiceDate(date1);
                                    field.onChange(date1);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="service_thru_date"
                        >
                          Service thru Date
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            <Controller
                              name="service_thru_date"
                              // id="service_date"
                              control={controlFormAddDate}
                              defaultValue={serviceThruDate}
                              render={({ value, ref, field }) => (
                                <DatePicker
                                  {...field}
                                  inputRef={ref}
                                  ref={inputRef}
                                  selected={serviceThruDate}
                                  onChange={(date1) => {
                                    setServiceThruDate(date1);
                                    field.onChange(date1);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="invoice_doc_remarks1"
                        >
                          Remarks
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            {/* <Controller
                              name="invoice_doc_remarks"
                              type="text"
                              control={controlFormAddDate}
                              placeholder="Enter the text..."
                              // id="invoice_doc_remarks"
                              render={({ field }) => (
                                <textarea
                                  {...field}
                                  // value={remarksData}
                                  defaultValue={remarksData}
                                  row="3"
                                  // onChange={(data) => {
                                  //   setRemarksData(data);
                                  //   field.onChange(data);
                                  // }}
                                />
                              )}
                            /> */}
                            <textarea
                              value={remarksData}
                              onChange={(event) => {
                                setRemarksData(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <ButtonToolbar
                        className="modal__footer justify-content-center"
                        style={{ marginTop: 15, marginBottom: 10 }}
                      >
                        <Button className="modal_cancel" onClick={toggle}>
                          Close
                        </Button>
                        <Button color="success" type="submit">
                          Submit
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </form>
                </Card>
              </Col>
            </div>
          </Modal>

          {/* modal mail */}
          <Modal
            isOpen={modalMail}
            toggle={toggleModalMail}
            backdrop="static"
            keyboard={false}
            modalClassName="ltr-support"
            size="lg"
            fullscreen="lg"
            className="modal-dialog modal-dialog--primary modal-dialog--header hs-modal hs-modal__pay"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                aria-label="modal__close-btn"
                type="button"
                // onClick={toggleModalMail}
                onClick={handleCancelMail}
              />
              <h4 className="text-modal--primary modal__title">
                Mail Send To All
              </h4>
            </div>
            <div
              className="modal__body"
              style={{ paddingRight: 30, paddingLeft: 30 }}
            >
              <Col md={12} lg={12} className="p-0">
                <Card className="p-0">
                  <div className="mail-body">
                    <div className="text-bold">Are you Sure?</div>
                    <div>
                      Do you want to send the followups mail to all vendors.
                    </div>
                  </div>
                  <div className="col-md-12 pt-3">
                    <ButtonToolbar
                      className="modal__footer justify-content-center"
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      <Button
                        className="modal_cancel"
                        onClick={handleCancelMail}
                      >
                        Cancel
                      </Button>
                      <Button color="success" onClick={handleSubmitMail}>
                        {isButtonLoading ? "Loading..." : "Send"}
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Card>
              </Col>
            </div>
          </Modal>

          {/* modal mail single / group */}
          <Modal
            isOpen={modalMailSingle}
            // toggle={toggleModalMailSingle}
            backdrop="static"
            keyboard={false}
            modalClassName="ltr-support"
            size="lg"
            fullscreen="lg"
            className="modal-dialog modal-dialog--primary modal-dialog--header hs-modal hs-modal__pay"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                aria-label="modal__close-btn"
                type="button"
                // onClick={toggleModalMailSingle}
                onClick={handleCancelMailSingle}
              />
              <h4 className="text-modal--primary modal__title">
                Mail Send To {vendorName}
              </h4>
            </div>
            <div
              className="modal__body"
              style={{ paddingRight: 30, paddingLeft: 30 }}
            >
              <Col md={12} lg={12} className="p-0">
                <Card className="p-0">
                  <div className="mail-body">
                    <div className="text-bold">Are you Sure?</div>
                    <div>Do you want to send the followups mail to vendor.</div>
                    <br />
                    {`${emailStatus}` === "2" ? (
                      <div>Grouped Mail</div>
                    ) : (
                      <>
                        <div>
                          <b>Name: </b>
                          {vendorName}
                        </div>
                        <div>
                          <b>Code: </b>
                          {vendorCode}
                        </div>
                        <div>
                          <b>Id: </b>
                          {invoiceID}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-12 pt-3">
                    <ButtonToolbar
                      className="modal__footer justify-content-center"
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      <Button
                        className="modal_cancel"
                        onClick={handleCancelMailSingle}
                      >
                        Cancel
                      </Button>
                      <Button color="success" onClick={handleSubmitMailSingle}>
                        {isButtonLoading ? "Loading..." : "Send"}
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Card>
              </Col>
            </div>
          </Modal>

          {followPopup ? (
            <TrackerPopup
              invoicesNumber={invoicesNumber}
              setFollowPopup={setFollowPopup}
            />
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};

TrackerTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default TrackerTable;
