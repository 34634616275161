/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button, Modal, ButtonToolbar, ButtonGroup, Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import PlusBoldIcon from 'mdi-react/PlusBoldIcon';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import DpTrackerStatus from '../../../../Components/Dropdowns/DpTrackerStatus';
import DpPropertyReport from '../../../../Components/Dropdowns/DpPropertyReport';
import DpVendorFilterReport from '../../../../Components/Dropdowns/DpVendorFilterReport';


const ReportsTable = () => {
  const animatedComponents = makeAnimated();
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  // Table Data
  // const data = [
  //   {
  //     tracker_id: 1,
  //     tracker_vendor: 'Vendor 1',
  //     tracker_code: 'ABCDE12345',
  //     tracker_invoice: 'INV23-11-04-112',
  //     tracker_amount: '125000',
  //     tracker_type: 'Debit',
  //     tracker_paid_amount: '100000',
  //     payment_received_date: '11-04-2023',
  //     tracker_status: 'Partially Paid',
  //     trans_invoices_follow_up_history_dates: 'Follow-01 / 25-04-2023',
  //     action: <ButtonToolbar>
  //       <ButtonGroup className="btn-group--icons" dir="ltr">
  //         {/* <Link to="/master/tracker/"> */}
  //         <Button id="btn-view-master-tracker-1" color="success" onClick={() => handleOpenModal(true, 1)}><span className="lnr lnr-enter" /> Details</Button>
  //         {/* </Link> */}
  //       </ButtonGroup>
  //     </ButtonToolbar>,
  //   },
  //   {
  //     tracker_id: 2,
  //     tracker_vendor: 'Vendor 2',
  //     tracker_code: 'ABCDE12345',
  //     tracker_invoice: 'INV23-11-04-113',
  //     tracker_amount: '65000',
  //     tracker_type: 'Debit',
  //     tracker_paid_amount: '65000',
  //     payment_received_date: '19-09-2023',
  //     tracker_status: 'Fully Paid',
  //     trans_invoices_follow_up_history_dates: 'Follow-03 / 12-04-2023, Follow-05 / 25-04-2023, Follow-10 / 30-04-2023',
  //     action: <ButtonToolbar>
  //       <ButtonGroup className="btn-group--icons" dir="ltr">
  //         {/* <Link to="/master/tracker/"> */}
  //         <Button id="btn-view-master-tracker-2" color="success" onClick={() => handleOpenModal(true, 2)}><span className="lnr lnr-enter" /> Details</Button>
  //         {/* </Link> */}
  //       </ButtonGroup>
  //     </ButtonToolbar>,
  //   },
  //   {
  //     tracker_id: 3,
  //     tracker_vendor: 'Vendor 3',
  //     tracker_code: 'ABCDE12345',
  //     tracker_invoice: 'INV23-11-04-114',
  //     tracker_amount: '100000',
  //     tracker_type: 'Credit',
  //     tracker_paid_amount: '0',
  //     payment_received_date: '15-02-2023',
  //     tracker_status: 'Not Paid',
  //     trans_invoices_follow_up_history_dates: 'Follow-20 / 12-04-2023, Follow-25 / 30-04-2023',
  //     action: <ButtonToolbar>
  //       <ButtonGroup className="btn-group--icons" dir="ltr">
  //         {/* <Link to="/master/tracker/"> */}
  //         <Button id="btn-view-master-tracker-3" color="success" onClick={() => handleOpenModal(true, 3)}><span className="lnr lnr-enter" /> Details</Button>
  //         {/* </Link> */}
  //       </ButtonGroup>
  //     </ButtonToolbar>,
  //   },
  // ];

  const data = [];
  const [tableData, setTableData] = useState([]);

  async function loadTrackerData(dataStatus, filterData, filterType) {
    console.log(filterData);
    let configAPI;
    if (dataStatus === 'initial') {
      configAPI = axios.get(`${getUserData.ses_URL}invoices/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`);
    } else if (dataStatus === 'filter') {
      configAPI = axios.post(`${getUserData.ses_URL}invoices_filter/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${filterType}/0`, filterData);
    } else if (dataStatus === 'filterProperty') {
      configAPI = axios.post(`${getUserData.ses_URL}invoices_filter_property/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${filterType}/0`, filterData);
    }

    await configAPI
      // axios.get('http://127.0.0.1:8000/user_type/1/30888374364/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName) => {
          // console.log(res.data.data);
          // console.log(res.data.status);
          if (res.data.data[keyName].trans_invoices_follow_up_history_dates !== null) {
            data.push({
              trans_invoices_id: `${res.data.data[keyName].trans_invoices_id}`,
              trans_invoices_vendor_name: res.data.data[keyName].trans_invoices_vendor_name !== null ? `${res.data.data[keyName].trans_invoices_vendor_name}` : '-',
              trans_invoices_property_name: res.data.data[keyName].trans_invoices_property_name !== null ? `${res.data.data[keyName].trans_invoices_property_name}` : '',
              trans_invoices_vendor_code: `${res.data.data[keyName].trans_invoices_vendor_code}`,
              trans_invoices_due_date: moment(`${res.data.data[keyName].trans_invoices_due_date}`).format('YYYY-MM-DD'),
              trans_invoices_amount: `${res.data.data[keyName].trans_invoices_amount}`,
              trans_invoices_amount_typ: `${res.data.data[keyName].trans_invoices_amount_typ}`,
              trans_invoices_number: `${res.data.data[keyName].trans_invoices_number}`,
              trans_invoices_doc_received: `${res.data.data[keyName].trans_invoices_doc_received}`,
              trans_invoices_follow_up_history_dates: `${res.data.data[keyName].trans_invoices_follow_up_history_dates}`,
              action: <ButtonToolbar>
                <ButtonGroup className="btn-group--icons" dir="ltr">
                  {/* <Link to="/master/tracker/"> */}
                  <Button id="btn-view-master-tracker-1" color="success" onClick={() => handleOpenModal(true, 1)}><span className="lnr lnr-enter" /> Details</Button>
                  {/* </Link> */}
                </ButtonGroup>
              </ButtonToolbar>,
            });
          }
        });
        console.log(data);
        setTableData(data);
      });
  }

  console.log("data" + data);
  console.log("tableData" + tableData);

  const SearchMonth = [
    { value: 'Jan', label: 'Jan' },
    { value: 'Feb', label: 'Feb' },
    { value: 'Mar', label: 'Mar' },
    { value: 'Apr', label: 'Apr' },
    { value: 'May', label: 'May' },
    { value: 'Jun', label: 'Jun' },
    { value: 'Jul', label: 'Jul' },
    { value: 'Aug', label: 'Aug' },
    { value: 'Sep', label: 'Sep' },
    { value: 'Oct', label: 'Oct' },
    { value: 'Nov', label: 'Nov' },
    { value: 'Dec', label: 'Dec' },
  ];
  const SearchYear = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
  ];
  const docStatus = [
    { value: '0', label: 'No' },
    { value: '1', label: 'Yes' },
  ];
  const [selectedOptionDocStatus, setSelectedOptionDocStatus] = useState('1');
  const [formData, setTestData] = useState({});
  const [inputActive, setinputActive] = useState(false);
  const {
    handleSubmit, register, reset, control, setValue, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  const {
    register: registerFormPropertyFilter, handleSubmit: handleSubmitFormPropertyFilter, reset: resetPropertyFilter, control: controlFormPropertyFilter, setValue: setValuePropertyFilter, formState: { errors: errorsFormPropertyFilter },
  } = useForm();
  

  const onSubmitFormFilter = (data1) => {
    console.log('Filter data1:', data1);
    const formdata = new FormData();
    if (data1.filter_document_status.value === '1') {
      formdata.append('month_year', `${data1.filter_month.value}-${data1.filter_year.value}`);
      // formdata1.append('month_year', '');
      console.log(`${data1.filter_month.value}-${data1.filter_year.value}`);
      loadTrackerData('filter', formdata, 1);
    } else {
      formdata.append('month_year', '');
      loadTrackerData('filter', formdata, 0);
    }
    // const sendFilterFromDate = [{
    //   month_year: '',
    // }];

    console.log('formdata', formdata);
  };

  const onSubmitFormPropertyFilter = (data) => {
    console.log('Property Filter data:', data);
    const formdata = new FormData();
    // if (data.property_name.label) {
      // console.log('testing')
      // console.log(data.property_name.value)
      formdata.append('property_name', (data.property_name !== null) && (data.property_name !== undefined) && (data.property_name !== '') ? `${data.property_name.value}` : '');
      formdata.append('vendor_name', (data.vendor_name !== null) && (data.vendor_name !== undefined) && (data.vendor_name !== '') ? `${data.vendor_name.value}` : '');
      formdata.append('tracker_status', '');
      loadTrackerData('filterProperty', formdata, 0);
      // // }
      console.log('formdata', formdata);
  };

  async function onSubmit(data1) {
    console.log(data1);
  }

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'trans_invoices_id',
        disableGlobalFilter: true,
      },
      {
        Header: 'Property',
        accessor: 'trans_invoices_property_name',
        // Cell: ({ value }) => (
        //   <div>
        //     {/* {value.split(',').map((nameAndDate, index) => (
        //       <div>{nameAndDate.trim()},</div>
        //     ))} */}
        //     {
        //       (value === 'null')
        //         ? <div>No Followups</div>
        //         : value.split(',').map((date, index) => (

        //           <div key={date}>Followup-{index + 1} / {date}</div>
        //         ))
        //     }
        //   </div>
        // ),
      },
      {
        Header: 'Vendor',
        accessor: 'trans_invoices_vendor_name',

      },
      {
        Header: 'Code',
        accessor: 'trans_invoices_vendor_code',
      },
      {
        Header: 'Due Date',
        accessor: 'trans_invoices_due_date',

      },
      {
        // Header: () => (<div style={{ textAlign: 'right' }}>Amount</div>),
        Header: 'Amount',
        accessor: 'trans_invoices_amount',
        Cell: ({ value }) => <div style={{ paddingLeft: 10 }}>{value}</div>,
      },
      {
        Header: 'Type',
        accessor: 'trans_invoices_amount_typ',
        Cell: ({ value }) => (
          <div className={
            (value === 'Credit')
              ? 'hs-text-danger'
              : 'hs-text-success'
          }
          >{value}</div>
        ),
      },
      {
        Header: 'Invoice No.',
        accessor: 'trans_invoices_number',
        Cell: ({ value }) => <div style={{ paddingLeft: 10 }}>{value}</div>,
      },
      {
        Header: 'Doc-Received',
        accessor: 'trans_invoices_doc_received',
        Cell: ({ value }) => (
          <div className={
            (value === 'Yes')
              ? 'hs-text-success'
              // : (value === 'Partially Paid')
              // ? 'hs-text-warning'
              : 'hs-text-danger'
          }
          >{value}</div>
        ),
      },
      {
        Header: 'Followed Dates',
        accessor: 'trans_invoices_follow_up_history_dates',
        Cell: ({ value }) => (
          <div>
            {/* {value.split(',').map((nameAndDate, index) => (
              <div>{nameAndDate.trim()},</div>
            ))} */}
            {
              (value === 'null')
                ? <div>No Followups</div>
                : value.split(',').map((date, index) => (

                  <div key={date}>Followup-{index + 1} / {date}</div>
                ))
            }
          </div>
        ),
      },
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
    ],
    [],
  );

  // const [tableData, setTableData] = useState(data);
  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  // Excel file format
  // console.log("test" + tableData)
  const newExcelFormatData = tableData.map(item => ({
    Invoice_ID: item.trans_invoices_id,
    Property: item.trans_invoices_property_name,
    Vendor: item.trans_invoices_vendor_name,
    Code: item.trans_invoices_vendor_code,
    Due_Date: item.trans_invoices_due_date,
    Amount: item.trans_invoices_amount,
    Type: item.trans_invoices_amount_typ,
    Invoice_No: item.trans_invoices_number,
    Doc_Received: item.trans_invoices_doc_received,
    Followed_Dates: item.trans_invoices_follow_up_history_dates,
  }));
  // console.log(newExcelFormatData);

  // console.log(reactTableData);
  const rows = reactTableData.tableRowsData;
  const dNone = 'd-none';
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by ...',
  };


  const handleFormClear = () => {
    reset();
    setValue('filter_document_status', '');
    setValue('filter_month', '');
    setValue('filter_year', '');
    loadTrackerData('initial');
    setSelectedOptionDocStatus('1');
    handlePropertyFormClear();
  };

  const handlePropertyFormClear = () => {
    resetPropertyFilter();
    setValuePropertyFilter('property_name', '');
    setValuePropertyFilter('vendor_name', '');
    setValuePropertyFilter('tracker_status', '');
    loadTrackerData('initial');
  };


  // modal
  const [modal, setModal] = useState(false);
  const [reportID, setReportID] = useState();
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const handleOpenModal = (state, id) => {
    console.log(state, id);
    setModal(state);
    setReportID(id);
  };

  // modal Table Data
  const dataModal = [
    {
      SN: 1,
      Invoice: 'INV23-11-04-113',
      Paid_Amount: '5000',
      Paid_Date: '01-04-2023',
    },
    {
      SN: 2,
      Invoice: 'INV23-11-04-115',
      Paid_Amount: '15000',
      Paid_Date: '05-04-2023',
    },
    {
      SN: 3,
      Invoice: 'INV23-11-04-120',
      Paid_Amount: '130000',
      Paid_Date: '10-04-2023',
    },
    {
      SN: 4,
      Invoice: 'INV23-11-04-113',
      Paid_Amount: '8000',
      Paid_Date: '11-04-2023',
    },
  ];
  const columnsModal = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'SN',
        disableGlobalFilter: true,
      },
      {
        Header: 'Invoice',
        accessor: 'Invoice',
      },
      {
        Header: 'Paid Amount',
        accessor: 'Paid_Amount',
      },
      {
        Header: 'Paid Date',
        accessor: 'Paid_Date',
      },
    ],
    [],
  );
  const [tableDataModal, setTableDataModal] = useState(dataModal);
  const reactTableDataModal = { tableHeaderData: columnsModal, tableRowsData: tableDataModal };

  useEffect(() => {
    loadTrackerData('initial');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col md={12} lg={12} className="report_table">
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title d-flex align-items-baseline">
              <h5 className="bold-text">Table report-list</h5>
              <CSVLink data={newExcelFormatData} filename="reports.csv">
                <Badge
                  color="primary"
                  className="hs-badge__btn"
                  style={{ marginLeft: 15 }}
                >
                  Download CSV
                </Badge>
              </CSVLink>
            </div>

            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
              fullCustomizer
              advanceCustomizerOff={dNone}
            />
          </div>

          <div>
            <div className="row">
              <form
                className="form col-md-10"
                onSubmit={handleSubmit(onSubmitFormFilter)}
              >
                {selectedOptionDocStatus === "1" && (
                  <>
                    <div className="col-md-2 pl-0">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="filter_month"
                        >
                          Month *
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            <Controller
                              name="filter_month"
                              id="filter_month"
                              control={control}
                              rules={{ required: "Please select the month" }}
                              render={({ value, ref, field }) => (
                                <Select
                                  // defaultValue={SearchMonth[0]}
                                  inputRef={ref}
                                  options={SearchMonth}
                                  components={animatedComponents}
                                  value={SearchMonth.find(
                                    (option) => option.value === value
                                  )}
                                  {...field}
                                />
                              )}
                            />
                            {errors.filter_month && (
                              <span className="form__form-group-error">
                                {errors.filter_month.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 pl-0">
                      <div className="form__form-group">
                        <label
                          className="form__form-group-label"
                          htmlFor="filter_year"
                        >
                          Year *
                        </label>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap">
                            <Controller
                              name="filter_year"
                              id="filter_year"
                              control={control}
                              rules={{ required: "Please select the year" }}
                              render={({ value, ref, field }) => (
                                <Select
                                  // defaultValue={SearchYear[0]}
                                  inputRef={ref}
                                  options={SearchYear}
                                  components={animatedComponents}
                                  value={SearchYear.find(
                                    (option) => option.value === value
                                  )}
                                  {...field}
                                />
                              )}
                            />
                            {errors.filter_year && (
                              <span className="form__form-group-error">
                                {errors.filter_year.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="col-md-2 pl-0"
                  style={{ position: "relative", zIndex: "1" }}
                >
                  <div className="form__form-group">
                    <label
                      className="form__form-group-label"
                      htmlFor="filter_document_status"
                    >
                      Doc-Received *
                    </label>
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap">
                        <Controller
                          name="filter_document_status"
                          id="filter_document_status"
                          control={control}
                          rules={{ required: "Please select the status" }}
                          render={({ value, ref, field }) => (
                            <Select
                              // defaultValue={docStatus[0]}
                              inputRef={ref}
                              options={docStatus}
                              components={animatedComponents}
                              value={docStatus.find(
                                (option) => option.value === value
                              )}
                              {...field}
                              onChange={(event) => {
                                console.log(event.value);
                                setSelectedOptionDocStatus(event.value);
                                field.onChange(event);
                              }}
                            />
                          )}
                          // onChange={() => console.log('test')}
                        />
                        {errors.filter_document_status && (
                          <span className="form__form-group-error">
                            {errors.filter_document_status.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 make-center-ed pl-0">
                  <ButtonToolbar>
                    <ButtonGroup className="" dir="ltr">
                      <Button onClick={handleFormClear}>
                        <span className="lnr" /> Clear
                      </Button>
                      {/* <Button className="ml-2" color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                    <ButtonGroup className="" dir="ltr">
                      <Button color="success" type="submit">
                        <span className="lnr" /> Search
                      </Button>
                      {/* <Button color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </form>
            </div>
            <div className="row">
              <form
                className="form col-md-10"
                onSubmit={handleSubmitFormPropertyFilter(
                  onSubmitFormPropertyFilter
                )}
              >
                <>
                  <div className="col-md-4 pl-0">
                    <div className="form__form-group">
                      <label
                        className="form__form-group-label"
                        htmlFor="property_name"
                      >
                        Property
                      </label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="property_name"
                            id="property_name"
                            control={controlFormPropertyFilter}
                            // rules={{ required: 'Please select the property' }}
                            render={({ value, ref, field }) => (
                              <Select
                                // defaultValue={SearchMonth[0]}
                                inputRef={ref}
                                options={DpPropertyReport}
                                components={animatedComponents}
                                value={DpPropertyReport.find(
                                  (option) => option.value === value
                                )}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                          {/* {errorsFormPropertyFilter.property_name && <span className="form__form-group-error">{errorsFormPropertyFilter.property_name.message}</span>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 pl-0">
                    <div className="form__form-group">
                      <label
                        className="form__form-group-label"
                        htmlFor="vendor_name"
                      >
                        Vendor
                      </label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="vendor_name"
                            id="vendor_name"
                            control={controlFormPropertyFilter}
                            render={({ value, ref, field }) => (
                              <Select
                                inputRef={ref}
                                options={DpVendorFilterReport}
                                components={animatedComponents}
                                value={DpVendorFilterReport.find(
                                  (option) => option.value === value
                                )}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-2 pl-0">
                    <div className="form__form-group">
                      <label className="form__form-group-label" htmlFor="tracker_status">Status</label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <Controller
                            name="tracker_status"
                            id="tracker_status"
                            control={controlFormPropertyFilter}
                            render={({ value, ref, field }) => (
                              <Select
                                inputRef={ref}
                                options={DpTrackerStatus}
                                components={animatedComponents}
                                value={DpTrackerStatus.find(option => option.value === value)}
                                {...field}
                                isSearchable
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
                <div className="col-md-4 make-center-ed pl-0">
                  <ButtonToolbar>
                    <ButtonGroup className="" dir="ltr">
                      <Button onClick={handleFormClear}>
                        <span className="lnr" /> Clear
                      </Button>
                      {/* <Button className="ml-2" color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                    <ButtonGroup className="" dir="ltr">
                      <Button color="success" type="submit">
                        <span className="lnr" /> Search
                      </Button>
                      {/* <Button color="success" type="submit"><span className="lnr" /> Search</Button> */}
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </form>
            </div>
            <ReactTableBase
              key={withSearchEngine ? "modified" : "common"}
              columns={reactTableData.tableHeaderData}
              data={rows}
              tableConfig={tableConfig}
            />
          </div>

          <Modal
            isOpen={modal}
            toggle={toggle}
            modalClassName="ltr-support"
            size="lg"
            fullscreen="lg"
            className="modal-dialog modal-dialog--primary modal-dialog--header hs-modal"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              <h4 className="text-modal--primary modal__title">
                Detail Report
              </h4>
            </div>
            <div
              className="modal__body"
              style={{ paddingRight: 40, paddingLeft: 40 }}
            >
              <div className="text-center">
                <Badge color="primary" className="hs-badge">
                  Vendor 1
                </Badge>
                <span>ABCD12345</span>
              </div>
              <CSVLink data={dataModal} filename="detail-report.csv">
                <Badge color="primary" className="hs-badge__btn">
                  Download CSV
                </Badge>
              </CSVLink>
              <Col md={12} lg={12} className="p-0">
                <Card className="p-0">
                  <CardBody className="report-table">
                    <ReactTableBase
                      key={withSearchEngine ? "modified" : "common"}
                      columns={reactTableDataModal.tableHeaderData}
                      data={reactTableDataModal.tableRowsData}
                      tableConfig={tableConfig}
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
            <ButtonToolbar className="modal__footer">
              <Button className="modal_cancel" onClick={toggle}>
                Close
              </Button>
            </ButtonToolbar>
          </Modal>
        </CardBody>
      </Card>
    </Col>
  );
};

ReportsTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default ReportsTable;
