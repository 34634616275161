import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Col, Container, Row, Card, CardBody, Button,
} from 'reactstrap';
import TreeList from './TreeList/TreeListData';
import SheetMatTable from './SheetTable/SheetMatTable';
import { toSheetDataSet } from '../../redux/custom/actions/sheetTableDataActions';

// const treeData = [
//     {
//         label: 'Workspaces',
//         icon: 'folder',
//         title: 'Documents Folder',
//     children: [
//       {
//         label: 'Workspace 1',
//         icon: 'folder',
//         title: 'Documents Folder',
//         children: [
//           {
//             label: 'Document-0-1.doc',
//             icon: 'file',
//             title: 'Documents Folder',
//           },
//           {
//             label: 'Document-0-2.doc',
//             icon: 'file',
//             title: 'Documents Folder',
//           },
//           {
//             label: 'Document-0-3.doc',
//             icon: 'file',
//             title: 'Documents Folder',
//           },
//           {
//             label: 'Document-0-4.doc',
//             icon: 'file',
//             title: 'Documents Folder',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: 'Sheets',
//     icon: 'desktop',
//     title: 'Desktop Folder',
//     children: [
//       {
//         label: 'document1.doc',
//         icon: 'file',
//         title: 'Documents Folder',
//       },
//       {
//         label: 'documennt-2.doc',
//         icon: 'file',
//         title: 'Documents Folder',
//       },
//     ],
//   },
//   {
//     label: 'Dashboard',
//     icon: 'user-friends',
//     title: 'Downloads Folder',
//     children: [],
//   },
// ];


// const SheetManagerIndex = (props) => {
// export default function SheetManagerIndex(props) {
function SheetManagerIndex(props) {
  console.log(props);
  const [isLoading, setLoading] = useState(true);
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  // const data = [];
const wData = [];
const [workspaceData, setWorkspaceData] = useState(wData);
// const workspaceData = [];
const [SheetData, setSheetData] = useState([]);
const [treeData, setTreeData] = useState([
  {
  label: 'Workspaces',
  icon: 'user-friends',
  title: 'Workspaces',
  children: workspaceData,
  },
  {
  label: 'Sheets',
  icon: 'file',
  title: 'Sheets',
  children: [],
  },
  {
  label: 'Dashboard',
  icon: 'tachometer-alt',
  title: 'Dashboard',
  children: [],
}]);
async function getTreeData() {
  // console.log(treeData[1].children[0]);
//   setTreeData([
//     {
//     label: 'Workspaces',
//     icon: 'folder',
//     title: 'Workspaces',
//   children: [],
// },
// ]);
  // console.log(treeData);
  // setTreeData([{
  //   label: 'document1.doc',
  //   icon: 'file',
  //   title: 'Documents Folder',
  // }]);
  // console.log(treeData[0].label);
  // setTreeData(treeData.label = 'test');
  // setTreeData(treeData[0].label = test);
  // console.log(treeData);
  // console.log(treeData[1].children[0].label);
  // setTreeData(treeData[1].children[0].label = 'test');
  await axios.get(`${getUserData.ses_URL}smart_sheet/work_space/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/1/1556604563810180`)
  .then((res) => {
    console.log(res.data.data.data);
    // eslint-disable-next-line array-callback-return
    Object.keys(res.data.data.data).map((keyName) => {
      wData.push(
        {
          id: `${res.data.data.data[keyName].id}`,
          label: `${res.data.data.data[keyName].name}`,
          icon: 'user-friends',
          type: 1,
          title: 'Documents Folder',
          permalink: `${res.data.data.data[keyName].permalink}`,
          accesslevel: `${res.data.data.data[keyName].accessLevel}`,
          children: [],
        },
      );
    });
    // console.log(wData);
    setWorkspaceData(wData);
    console.log(treeData);
    
    // setTimeout(() => {
      // console.log('time 3');
      setLoading(false);
    // }, 3000);
  });
}
useEffect(() => {
  getTreeData();
  // setWorkspaceData(wData);
  // console.log(workspaceData);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []); 
// getTreeData();

  // const [data, setData] = useState(structure);

  // const handleClick = (node) => {
  //   console.log(node);
  // };
  // const handleUpdate = (state) => {
  //   localStorage.setItem(
  //     'tree',
  //     JSON.stringify(state, (key, value) => {
  //       if (key === 'parentNode' || key === 'id') {
  //         return null;
  //       }
  //       return value;
  //     }),
  //   );
  // };

  // useLayoutEffect(() => {
  //   try {
  //     const savedStructure = JSON.parse(localStorage.getItem('tree'));
  //     if (savedStructure) {
  //       setData(savedStructure);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="custom-header_links mb-3">
            <h3 className="page-title d-inline">Work Sheet Manager</h3>
            <Link to="/master/sheetaccount">
              <Button className="icon" color="success"><p>Sheet Account</p></Button>
            </Link>
            <div className="d-inline">
              <strong>Sheet: </strong>
              <span>Sheet Account 1</span>
            </div>
          </div>
          <Card>
            <CardBody className="file-manager p-0">
              <div>
                {/* <Col md={12}>
                    <div>Header</div>
                  </Col> */}
                <div className="react-table__wrapper">
                  <Col md={3} className="tree-custom">
                    <div className="d-tree">
                      {/* <Tree data={data} onUpdate={handleUpdate} onNodeClick={handleClick} /> */}
                      {/* {isLoading ? <div className="App">Loading...</div> : <TreeList data={treeData} />} */}
                      <TreeList data={treeData} />
                    </div>
                  </Col>
                  <Col md={9}>
                    <SheetMatTable />
                  </Col>
                </div>
                {/* <Col md={12}>
                    <div>Footer</div>
                  </Col> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* <FileSection /> */}
      </Row>
    </Container>
  );
}

const mapStateToProps = state => ({
  // rtl: state.rtl,
  // globalData: state.globalData,
  sheetTableData: state.sheetTableData,
});
const mapDispatchToProps = dispatch => ({
  // toGlobalDataSetAction: (url, token) => dispatch(toGlobalDataSet(url, token)),
  toSheetTableDataSetAction: data => dispatch(toSheetDataSet(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SheetManagerIndex);

// const mapStateToProps = state => ({
//   // rtl: state.rtl,
//   // globalData: state.globalData,
//   sheetTableData: state.sheetTableData,
// });
// const mapDispatchToProps = dispatch => ({
//   // toGlobalDataSetAction: (url, token) => dispatch(toGlobalDataSet(url, token)),
//   toSheetTableDataSetAction: data => dispatch(toSheetDataSet(data)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(SheetManagerIndex);
