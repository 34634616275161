import React from 'react';
import {
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import SourceBranchSyncIcon from 'mdi-react/SourceBranchSyncIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';

const MappingButton = ({
  // eslint-disable-next-line react/prop-types
  btnID, toMapLink, toSecLink,
}) => (
  <ButtonToolbar>
    <Link to={toMapLink}>
      <Button id={`btn-map-${btnID}`} color="warning btn-icon__small">
        <SourceBranchSyncIcon style={{ width: 18, height: 18 }} />
      </Button>
      {/* <UncontrolledTooltip placement="top" target={`btn-map-${btnID}`}>
        Mapping
      </UncontrolledTooltip> */}
    </Link>
    <Link to={toSecLink}>
      <Button id={`btn-sec-${btnID}`} color="primary btn-icon__small">
        <TimetableIcon style={{ width: 18, height: 18 }} />
      </Button>
      {/* <UncontrolledTooltip placement="top" target={`btn-sec-${btnID}`}>
        Scheduling
      </UncontrolledTooltip> */}
    </Link>   
  </ButtonToolbar>
);

export default MappingButton;
