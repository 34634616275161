import axios from 'axios';

const DpVendor = () => {
    const data = [{
        value: 'All',
        label: 'All',
         }];
    const getUserData = JSON.parse(localStorage.getItem('hismartData'));
    // console.log(getUserData);
    async function dropDownData() {
        await axios.get(`${getUserData.ses_URL}vendor_directory/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
        .then((res) => {
            // eslint-disable-next-line array-callback-return
            Object.keys(res.data.data).map((keyName) => {
                if (res.data.data[keyName].sp_vendor_dir_status === 1) {
                // console.log(res.data.data);
               data.push({
                value: `${res.data.data[keyName].sp_vendor_dir_code}`,
                label: `${res.data.data[keyName].sp_vendor_dir_name}`,
                 }); 
                }
               }); 
        });
    }
    if (getUserData !== null) {
        dropDownData();
    }
    return data;
};
export default DpVendor();
