import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Commerce from './Commerce';
import Finance from './Finance';
import OnLineMarketingDashboard from '../../../Dashboards/OnLineMarketing/index';
import AppDashboard from '../../../Dashboards/App/index';
import BookingDashboard from '../../../Dashboards/Booking/index';
import FitnessDashboard from '../../../Dashboards/Fitness/index';
import UI from './UI';
import Mail from '../../../Mail/index';
import Chat from '../../../Chat/index';
import Todo from '../../../Todo/index';
import Forms from './Forms';
import Tables from './Tables';
import Charts from './Charts';
import Maps from './Maps';
import Account from './Account';
import ECommerce from './ECommerce';
import DefaultPages from './DefaultPages';
import Documentation from './Documentation';
import Master from './Master';
import SystemParameter from './SystemParameter';
import FileManager from './FileManager';
import SheetManager from './SheetManager';
import Notification from '../../../Notification/index';
import ProtectedRouter from '../../../../config/protected';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/e_commerce_dashboard" component={Commerce} />
      {/* <Route path="/dashboard" component={OnLineMarketingDashboard} /> */}
      <ProtectedRouter path="/dashboard" component={OnLineMarketingDashboard} />
      <Route exact path="/app_dashboard" component={AppDashboard} />
      <Route path="/booking_dashboard" component={BookingDashboard} />
      <Route path="/finance_dashboard" component={Finance} />
      <Route path="/fitness_dashboard" component={FitnessDashboard} />
      <Route path="/ui" component={UI} />
      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/account" component={Account} />
      <Route path="/e-commerce" component={ECommerce} />
      <Route path="/default_pages" component={DefaultPages} />
      <Route path="/documentation" component={Documentation} />
      <Route path="/master" component={Master} />
      <Route path="/systemparameter" component={SystemParameter} />
      <Route path="/filemanager" component={FileManager} />
      <Route path="/sheetmanager" component={SheetManager} />
      <Route path="/notification" component={Notification} />
    </div>
  </div>
);
