import {
    TEST_DATA_SET,
    TEST_DATA_GET,
  } from '../actions/testActions';
  
  const initialState = {
        dataOne: 'test-data',
  };
  
  const testReducer = (state = initialState, action) => {
    switch (action.type) {
      case TEST_DATA_SET: {
        return {
          ...state,
          dataOne: action.payload, 
        };
      }
      case TEST_DATA_GET:
        return state;
      default:
        return state;
    }
  };
  
  export default testReducer;
