/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import DpSysSheet from '../../../../Components/Dropdowns/DpSysSheet';

const SheetAccountForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const [isLoading, setLoading] = useState(true);
  const [inputActive, setinputActive] = useState(false);
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [defaultSelect, setDefaultSelect] = useState({});
  const [formData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  // const sheetAccountType = [
  //   { value: '1', label: 'Sheet 1' },
  //   { value: '2', label: 'Sheet 2' },
  //   { value: '3', label: 'Sheet 3' },
  // ];
  const sheetAccountType = DpSysSheet;

  // const onSubmit = data => console.log(data);
  const [editedData, setEditedData] = useState([{
    gn_sheet_acc_name: '',
    gn_sheet_acc_code: '',
    gn_sheet_acc_type: '',
    gn_sheet_acc_keys: '',
    gn_sheet_acc_remarks: '',
    gn_sheet_acc_status: '',
    gn_sheet_acc_modified_by: '',
    gn_sheet_acc_modified_on: '',
    gn_sheet_acc_created_at: '',
}]);
async function onSubmit(data) {
  console.log(data);
  setEditedData( 
    editedData[0].gn_sheet_acc_name = data.gn_sheet_acc_name,
    editedData[0].gn_sheet_acc_code = data.gn_sheet_acc_code,
    editedData[0].gn_sheet_acc_type = `${data.sheet_acc_type.value}`,
    editedData[0].gn_sheet_acc_keys = data.gn_sheet_acc_keys,
    editedData[0].gn_sheet_acc_remarks = data.gn_sheet_acc_remarks,
  );
  console.log(inputActive);
  if (inputActive === true) {
    setEditedData(editedData[0].gn_sheet_acc_status = '1');
  } else {
    setEditedData(editedData[0].gn_sheet_acc_status = '0');
  }
  setEditedData(editedData[0].gn_sheet_acc_modified_by = '1');
  const currentDate = new Date();
  // eslint-disable-next-line no-undef
  const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
  setEditedData(editedData[0].gn_sheet_acc_modified_on = currentDateFormate);

  // console.log(data.gn_sheet_acc_created_at);
  if (data.gn_sheet_acc_created_at === '') {
    // console.log('data is there');
    setEditedData(editedData[0].gn_sheet_acc_created_at = currentDateFormate);
  } else {
    const crDate = new Date(data.gn_sheet_acc_created_at);
    setEditedData(editedData[0].gn_sheet_acc_created_at = `${crDate.getFullYear()}-${crDate.getMonth() + 1}-${crDate.getDate()}`);
  }
  // editedData.push(data); 
  console.log(editedData);
  if (params.action === 'create') {
    // console.log('create');
    await axios.post(`${getUserData.ses_URL}sheet/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
  // axios.post('http://127.0.0.1:8000/sheet/1/30888374364/1/0', editedData)
  .then((res) => {
    console.log(res.data);
    if (res.data.status_code === 200) {
      toast.success('New Sheet added successfully');
      document.getElementById('backButton_master_sheet').click();
    } else {
      toast.error('Try Again!');
    }
  });
  } else if (params.action === 'edit') {
    // console.log('ed');
    await axios.put(`${getUserData.ses_URL}sheet/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
    // axios.put(`http://127.0.0.1:8000/sheet/1/30888374364/2/${params.id}`, editedData)
    .then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success('Sheet updated successfully');
        document.getElementById('backButton_master_sheet').click();
      } else {
        toast.error('Try Again!');
      }
      // setTimeout(() => setTestData(res.data.data[0]), 1000);
      // setTestData(res.data.data[0]);
    });
  }
}


  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setTestData({
        gn_sheet_acc_name: '',
        gn_sheet_acc_code: '',
        gn_sheet_acc_type: '',
        gn_sheet_acc_keys: '',
        gn_sheet_acc_remarks: '',
        gn_sheet_acc_modified_by: '',
        gn_sheet_acc_modified_on: '',
        gn_sheet_acc_created_at: '',
      });
      setLoading(false);
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      async function viewData() {
        await axios.get(`${getUserData.ses_URL}sheet/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/sheet/1/30888374364/2/${params.id}`)
          .then((res) => {
            console.log(res.data.data.detail[0]);

            // dropdown list check
            // console.log(sheetAccountType);
            const dpIndex = sheetAccountType.findIndex(item => item.value === `${res.data.data.detail[0].gn_sheet_acc_type}`);
            console.log(dpIndex);
            setDefaultSelect({
              type: dpIndex,
            });

            setTestData(
              {
                gn_sheet_acc_name: res.data.data.detail[0].gn_sheet_acc_name,
                gn_sheet_acc_code: res.data.data.detail[0].gn_sheet_acc_code,
                gn_sheet_acc_type: res.data.data.detail[0].gn_sheet_acc_type,
                gn_sheet_acc_keys: res.data.data.detail[0].gn_sheet_acc_keys,
                gn_sheet_acc_remarks: res.data.data.detail[0].gn_sheet_acc_remarks,
                gn_sheet_acc_modified_by: res.data.data.detail[0].gn_sheet_acc_modified_by,
                gn_sheet_acc_modified_on: res.data.data.detail[0].gn_sheet_acc_modified_on,
                gn_sheet_acc_created_at: res.data.data.detail[0].gn_sheet_acc_created_at,
              },
              );
            if (res.data.data.detail[0].gn_sheet_acc_status === 1) {
              setinputActive(true);
            } else {
              setinputActive(false);
            }
            setLoading(false);
          });
      }
  viewData();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/master/sheetaccount">
              <Button className="icon" id="backButton_master_sheet" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Sheet Account {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_sheet_acc_name">Sheet Account Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_sheet_acc_name"
                      type="text"
                      placeholder="Sheet Name"
                      id="gn_sheet_acc_name"
                      defaultValue={formData.gn_sheet_acc_name ? formData.gn_sheet_acc_name : ''}
                      {...register('gn_sheet_acc_name', {
                        required: 'Sheet Account name is required',
                      })}
                    />
                    {errors?.gn_sheet_acc_name && <span className="form__form-group-error">{errors.gn_sheet_acc_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_sheet_acc_code">Sheet Account Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_sheet_acc_code"
                      type="text"
                      placeholder="Code"
                      id="gn_sheet_acc_code"
                      defaultValue={formData.gn_sheet_acc_code ? formData.gn_sheet_acc_code : ''}
                      {...register('gn_sheet_acc_code', {
                        required: 'Sheet Account Code is required',
                      })}
                    />
                    {errors?.gn_sheet_acc_code && <span className="form__form-group-error">{errors.gn_sheet_acc_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="sheet_acc_type">Sheet Account Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="sheet_acc_type"
                      id="sheet_acc_type"
                      control={control}
                      defaultValue={sheetAccountType[defaultSelect.type]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={sheetAccountType}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Select option is required',
                      }}
                    />
                    {errors.sheet_acc_type && <span className="form__form-group-error">{errors.sheet_acc_type.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_sheet_acc_keys">Sheet Account Key *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_sheet_acc_keys"
                      type="text"
                      placeholder="Name"
                      id="gn_sheet_acc_keys"
                      defaultValue={formData.gn_sheet_acc_keys ? formData.gn_sheet_acc_keys : ''}
                      {...register('gn_sheet_acc_keys', {
                        required: 'Sheet Account Key is required',
                      })}
                    />
                    {errors?.gn_sheet_acc_keys && <span className="form__form-group-error">{errors.gn_sheet_acc_keys.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_sheet_acc_keys">Sheet Account Key *</label>
                <div className="form__form-group-field">
                  <textarea
                    name="gn_sheet_acc_keys"
                    type="text"
                    placeholder="Enter the text..."
                    id="gn_sheet_acc_keys"
                    row="3"
                    defaultValue={formData.gn_sheet_acc_keys ? formData.gn_sheet_acc_keys : ''}
                    {...register('gn_sheet_acc_keys', {
                        required: 'Sheet Account Key is required',
                      })}
                  />
                  {errors?.gn_sheet_acc_keys && <span className="form__form-group-error">{errors.gn_sheet_acc_keys.message}</span>}                 
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_sheet_acc_remarks">Sheet Account Remarks</label>
                <div className="form__form-group-field">
                  <textarea
                    name="gn_sheet_acc_remarks"
                    type="text"
                    placeholder="Enter the text..."
                    id="gn_sheet_acc_remarks"
                    {...register('gn_sheet_acc_remarks')}
                    row="3"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox 
                          name="gn_sheet_acc_status" 
                          control={control}
                          value={inputActive}  
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}                   
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active" 
                      {...register('gn_sheet_acc_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/master/sheetaccount">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SheetAccountForm;
