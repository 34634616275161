/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import PlusBoldIcon from 'mdi-react/PlusBoldIcon';
import DpSheet from '../../../../Components/Dropdowns/DpSheet';
import DpFTP from '../../../../Components/Dropdowns/DpFTP';

const MappingForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const [isLoading, setLoading] = useState(true);
  const [inputActive, setinputActive] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState({});
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [formData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  // const [projectID, setProjectID] = useState();
  // const sheetAccountType = [
  //   { value: '1', label: 'Sheet 1' },
  //   { value: '2', label: 'Sheet 2' },
  //   { value: '3', label: 'Sheet 3' },
  // ];
  // const ftpAccountType = [
  //   { value: '1', label: 'Type1' },
  //   { value: '2', label: 'Type2' },
  //   { value: '3', label: 'Type3' },
  // ];
  const sheetAccountType = DpSheet;
  const ftpAccountType = DpFTP;
  const [detailFields, setDetailFields] = useState([
    { sheet_name: '', sheet_id: '' },
  ]);
  // const onSubmit = data => console.log(data);
  const [editedData, setEditedData] = useState([{
    map_pro_pro_id: `${params.proid}`,
    map_pro_ftp_id: '',
    map_pro_sheet_id: '',
    map_pro_ftp_root_path: '',
    map_pro_ftp_in_path: '',
    map_pro_ftp_out_path: '',
    map_pro_ftp_sample_file_path: '',
    map_pro_mapping_template: [],
    map_pro_status: '',
    map_pro_modified_by: '',
    map_pro_modified_on: '',
    map_pro_files_detail: detailFields,
}]);
// const [detailFields, setDetailFields] = useState([
//   { sheet_name: 'hi', sheet_id: 'test' },
// ]);
async function onSubmit(data) {
    console.log(data);
    console.log(detailFields);
    // console.log(JSON.stringify(detailFields));
    setEditedData( 
      // editedData[0].map_pro_pro_id = data.map_pro_pro_id,
      editedData[0].map_pro_pro_id = `${params.proid}`,
      editedData[0].map_pro_ftp_id = `${data.pro_ftp_id.value}`,
      editedData[0].map_pro_sheet_id = `${data.pro_sheet_id.value}`,
      editedData[0].map_pro_ftp_root_path = data.map_pro_ftp_root_path,
      editedData[0].map_pro_ftp_in_path = data.map_pro_ftp_in_path,
      editedData[0].map_pro_ftp_out_path = data.map_pro_ftp_out_path,
      editedData[0].map_pro_ftp_sample_file_path = data.map_pro_ftp_sample_file_path,
      editedData[0].map_pro_files_detail = JSON.stringify(detailFields),
    );
    console.log(inputActive);
    if (inputActive === true) {
      setEditedData(editedData[0].map_pro_status = '1');
    } else {
      setEditedData(editedData[0].map_pro_status = '0');
    }
    setEditedData(editedData[0].map_pro_modified_by = '1');
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    setEditedData(editedData[0].map_pro_modified_on = currentDateFormate);
    // editedData.push(data); 
    console.log(editedData);
    if (params.action === 'create') {
      await axios.post(`${getUserData.ses_URL}project/ftp_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
    // axios.post('http://127.0.0.1:8000/project/ftp_map/1/30888374364/1/0', editedData)
    .then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success('New FTP Map added successfully');
        document.getElementById('backButton_master_project_map').click();
      } else {
        toast.error('Try Again!');
      }
    });
    } else if (params.action === 'edit') {
      await axios.put(`${getUserData.ses_URL}project/ftp_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
      // axios.put(`http://127.0.0.1:8000/project/ftp_map/1/30888374364/2/${params.id}`, editedData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success('FTP Map updated successfully');
          document.getElementById('backButton_master_project_map').click();
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    }
  }

  // if (params.action !== 'create') {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     setTestData({
  //       map_pro_pro_id: 'New A',
  //       map_pro_ftp_id: 'FTP Acc 1',
  //       map_pro_ftp_root_path: 'http://localhost:3000/hismart/master/project',
  //       map_pro_ftp_in_path: 'http://localhost:3000/hismart/master/project',
  //       map_pro_ftp_out_path: 'http://localhost:3000/hismart/master/project',
  //       map_pro_ftp_sample_file_path: 'http://localhost:3000/hismart/master/project',
  //       map_pro_sheet_id: 'Sheet Account 1',
  //       map_pro_mapping_template: 'Template 1',
  //       map_pro_status: true,
  //     });
  //   }, [params.id]);
  // }

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setTestData({
        map_pro_pro_id: '',
        map_pro_ftp_id: '',
        map_pro_sheet_id: '',
        map_pro_ftp_root_path: '',
        map_pro_ftp_in_path: '',
        map_pro_ftp_out_path: '',
        map_pro_ftp_sample_file_path: '',
        map_pro_modified_by: '',
        map_pro_modified_on: '',
        map_pro_files_detail: detailFields,
      });
      setLoading(false);
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      async function viewData() {
        await axios.get(`${getUserData.ses_URL}project/ftp_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/project/ftp_map/1/30888374364/2/${params.id}`)
          .then((res) => {
            setDefaultSelect({
              
            });

            // dropdown list check
            // console.log(ftpAccountType);
            const dpIndexFTP = ftpAccountType.findIndex(item => item.value === `${res.data.data.detail[0].map_pro_ftp_id}`);
            const dpIndexSheet = sheetAccountType.findIndex(item => item.value === `${res.data.data.detail[0].map_pro_sheet_id}`);
            console.log(dpIndexFTP);
            console.log(dpIndexSheet);
            setDefaultSelect({
              ftp: dpIndexFTP,
              sheet: dpIndexSheet,
            });

            // console.log(res.data.data);
            console.log(res.data.data.detail[0]);
            // console.log(JSON.parse(res.data.data.detail[0].map_pro_files_detail));
            setDetailFields(JSON.parse(res.data.data.detail[0].map_pro_files_detail));
            
            setTestData(
              {
                map_pro_pro_id: res.data.data.detail[0].map_pro_pro_id,
                map_pro_ftp_id: res.data.data.detail[0].map_pro_ftp_id,
                map_pro_sheet_id: res.data.data.detail[0].map_pro_sheet_id,
                map_pro_ftp_root_path: res.data.data.detail[0].map_pro_ftp_root_path,
                map_pro_ftp_in_path: res.data.data.detail[0].map_pro_ftp_in_path,
                map_pro_ftp_out_path: res.data.data.detail[0].map_pro_ftp_out_path,
                map_pro_ftp_sample_file_path: res.data.data.detail[0].map_pro_ftp_sample_file_path,
                map_pro_modified_by: res.data.data.detail[0].map_pro_modified_by,
                map_pro_modified_on: res.data.data.detail[0].map_pro_modified_on,
                map_pro_files_detail: detailFields,
              },
              );
            if (res.data.data.detail[0].map_pro_status === 1) {
              setinputActive(true);
            } else {
              setinputActive(false);
            }
            setLoading(false);
            // console.log(defaultSelect.ftp);
          });
      }
  viewData();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  const addFilesDetails = () => {
    // console.log('add');
    setDetailFields([...detailFields, { sheet_name: '', sheet_id: '' }]);
  };

  const deleteFilesDetails = (index) => {
    // console.log('delete');
    const values = [...detailFields];
    values.splice(index, 1);
    setDetailFields(values);
  };

  const handleChangeInput = (index, event) => {
    // console.log(index, event.target.name);
    const values = [...detailFields];
    values[index][event.target.name] = event.target.value;
    setDetailFields(values);
    // setEditedData( 
    //   editedData[0].map_pro_files_detail = detailFields,
    // );
    };

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to={`/master/mapping/${params.proid}`}>
              <Button className="icon" id="backButton_master_project_map" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Mapping Project {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-3">
              <div className="form__form-group disabled-form-item">
                <label className="form__form-group-label" htmlFor="map_pro_pro_id">Project Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="map_pro_pro_id"
                      type="text"
                      placeholder="Project Name"
                      id="map_pro_pro_id"
                      // disabled={params.action === 'edit' ? '' : false}
                      defaultValue={formData.map_pro_pro_id ? formData.map_pro_pro_id : ''}
                      {...register('map_pro_pro_id')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="pro_ftp_id">FTP Account *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="pro_ftp_id"
                      id="pro_ftp_id"
                      control={control}
                      defaultValue={ftpAccountType[defaultSelect.ftp]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={ftpAccountType}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Select option is required',
                      }}
                    />
                    {errors.pro_ftp_id && <span className="form__form-group-error">{errors.pro_ftp_id.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="pro_sheet_id">Sheet Account *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="pro_sheet_id"
                      id="pro_sheet_id"
                      control={control}
                      defaultValue={sheetAccountType[defaultSelect.sheet]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={sheetAccountType}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Select option is required',
                      }}
                    />
                    {errors.pro_sheet_id && <span className="form__form-group-error">{errors.pro_sheet_id.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_pro_ftp_root_path">FTP Root Path</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_pro_ftp_root_path"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_pro_ftp_root_path"
                    {...register('map_pro_ftp_root_path')}
                    row="3"
                    defaultValue={formData.map_pro_ftp_root_path ? formData.map_pro_ftp_root_path : ''}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_pro_ftp_in_path">FTP In Path</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_pro_ftp_in_path"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_pro_ftp_in_path"
                    {...register('map_pro_ftp_in_path')}
                    row="3"
                    defaultValue={formData.map_pro_ftp_in_path ? formData.map_pro_ftp_in_path : ''}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_pro_ftp_out_path">FTP Out Path</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_pro_ftp_out_path"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_pro_ftp_out_path"
                    {...register('map_pro_ftp_out_path')}
                    row="3"
                    defaultValue={formData.map_pro_ftp_out_path ? formData.map_pro_ftp_out_path : ''}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_pro_ftp_sample_file_path">FTP Sample File Path</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_pro_ftp_sample_file_path"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_pro_ftp_sample_file_path"
                    {...register('map_pro_ftp_sample_file_path')}
                    row="3"
                    defaultValue={formData.map_pro_ftp_sample_file_path ? formData.map_pro_ftp_sample_file_path : ''}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="map_pro_mapping_template">Mapping Template</label>
                <div className="form__form-group-field">
                  <textarea
                    name="map_pro_mapping_template"
                    type="text"
                    placeholder="Enter the text..."
                    id="map_pro_mapping_template"
                    {...register('map_pro_mapping_template')}
                    row="3"
                  />
                </div>
              </div>
            </div> */}

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox 
                          name="map_pro_status" 
                          control={control}
                          value={inputActive}  
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}                   
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active" 
                      {...register('map_pro_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="card__title col-sm-12">
              <h5 className="bold-text mt-3">Account Files Details</h5>
            </div>
            <div className="files-details">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <h6 className="bold-text">Sheet Name</h6>
                  </div>
                  <div className="col-md-5">
                    <h6 className="bold-text">Sheed ID.</h6>
                  </div>
                  { params.action !== 'view' 
                        && (
                        <div className="col-md-2 custom-sm-btn custom-sm-btn__round">
                          <Button className="btn-sm mb-2" color="success" onClick={() => addFilesDetails()}><PlusBoldIcon /></Button>
                        </div>
                        )}
                </div>
              </div>
              {
                detailFields.map((detailField, index) => (
                  <div className="col-md-12 files-details__fields" Key={index}>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap">
                              <input
                                name="sheet_name"
                                type="text"
                                placeholder="Sheet name"
                                value={detailField.sheet_name}
                                onChange={event => handleChangeInput(index, event)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap">
                              <input
                                name="sheet_id"
                                type="text"
                                placeholder="Sheet id"
                                value={detailField.sheet_id}
                                onChange={event => handleChangeInput(index, event)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      { params.action !== 'view' 
                        && (
                        <div className="col-md-2 d-flex custom-sm-btn custom-sm-btn__round">
                          {/* <Button className="btn-sm" color="primary"><span className="lnr lnr-pencil" /></Button> */}
                          {/* { detailFields.length !== 1
                          &&  */}
                          <Button className="btn-sm" color="danger" onClick={() => deleteFilesDetails(index)}><span className="lnr lnr-trash" /></Button>
                          {/* } */}
                        </div>
                        )}
                    </div>
                  </div>
                ))
              }
              
            </div>

            <div className="col-md-12 mt-5">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to={`/master/mapping/${params.proid}`}>
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default MappingForm;
