import axios from 'axios';

const DpUserState = () => {
    const data = [];
    const getUserData = JSON.parse(localStorage.getItem('hismartData'));
    async function dropDownData() {
        await axios.get(`${getUserData.ses_URL}user_state/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
        // axios.get('http://127.0.0.1:8000/user_state/1/30888374364/1/0')
        .then((res) => {
            // eslint-disable-next-line array-callback-return
            Object.keys(res.data.data).map((keyName) => {
                // console.log(res.data.data);
               data.push({
                value: `${res.data.data[keyName].usr_sta_id}`,
                label: `${res.data.data[keyName].usr_sta_name}`,
                 }); 
               }); 
        });
    }
    if (getUserData !== null) {
        dropDownData();
    }
    return data;
};
export default DpUserState();
