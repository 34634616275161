import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {
  CgFileAdd, CgFolderAdd, CgCopy, CgTrashEmpty
} from 'react-icons/cg';
import { BiEditAlt } from 'react-icons/bi';
// import MatTableFilterButton from './MatTableFilterButton';

const MatTableToolbar = ({ numSelected, handleDeleteSelected, onRequestSort }) => (
  <div className="material-table__toolbar-wrap">
    <Toolbar className="material-table__toolbar">
      <div>
        {numSelected > 0 && (
          <>
            {/* <IconButton
              className="material-table__toolbar-button"
            >
              <CgSoftwareUpload />
            </IconButton>

            <IconButton
              className="material-table__toolbar-button"
            >
              <CgSoftwareDownload />
            </IconButton> */}
          </>
        )}
      </div>
      <div>
        {numSelected > 0 ? (
          <>
            <h5 className="material-table__toolbar-selected d-inline-block">{numSelected} <span>selected</span></h5>
            <IconButton
              className="material-table__toolbar-button"
            >
              <CgFolderAdd />
            </IconButton>

            <IconButton
              className="material-table__toolbar-button"
            >
              <CgFileAdd />
            </IconButton>

            <IconButton
              className="material-table__toolbar-button"
            >
              <BiEditAlt />
            </IconButton>

            <IconButton
              className="material-table__toolbar-button"
            >
              <CgCopy />
            </IconButton>

            <IconButton
              onClick={handleDeleteSelected}
              className="material-table__toolbar-button"
            >
              <CgTrashEmpty />
            </IconButton>

          </>
        ) : (
          // <MatTableFilterButton onRequestSort={onRequestSort} />
          <div />
        )}
      </div>
    </Toolbar>
  </div>
);

MatTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default MatTableToolbar;
