/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import DpLanguage from '../../../../Components/Dropdowns/DpLanguage';
import DpTimezone from '../../../../Components/Dropdowns/DpTimezone';
import DpUserType from '../../../../Components/Dropdowns/DpUserType';
import DpUserState from '../../../../Components/Dropdowns/DpUserState';
import DpFTP from '../../../../Components/Dropdowns/DpFTP';
import DpSheet from '../../../../Components/Dropdowns/DpSheet';
import DpProject from '../../../../Components/Dropdowns/DpProject';
import DpSection from '../../../../Components/Dropdowns/DpSection';

const UserForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  // console.log(BasicData[0].url);
  const [isLoading, setLoading] = useState(true);
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [formData, setTestData] = useState({});
  const [defaultSelect, setDefaultSelect] = useState({});
  const [defaultSelectFTP, setDefaultSelectFTP] = useState({});
  const [inputActive, setinputActive] = useState(false);
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });

  const onSubmit = data => console.log(data);
  // const languageDropdown = [
  const languageDropdown = DpLanguage;
  // console.log(DpLanguage);
  const timeZoneDropdown = DpTimezone;
  // console.log(DpTimezone);
  const userStateDropdown = DpUserState;
  // console.log(DpUserState);
  const userTypeDropdown = DpUserType;
  // console.log(DpUserType);
  const userFTPDropdown = DpFTP;
  // console.log(DpFTP);
  const userSheetDropdown = DpSheet;
  // console.log(DpSheet);
  const userProjectDropdown = DpProject;
  // console.log(DpProject);
  const userSectionDropdown = DpSection;
  // console.log(DpSection);

  // FTP selected dp
  const formFTPDp = [];
  const [selectedFTPDp, setSelectedFTPDp] = useState(formFTPDp);

  // Sheet selected dp
  const formSheetDp = [];
  const [selectedSheetDp, setSelectedSheetDp] = useState(formSheetDp);

  // Project selected dp
  const formProjectDp = [];
  const [selectedProjectDp, setSelectedProjectDp] = useState(formProjectDp);

  // Section selected dp
  const formSectionDp = [];
  const [selectedSectionDp, setSelectedSectionDp] = useState(formSectionDp);

  const [editedData, setEditedData] = useState([{
    gn_usr_typ: '',
    gn_usr_first_name: '',
    gn_usr_last_name: '',
    gn_usr_personal_mail: '',
    gn_usr_official_mail: '',
    gn_usr_primary_mobile: '',
    gn_usr_secondary_mobile: '',
    gn_usr_username: '',
    gn_usr_password: '',
    gn_usr_profile_img_url: '',
    gn_usr_role_access: {
      ftp_account_access: [],
      sheet_account_access: [],
      project_access: [],
      section_access: [],
    },
    gn_usr_state: '',
    gn_usr_modified_by: '',
    gn_usr_modified_on: '',
    gn_usr_status: '',
    gn_usr_language: '',
    gn_usr_time_zone: '',
    gn_usr_created_at: '',
  }]);

  async function onSubmitData(data) {
    console.log('data', data);
    setEditedData(
      editedData[0].gn_usr_typ = data?.usr_typ?.value,
      editedData[0].gn_usr_first_name = data?.gn_usr_first_name,
      editedData[0].gn_usr_last_name = data?.gn_usr_last_name,
      editedData[0].gn_usr_official_mail = data?.gn_usr_official_mail,
      editedData[0].gn_usr_personal_mail = data?.gn_usr_personal_mail,
      editedData[0].gn_usr_primary_mobile = data?.gn_usr_primary_mobile,
      editedData[0].gn_usr_secondary_mobile = data?.gn_usr_secondary_mobile,
      editedData[0].gn_usr_username = data?.gn_usr_username,
      editedData[0].gn_usr_state = data?.usr_state?.value,
      editedData[0].gn_usr_language = '1',
      editedData[0].gn_usr_time_zone = data?.usr_time_zone?.value,
      editedData[0].gn_usr_password = 'test@123',
    );
    if (inputActive === true) {
      setEditedData(editedData[0].gn_usr_status = '1');
    } else {
      setEditedData(editedData[0].gn_usr_status = '0');
    }
    setEditedData(editedData[0].gn_usr_modified_by = '1');
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    setEditedData(editedData[0].gn_usr_modified_on = currentDateFormate);
    // FTP
    // editedData[0].gn_usr_role_access.ftp_account_access = data.usr_ftp_access.map(vl => parseInt(vl.value));
    editedData[0].gn_usr_role_access.ftp_account_access = [1, 2, 3];
    // sheet
    // editedData[0].gn_usr_role_access.sheet_account_access.push(
    //   data.usr_sheet_access.map(vl => parseInt(vl.value)),
    // );
    // editedData[0].gn_usr_role_access.sheet_account_access = data.usr_sheet_access.map(vl => parseInt(vl.value));
    editedData[0].gn_usr_role_access.sheet_account_access = [1, 2, 3];
    // project
    // editedData[0].gn_usr_role_access.project_access = data.usr_project_access.map(vl => parseInt(vl.value));
    editedData[0].gn_usr_role_access.project_access = [1, 2, 3];
    // section
    // editedData[0].gn_usr_role_access.section_access = data.usr_section_access.map(vl => parseInt(vl.value));
    editedData[0].gn_usr_role_access.section_access = [1, 2, 3];
    if (data.gn_usr_created_at === '') {
      // console.log('data is there');
      setEditedData(editedData[0].gn_usr_created_at = currentDateFormate);
    } else {
      const crDate = new Date(data.gn_usr_created_at);
      setEditedData(editedData[0].gn_usr_created_at = `${crDate.getFullYear()}-${crDate.getMonth() + 1}-${crDate.getDate()}`);
    }

    console.log(editedData);

    //     const test = [{
    //       gn_usr_typ: '1',
    //       gn_usr_first_name: 'user',
    //       gn_usr_last_name: 'test',
    //       gn_usr_personal_mail: 'test@gmail.com',
    //       gn_usr_official_mail: 'test@gmail.com',
    //       gn_usr_primary_mobile: '1234567890',
    //       gn_usr_secondary_mobile: '1234567890',
    //       gn_usr_username: 'test@gmail.com',
    //       gn_usr_password: 'test@123',
    //       gn_usr_profile_img_url: '../',
    //       gn_usr_role_access:
    //        {
    //  ftp_account_access: [1, 2, 3],
    //       sheet_account_access: [1, 2, 3],
    //       project_access: [1, 2, 3],
    //       section_access: [1, 2, 3], 
    // },
    //       gn_usr_state: '1',
    //       gn_usr_modified_by: '1',
    //       gn_usr_modified_on: '2021-10-21',
    //       gn_usr_status: '1',
    //       gn_usr_language: '1',
    //       gn_usr_time_zone: '1',
    //   }];
    if (params.action === 'create') {
      await axios.post(`${getUserData.ses_URL}user/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
        // axios.post('http://127.0.0.1:8000/project/1/30888374364/1/0', editedData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success('New User added successfully');
            document.getElementById('backButton_master_user').click();
          } else {
            toast.error('Try Again!');
          }
        });
    } else if (params.action === 'edit') {
      await axios.put(`${getUserData.ses_URL}user/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
        // axios.put(`http://127.0.0.1:8000/project/1/30888374364/2/${params.id}`, editedData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            toast.success('User updated successfully');
            document.getElementById('backButton_master_user').click();
          } else {
            toast.error('Try Again!');
          }
          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          // setTestData(res.data.data[0]);
        });
    }
  }

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setDefaultSelect({
        lang: '',
        time: '',
        state: '',
        type: '',
      });
      setTestData({
        gn_usr_first_name: '',
        gn_usr_language: '',
        gn_usr_last_name: '',
        gn_usr_personal_mail: '',
        gn_usr_official_mail: '',
        gn_usr_primary_mobile: '',
        gn_usr_secondary_mobile: '',
        gn_usr_username: '',
        gn_usr_profile_img_url: '',
        gn_usr_role_access: '',
        gn_usr_state: '',
        gn_usr_status: '',
        gn_usr_typ: '',
        gn_usr_time_zone: '',
        gn_usr_modified_by: '',
        gn_usr_modified_on: '',
        gn_usr_created_at: '',
      });
      setLoading(false);
    }, [params.id]);
    // setLoading(false);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      axios.get(`${getUserData.ses_URL}user/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/user/1/30888374364/2/${params.id}`)
        .then((res) => {
          console.log(res.data.data);
          const langIndex = languageDropdown.findIndex(item => item.value === `${res.data.data.detail[0].gn_usr_language}`);
          const timeIndex = timeZoneDropdown.findIndex(item => item.value === `${res.data.data.detail[0].gn_usr_time_zone}`);
          const stateIndex = userStateDropdown.findIndex(item => item.value === `${res.data.data.detail[0].gn_usr_state}`);
          const typeIndex = userTypeDropdown.findIndex(item => item.value === `${res.data.data.detail[0].gn_usr_typ}`);
          // console.log(langIndex);
          // console.log(timeIndex);
          // console.log(stateIndex);
          // console.log(typeIndex);
          setDefaultSelect({
            lang: langIndex,
            time: timeIndex,
            state: stateIndex,
            type: typeIndex,
          });
          // console.log(JSON.parse(`${res.data.data.detail[0].gn_usr_role_access}`));
          console.log(userFTPDropdown);

          // console.log(JSON.parse(res.data.data.detail[0].gn_usr_role_access));
          const multiSelectDp = JSON.parse(res.data.data.detail[0].gn_usr_role_access);

          console.log('multiselect DP', multiSelectDp);


          // FTP Section
          multiSelectDp.ftp_account_access.map((item) => {
            formFTPDp.push(userFTPDropdown.filter(v => v.value === item.toString())[0]);
          });
          console.log(selectedFTPDp);

          // sheet section
          multiSelectDp.sheet_account_access.map((item) => {
            formSheetDp.push(userSheetDropdown.filter(v => v.value === item.toString())[0]);
          });

          // project section
          multiSelectDp.project_access.map((item) => {
            formProjectDp.push(userProjectDropdown.filter(v => v.value === item.toString())[0]);
          });

          // section
          multiSelectDp.section_access.map((item) => {
            formSectionDp.push(userSectionDropdown.filter(v => v.value === item.toString())[0]);
          });

          console.log('formSectionDp', formSectionDp);
          console.log('selectedSectionDp', selectedSectionDp);

          console.log('userSectionDropdown DP', userSectionDropdown);

          // multiSelectDp.ftp_account_access.map(ftpList => (
          //   selectedFTPDp.push(
          //     // eslint-disable-next-line no-template-curly-in-string
          //     userFTPDropdown.findIndex(item => item.value === `${ftpList}`),
          //     )
          // ));
          // console.log(selectedFTPDp);
          // const formFTPDP = [selectedFTPDp.forEach(e => `userFTPDropdown[${[e]}]`)];


          // setTimeout(() => setTestData(res.data.data[0]), 1000);
          // setTestData({
          //   gn_usr_first_name: res.data.data.detail[0].gn_usr_first_name,
          //   gn_usr_language: res.data.data.detail[0].gn_usr_language,
          //   gn_usr_last_name: res.data.data.detail[0].gn_usr_last_name,
          //   gn_usr_personal_mail: res.data.data.detail[0].gn_usr_personal_mail,
          //   gn_usr_official_mail: res.data.data.detail[0].gn_usr_official_mail,
          //   gn_usr_primary_mobile: res.data.data.detail[0].gn_usr_primary_mobile,
          //   gn_usr_secondary_mobile: res.data.data.detail[0].gn_usr_secondary_mobile,
          //   gn_usr_username: res.data.data.detail[0].gn_usr_username,
          //   gn_usr_profile_img_url: res.data.data.detail[0].gn_usr_profile_img_url,
          //   gn_usr_role_access: res.data.data.detail[0].gn_usr_role_access,
          //   gn_usr_state: res.data.data.detail[0].gn_usr_state,
          //   gn_usr_status: res.data.data.detail[0].gn_usr_status,
          //   gn_usr_typ: res.data.data.detail[0].gn_usr_typ,
          //   gn_usr_time_zone: res.data.data.detail[0].gn_usr_time_zone,
          //   gn_usr_modified_by: res.data.data.detail[0].gn_usr_modified_by,
          //   gn_usr_modified_on: res.data.data.detail[0].gn_usr_modified_on,
          //   gn_usr_created_at: res.data.data.detail[0].gn_usr_created_at,
          // });
          // setTestData({gn_usr_created_at: res.data.data.detail[0].gn_usr_created_at });
          setTestData(res.data.data.detail[0]);
          if (res.data.data.detail[0].gn_usr_status === 1) {
            setinputActive(true);
          } else {
            setinputActive(false);
          }
          setLoading(false);
        });
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
    // reset(defaultSelect);
    // console.log(defaultSelect);
  }, [formData]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/master/user">
              <Button className="icon" id="backButton_master_user" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">User {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmitData)}>
            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_first_name">First Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_first_name"
                      type="text"
                      placeholder="First Name"
                      id="gn_usr_first_name"
                      defaultValue={formData.gn_usr_first_name ? formData.gn_usr_first_name : ''}
                      {...register('gn_usr_first_name', {
                        required: 'First name is required',
                      })}
                    />
                    {errors?.gn_usr_first_name && <span className="form__form-group-error">{errors.gn_usr_first_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_last_name">Last Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_last_name"
                      type="text"
                      placeholder="Last Name"
                      id="gn_usr_last_name"
                      defaultValue={formData.gn_usr_last_name ? formData.gn_usr_last_name : ''}
                      {...register('gn_usr_last_name', {
                        required: 'Last Name is required',
                      })}
                    />
                    {errors?.gn_usr_last_name && <span className="form__form-group-error">{errors.gn_usr_last_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_username">Username *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_username"
                      type="text"
                      placeholder="Username"
                      id="gn_usr_username"
                      defaultValue={formData.gn_usr_username ? formData.gn_usr_username : ''}
                      {...register('gn_usr_username', {
                        required: 'Username is required',
                      })}
                    />
                    {errors?.gn_usr_username && <span className="form__form-group-error">{errors.gn_usr_username.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_official_mail">Official email *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_official_mail"
                      type="email"
                      placeholder="abcd@123.com"
                      id="gn_usr_official_mail"
                      defaultValue={formData.gn_usr_official_mail ? formData.gn_usr_official_mail : ''}
                      {...register('gn_usr_official_mail', {
                        required: 'Official email is required',
                      })}
                    />
                    {errors?.gn_usr_official_mail && <span className="form__form-group-error">{errors.gn_usr_official_mail.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_personal_mail">Personal email</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_personal_mail"
                      type="email"
                      placeholder="abcd@123.com"
                      id="gn_usr_personal_mail"
                      defaultValue={formData.gn_usr_personal_mail ? formData.gn_usr_personal_mail : ''}
                      {...register('gn_usr_personal_mail')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_primary_mobile">Primary Number *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_primary_mobile"
                      type="number"
                      placeholder="1234567890"
                      id="gn_usr_primary_mobile"
                      defaultValue={formData.gn_usr_primary_mobile ? formData.gn_usr_primary_mobile : ''}
                      {...register('gn_usr_primary_mobile', {
                        required: 'Phone number is required',
                      })}
                    />
                    {errors?.gn_usr_primary_mobile && <span className="form__form-group-error">{errors.gn_usr_primary_mobile.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_usr_secondary_mobile">Secondary Number</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_usr_secondary_mobile"
                      type="number"
                      placeholder="0987654321"
                      id="gn_usr_secondary_mobile"
                      defaultValue={formData.gn_usr_secondary_mobile ? formData.gn_usr_secondary_mobile : ''}
                      {...register('gn_usr_secondary_mobile')}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_language">Language *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_language"
                      id="usr_language"
                      control={control}
                      defaultValue={languageDropdown[defaultSelect.lang]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={languageDropdown}
                          components={animatedComponents}
                        />
                          )}
                      rules={{
                            required: 'Language is required',
                          }}
                    />
                    {errors.usr_language && <span className="form__form-group-error">{errors.usr_language.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_time_zone">Time Zone *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_time_zone"
                      id="usr_time_zone"
                      control={control}
                      defaultValue={timeZoneDropdown[defaultSelect.time]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={timeZoneDropdown}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Time Zone is required',
                      }}
                    />
                    {errors.usr_time_zone && <span className="form__form-group-error">{errors.usr_time_zone.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_state">User State *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_state"
                      id="usr_state"
                      control={control}
                      defaultValue={userStateDropdown[defaultSelect.state]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={userStateDropdown}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'User State is required',
                      }}
                    />
                    {errors.usr_state && <span className="form__form-group-error">{errors.usr_state.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_typ">User Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_typ"
                      id="usr_typ"
                      control={control}
                      defaultValue={userTypeDropdown[defaultSelect.type]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={userTypeDropdown}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'User Type is required',
                      }}
                    />
                    {errors.usr_typ && <span className="form__form-group-error">{errors.usr_typ.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_profile_img_url">User Profile</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="usr_profile_img_url"
                      type="file"
                      id="usr_profile_img_url"
                      defaultValue={formData.usr_profile_img_url ? formData.usr_profile_img_url : ''}
                      {...register('usr_profile_img_url')}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <Col md={12}>
              <h5 className="bold-text mt-3 mb-3">User Role</h5>
            </Col> */}

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_ftp_access">FTP Account Access *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_ftp_access"
                      id="usr_ftp_access"
                      control={control}
                      // eslint-disable-next-line no-sequences
                      // eslint-disable-next-line no-unused-expressions
                      // defaultValue={['userFTPDropdown[1]', 'userFTPDropdown[3]']}
                      defaultValue={selectedFTPDp.map(dp => dp)}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={userFTPDropdown} 
                          components={animatedComponents}
                          closeMenuOnSelect={false}
                          isMulti
                        />
                      )}
                      rules={{
                        required: 'FTP Accounts is required',
                      }}
                    />
                    {errors.usr_ftp_access && <span className="form__form-group-error">{errors.usr_ftp_access.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_sheet_access">Sheet Account Access *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_sheet_access"
                      id="usr_sheet_access"
                      control={control}
                      defaultValue={selectedSheetDp.map(dp => dp)}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={userSheetDropdown}
                          components={animatedComponents}
                          closeMenuOnSelect={false}
                          isMulti
                        />
                      )}
                      rules={{
                        required: 'Sheet Accounts is required',
                      }}
                    />
                    {errors.usr_sheet_access && <span className="form__form-group-error">{errors.usr_sheet_access.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_project_access">Project Access *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_project_access"
                      id="usr_project_access"
                      defaultValue={selectedProjectDp.map(dp => dp)}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={userProjectDropdown}
                          components={animatedComponents}
                          closeMenuOnSelect={false}
                          isMulti
                        />
                      )}
                      rules={{
                        required: 'Project is required',
                      }}
                    />
                    {errors.usr_project_access && <span className="form__form-group-error">{errors.usr_project_access.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_section_access">Section Access *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="usr_section_access"
                      id="usr_section_access"
                      defaultValue={selectedSectionDp.map(dp => dp)}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            options={userSectionDropdown}
                            components={animatedComponents}
                            closeMenuOnSelect={false}
                            isMulti
                          />
                        </>
                      )}
                      rules={{
                        required: 'Section is required',
                      }}
                    />
                    {errors.usr_section_access && <span className="form__form-group-error">{errors.usr_section_access.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="usr_role_access">User Role Access *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <textarea
                      name="usr_role_access"
                      type="text"
                      placeholder="Enter the text..."
                      id="usr_role_access"
                      row="3"
                      {...register('usr_role_access', {
                        required: 'User role access is required',
                      })}
                    />
                    {errors?.usr_role_access && <span className="form__form-group-error">{errors.usr_role_access.message}</span>}
                  </div>
                </div>
              </div>
            </div>  */}

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="gn_usr_status"
                          control={control}
                          value={inputActive}
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active"
                      {...register('gn_usr_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/master/user">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserForm;
