/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import ActionGroupButton from "../../../../Components/ActionGroupButton";

const TemplateTableData = () => {
  // const getTemplateData = JSON.parse(localStorage.getItem('hismartData'));
  // const [isLoading, setLoading] = useState(true);
  const [getTemplateData, setGetTemplateData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getTemplateData === null) {
    setGetTemplateData({
      ses_URL: "",
      ses_jwt: "",
    });
  }
  // Table Data
  // const data = [
  //   {
  //     sp_sch_id: '1',
  //     sp_sch_name: 'FollowUp 1',
  //     sp_sch_code: 'F1',
  //     Template_remarks: 'F1',
  //     Template_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  //   {
  //     sp_sch_id: '2',
  //     sp_sch_name: 'FollowUp 2',
  //     sp_sch_code: 'F2',
  //     Template_remarks: 'F2',
  //     Template_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  //   {
  //     sp_sch_id: '3',
  //     sp_sch_name: 'Reminder 1',
  //     sp_sch_code: 'R1',
  //     Template_remarks: 'R1',
  //     Template_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  // ];

  const data = [];

  const link = {
    toCreateLink: "/master/template/view/",
    toHistoryLink: "/master/template/history/",
    toEditLink: "/master/template/edit/",
    // toDeleteLink: '/master/Template',
  };
  const [tableData, setTableData] = useState([]);
  console.log(tableData, "demo");
  async function loadSysTemplate() {
    await axios
      .get(
        `${getTemplateData.ses_URL}schedule/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/1/0`
      )
      // axios.get('http://127.0.0.1:8000/Template/1/30888374364/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName) => {
          // console.log(res.data.data.sp_temp_filepath,'path');
          // console.log(res.data.status);
          if (res.data.data[keyName].sp_sch_status !== 2) {
            // var follow_up_day =moment(`${res.data.data[keyName].sp_sch_follow_up_day}`).format("YYYY-MM-DD")
            // console.log('Invalid date'!==follow_up_day ? follow_up_day :  "Not Available"   );
            data.push({
              sp_sch_id: `${res.data.data[keyName].sp_sch_id}`,
              sp_sch_name: `${res.data.data[keyName].sp_sch_name}`,
              sp_sch_code: `${res.data.data[keyName].sp_sch_code}`,
              // sp_sch_project_typ: `${res.data.data[keyName].sp_sch_project_typ}`,
              sp_sch_temp_typ_name: `${res.data.data[keyName].sp_sch_temp_typ_name}`,
              sp_sch_follow_up_day: `${res.data.data[keyName].sp_sch_follow_up_day}`,
              sp_sch_prior: `${res.data.data[keyName].sp_sch_prior}`,
              sp_sch_business_day: moment(
                `${res.data.data[keyName].sp_sch_business_day}`
              ).format("YYYY-MM-DD"),
              sp_sch_remarks: `${res.data.data[keyName].sp_sch_remarks}`,
              status_name: `${res.data.data[keyName].status_name}`,
              action: (
                <ActionGroupButton
                  btnID={`${res.data.data[keyName].sp_sch_id}`}
                  toSection="sys-Template"
                  toCreateLink={`${link.toCreateLink}${res.data.data[keyName].sp_sch_id}`}
                  toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].sp_sch_id}`}
                  toEditLink={`${link.toEditLink}${res.data.data[keyName].sp_sch_id}`}
                  toDeleteMethod={() =>
                    deleteSysTemplate(`${res.data.data[keyName].sp_sch_id}`)
                  }
                  
                />
              ),
            });
          }
        });
        console.log(data, "data");
      });
    setTableData(data);
  }
  async function deleteSysTemplate(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm("Do you want to delete this Template?");
    if (confirmBox === true) {
      await axios
        .delete(
          `${getTemplateData.ses_URL}schedule/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/2/${deleteID}`
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            // window.location = 'http://localhost:3000/hismart/master/ftpaccounttype';
            toast.success(
              <div>
                <strong className="text-danger">Deleted</strong> successfully
              </div>
            );
            data.length = 0;
            setTableData([]);
            loadSysTemplate();
            // window.location.reload(false);
          } else {
            toast.error("Try Again!");
          }
        });
    }
  }
  useEffect(() => {
    loadSysTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "sp_sch_id",
      },
      {
        Header: "Name",
        accessor: "sp_sch_name",
      },
      {
        Header: "Code",
        accessor: "sp_sch_code",
      },
      // {
      //   Header: 'Template Type',
      //   accessor: 'sp_sch_project_typ',
      // },
      {
        Header: "Template Type",
        accessor: "sp_sch_temp_typ_name",
      },
      //       {
      //         Header: 'Follow Up Day',
      //         accessor: 'sp_sch_follow_up_day',
      //       },
      {
        Header: "Follow Up Day",
        accessor: "sp_sch_follow_up_day",
      },
      //       {
      //         Header: 'Business Day',
      //         accessor: 'sp_sch_business_day',
      //       },
      {
        Header: "Remarks",
        accessor: "sp_sch_remarks",
      },
      {
        Header: "Status",
        accessor: "status_name",
      },
      {
        Header: "Action",
        accessor: "action",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    []
  );
  // console.log(isLoading);
  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  // if (isLoading) {
  //   return <div className="App">Loading...</div>;
  // }
  return reactTableData;
};

export default TemplateTableData;
