/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Checkbox, FormControlLabel, RadioGroup, Radio, FormGroup,
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { emailPatter } from '@/shared/helpers';

const TestForm = ({ pristine, submitting }) => {
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [testData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      testData,
    },
  });
  const selectValues = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const onSubmit = data => console.log(data);

  if (params.action !== 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      axios.get(`https://jsonplaceholder.typicode.com/users/${params.id}`)
        .then((res) => {
          // console.log(res.data);
          setTestData(res.data);
          // console.log(testData);
          // reset(res.data);
          // console.log(testData);
        });
    }, [params.id]);
  }

  useEffect(() => {
    console.log(testData);
  }, [testData]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/systemparameter/test">
              <Button className="icon" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">Test {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_name">Name *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="name"
                      type="text"
                      placeholder="Name"
                      id="t_name"
                      defaultValue={testData.name ? testData.name : ''}
                      {...register('name', {
                        required: 'Name is required',
                      })}
                    />
                    {errors?.name && <span className="form__form-group-error">{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_username">Username *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="username"
                      type="text"
                      placeholder="Username"
                      id="t_username"
                      {...register('username', {
                        required: 'Username is required',
                      })}
                      defaultValue={testData.username ? testData.username : ''}
                    />
                    {errors.username && <span className="form__form-group-error">{errors.username.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_email">Email *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="email"
                      type="text"
                      placeholder="test@123.com"
                      id="t_email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: emailPatter,
                          message: 'invalid email',
                        },
                      })}
                      defaultValue={testData.email ? testData.email : ''}
                    />
                    {errors.email && <span className="form__form-group-error">{errors.email.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_phone">Phone *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="phone"
                      type="text"
                      placeholder="1234567890"
                      id="t_phone"
                      {...register('phone', {
                        required: 'Phone is required',
                      })}
                      defaultValue={testData.phone ? testData.phone : ''}
                    />
                    {errors.phone && <span className="form__form-group-error">{errors.phone.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_selectOption">Select Option *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="selectOption"
                      id="t_selectOption"
                      control={control}
                      defaultValue={selectValues[2]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={selectValues}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Select option is required',
                      }}
                    />
                    {errors.selectOption && <span className="form__form-group-error">{errors.selectOption.message}</span>}
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_selectMultiOption">Multiple Select Option</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="selectMultiOption"
                      id="t_selectMultiOption"
                      control={control}
                      defaultValue={[selectValues[0], selectValues[1]]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={selectValues}
                          isMulti

                        />
                      )}

                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_radio">Gender *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="radio"
                      id="t_radio"
                      control={control}
                      // defaultValue="female"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <FormControlLabel value="male" control={<Radio />} label="Male" />
                          <FormControlLabel value="female" control={<Radio />} label="Female" />
                          <FormControlLabel value="other" control={<Radio />} label="Other" />
                        </RadioGroup>
                      )}
                      rules={{
                        required: 'Gender is required',
                      }}
                    />
                    {errors.radio && <span className="form__form-group-error">{errors.radio.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="checkbox">Course</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <FormGroup id="checkbox">
                      <FormControlLabel
                        control={<Checkbox name="html" value="HTML" />}
                        label="HTML"
                        {...register('html')}
                      />
                      <FormControlLabel
                        control={<Checkbox name="css" value="CSS" />}
                        label="CSS"
                        {...register('css')}
                      />
                      <FormControlLabel
                        control={<Checkbox name="js" value="JS" />}
                        label="JS"
                        {...register('js')}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_datapicker">Date Picker *</label>
                <div className="form__form-group-field width-full">
                  <Controller
                    control={control}
                    name="dateinput"
                    id="t_datapicker"
                    render={({ field }) => (
                      <>
                        <DatePicker
                          placeholderText="Select date"
                          onChange={date => field.onChange(date)}
                          selected={field.value}
                          className="form__form-group-datepicker"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </>
                    )}
                    rules={{
                      required: 'Date is required',
                    }}
                  />
                </div>
                <div className="form__form-group-input-wrap">
                  {errors.dateinput && <span className="form__form-group-error">{errors.dateinput.message}</span>}  
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="t_datatimepicker">Date Time Picker</label>
                <div className="form__form-group-field width-full">
                  <Controller
                    control={control}
                    name="date-input-time"
                    id="t_datatimepicker"
                    render={({ field }) => (
                      <>
                        <DatePicker
                          placeholderText="Select date"
                          onChange={date => field.onChange(date)}
                          selected={field.value}
                          className="form__form-group-datepicker"
                          timeFormat="HH:mm"
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="textarea">Textarea</label>
                <div className="form__form-group-field">
                  <textarea
                    name="textarea"
                    type="text"
                    placeholder="Enter text..."
                    id="textarea"
                    {...register('textarea')}
                    row="5"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="active" value="true" />}
                      label="Active"
                      {...register('active')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/systemparameter/test">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TestForm;
