
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SchedulerHistoryDesign from './SchedulerHistoryDesign';
import SchedulerHistoryData from './SchedulerHistoryData';



const SchedulerHistoryIndex = () => {
    const reactTableData = SchedulerHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Scheduler History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <SchedulerHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default SchedulerHistoryIndex;
