
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TestHistoryDesign from './TestHistoryDesign';
import TestHistoryData from './TestHistoryData';



const HistoryIndex = () => {
    const reactTableData = TestHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Test Data</h3>
            <h3 className="page-subhead subhead">
              History
            </h3>
          </Col>
        </Row>
        <Row>
          <TestHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default HistoryIndex;
