
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProjectTypeHistoryDesign from './ProjectTypeHistoryDesign';
import ProjectTypeHistoryData from './ProjectTypeHistoryData';



const ProjectTypeHistoryIndex = () => {
    const reactTableData = ProjectTypeHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Project Type History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <ProjectTypeHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default ProjectTypeHistoryIndex;
