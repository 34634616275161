import React, { useState, useLayoutEffect } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import {
  Col, Container, Row, Card, CardBody, Button,
} from 'reactstrap';
import Tree from './Tree/Tree';
import SheetMatTable from './SheetTable/SheetMatTable';

const structure = [
  {
    type: 'folder',
    name: 'Work Sheet 1',
    files: [
      {
        type: 'folder',
        name: 'Sub Sheet 1',
        files: [
          { type: 'file', name: 'Sheet1.xlsx' },
          { type: 'file', name: 'Sheet2.xlsx' },
          { type: 'file', name: 'Sheet3.xlsx' },
        ],
      },
      {
        type: 'folder',
        name: 'Sub Sheet 2',
        files: [
          { type: 'file', name: 'Sheet4.xlsx' },
          { type: 'file', name: 'Sheet5.xlsx' },
        ],
      },
      { type: 'file', name: 'Sample_sheet.xlsx' },
    ],
  },
  {
    type: 'folder',
    name: 'Work Sheet 2',
    files: [
      {
        type: 'file',
        name: 'sheet2 f1.xlsx',
      },
      {
        type: 'file',
        name: 'sheet2 f2.xlsx',
      },
      {
        type: 'file',
        name: 'sheet2 f3.xlsx',
      },
      { type: 'file', name: 'sheet_sample2.xlsx' },
    ],
  },
  { type: 'file', name: 'sheet_sample.xlsx' },
  { type: 'file', name: 'test_sheet.xlsx' },
  { type: 'file', name: 'format.doc' },
];

export default function SheetManagerIndex() {
  const [data, setData] = useState(structure);

  const handleClick = (node) => {
    console.log(node);
  };
  const handleUpdate = (state) => {
    localStorage.setItem(
      'tree',
      JSON.stringify(state, (key, value) => {
        if (key === 'parentNode' || key === 'id') {
          return null;
        }
        return value;
      }),
    );
  };

  useLayoutEffect(() => {
    try {
      const savedStructure = JSON.parse(localStorage.getItem('tree'));
      if (savedStructure) {
        setData(savedStructure);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="custom-header_links mb-3">
            <h3 className="page-title d-inline">Work Sheet Manager</h3>
            <Link to="/master/sheetaccount">
              <Button className="icon" color="success"><p>Sheet Account</p></Button>
            </Link>
            <div className="d-inline">
              <strong>Sheet: </strong>
              <span>Sheet Account 1</span>
            </div>
          </div>
          <Card>
            <CardBody className="file-manager p-0">
              <div>
                {/* <Col md={12}>
                    <div>Header</div>
                  </Col> */}
                <div className="react-table__wrapper">
                  <Col md={3} className="tree-custom">
                    <div className="d-tree">
                      <Tree data={data} onUpdate={handleUpdate} onNodeClick={handleClick} />
                    </div>
                  </Col>
                  <Col md={9}>
                    <SheetMatTable />
                  </Col>
                </div>
                {/* <Col md={12}>
                    <div>Footer</div>
                  </Col> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* <FileSection /> */}
      </Row>
    </Container>
  );
}
