import axios from 'axios';
// import BasicData from '../BasicData';

const DpLanguage = () => {
    // console.log(BasicData);
    // console.log(`{${BasicData[0].url}language/1/${BasicData[0].token}/1/0}`);
    const data = [];
    const getUserData = JSON.parse(localStorage.getItem('hismartData'));
    async function dropDownData() {
        await axios.get(`${getUserData.ses_URL}language/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
        // axios.get('http://127.0.0.1:8000/language/1/30888374364/1/0')
        // axios.get(`{${BasicData[0].url}language/1/${BasicData[0].token}/1/0}`)
        .then((res) => {
            // eslint-disable-next-line array-callback-return
            Object.keys(res.data.data).map((keyName) => {
                // console.log(res.data.data);
                if (res.data.data[keyName].sp_lang_status === 1) {
               data.push({
                value: `${res.data.data[keyName].sp_lang_id}`,
                label: `${res.data.data[keyName].sp_lang_name}`,
                 }); 
                }
               }); 
        });
    }
    if (getUserData !== null) {
        dropDownData();
    }
    return data;
};
export default DpLanguage();
