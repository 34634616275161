
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserStateHistoryDesign from './UserStateHistoryDesign';
import UserStateHistoryData from './UserStateHistoryData';



const UserStateHistoryIndex = () => {
    const reactTableData = UserStateHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">User State History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <UserStateHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default UserStateHistoryIndex;
