import React, { useState, useEffect, useRef } from "react";
import "../../../../scss/component/popup.scss";
import Icon from "@mdi/react";
import { mdiClose, mdiDownload } from "@mdi/js";
import mammoth from "mammoth";
import $ from "jquery";
import { Button } from "reactstrap";

const ReactTablePopup = ({ fileUrl }) => {
  const containerRef = useRef(null);
  const [display, setDisplay] = useState(false);
  // console.log(fileUrl);

  useEffect(() => {
    const loadDocx = async () => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onload = async () => {
          const arrayBuffer = reader.result;
          const mammothResult = await mammoth.convertToHtml({ arrayBuffer });
          const html = mammothResult.value;
          $(containerRef.current).html(html);
        };
        reader.readAsArrayBuffer(blob);
      } catch (error) {
        console.error("Error loading DOCX file:", error);
      }
    };

    if (display) {
      loadDocx();
    }
  }, [fileUrl, display]);

  const handleOpen = () => {
    setDisplay(true);
    console.log(fileUrl);
  };

  const handleDownload = () => {
    window.open(fileUrl, "_blank");
  };

  const handleClose = () => {
    setDisplay(false);
  };

  return (
    <div className="popup-view">
      <div>
        <Button outline color="success" onClick={handleOpen}>
          <span class="lnr lnr-file-empty"></span>
        </Button>
      </div>
      <div className="popup" style={{ display: display ? "block" : "none" }}>
        <div className="popup-container">
          <div className="d-flex justify-content-end p-5">
            <button
              onClick={handleDownload}
              className="btn btn-success popup_buttons"
            >
              <Icon path={mdiDownload} size={1} />
            </button>
            <button
              onClick={handleClose}
              className="btn btn-danger popup_buttons"
            >
              <Icon path={mdiClose} size={1} />
            </button>
          </div>
          {/* Popup content */}
          <div className="px-5 py-0 pb-5" ref={containerRef}></div>
        </div>
      </div>
    </div>
  );
};

export default ReactTablePopup;
