/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from 'react-router-dom';
import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import DpSysFTP from '../../../../Components/Dropdowns/DpSysFTP';

const FTPAccountForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const [isLoading, setLoading] = useState(true);
  const params = useParams();
  const animatedComponents = makeAnimated();
  const [formData, setTestData] = useState({});
  const [defaultSelect, setDefaultSelect] = useState({});
  const [inputActive, setinputActive] = useState(false);
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  // const ftpAccountType = [
  //   { value: '1', label: 'Type1' },
  //   { value: '2', label: 'Type2' },
  //   { value: '3', label: 'Type3' },
  // ];
  const ftpAccountType = DpSysFTP;

  // const onSubmit = data => console.log(data);
  const [editedData, setEditedData] = useState([{
      gn_ftp_acc_name: '',
      gn_ftp_acc_code: '',
      gn_ftp_acc_type: '',
      gn_ftp_acc_keys: {
          host: '',
          username: '',
          password: '',
          port: '',
          pem: '',
      },
      gn_ftp_acc_remarks: '',
      gn_ftp_acc_status: '',
      gn_ftp_acc_modified_by: '',
      gn_ftp_acc_modified_on: '',
      gn_ftp_acc_Created_at: '',
  }]);
  async function onSubmit(data) {
    console.log(data);
    setEditedData( 
      editedData[0].gn_ftp_acc_name = data.gn_ftp_acc_name,
      editedData[0].gn_ftp_acc_code = data.gn_ftp_acc_code,
      editedData[0].gn_ftp_acc_type = `${data.ftp_acc_type.value}`,
      editedData[0].gn_ftp_acc_keys.host = data.gn_ftp_acc_host,
      editedData[0].gn_ftp_acc_keys.username = data.gn_ftp_acc_username,
      editedData[0].gn_ftp_acc_keys.password = data.gn_ftp_acc_password,
      editedData[0].gn_ftp_acc_keys.port = data.gn_ftp_acc_port,
      editedData[0].gn_ftp_acc_keys.pem = data.gn_ftp_acc_pem,
      editedData[0].gn_ftp_acc_remarks = data.gn_ftp_acc_remarks,
      editedData[0].gn_ftp_acc_Created_at = data.gn_ftp_acc_Created_at,
    );
    console.log(inputActive);
    if (inputActive === true) {
      setEditedData(editedData[0].gn_ftp_acc_status = '1');
    } else {
      setEditedData(editedData[0].gn_ftp_acc_status = '0');
    }
    setEditedData(editedData[0].gn_ftp_acc_modified_by = '1');
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    setEditedData(editedData[0].gn_ftp_acc_modified_on = currentDateFormate);

    if (data.gn_ftp_acc_Created_at === '') {
      // console.log('data is there');
      setEditedData(editedData[0].gn_ftp_acc_Created_at = currentDateFormate);
    } else {
      const crDate = new Date(data.gn_ftp_acc_Created_at);
      setEditedData(editedData[0].gn_ftp_acc_Created_at = `${crDate.getFullYear()}-${crDate.getMonth() + 1}-${crDate.getDate()}`);
    }

    // editedData.push(data); 
    console.log(editedData);
    if (params.action === 'create') {
      await axios.post(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`, editedData)
    // axios.post('http://127.0.0.1:8000/ftp/1/30888374364/1/0', editedData)
    .then((res) => {
      console.log(res.data);
      if (res.data.status_code === 200) {
        toast.success('New FTP added successfully');
        document.getElementById('backButton_master_ftp').click();
      } else {
        toast.error('Try Again!');
      }
    });
    } else if (params.action === 'edit') {
      await axios.put(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`, editedData)
      // axios.put(`http://127.0.0.1:8000/ftp/1/30888374364/2/${params.id}`, editedData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success('FTP updated successfully');
          document.getElementById('backButton_master_ftp').click();
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    }
  }

  if (params.action === 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setinputActive(true);
      setTestData({
        gn_ftp_acc_name: '',
        gn_ftp_acc_code: '',
        gn_ftp_acc_type: '',
        gn_ftp_acc_host: '',
        gn_ftp_acc_username: '',
        gn_ftp_acc_password: '',
        gn_ftp_acc_port: '',
        gn_ftp_acc_pem: '',
        gn_ftp_acc_remarks: '',
        gn_ftp_acc_modified_by: '',
        gn_ftp_acc_modified_on: '',
        gn_ftp_acc_Created_at: '',
      });
      setLoading(false);
    }, [params.id]);
  } else if ((params.action === 'edit') || (params.action === 'view')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      async function viewData() {
        await axios.get(`${getUserData.ses_URL}ftp/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${params.id}`)
        // axios.get(`http://127.0.0.1:8000/ftp/1/30888374364/2/${params.id}`)
          .then((res) => {
            console.log(res.data.data.detail[0]);
            console.log(JSON.parse(res.data.data.detail[0].gn_ftp_acc_keys));
            const ftpKeys = JSON.parse(res.data.data.detail[0].gn_ftp_acc_keys);
            // setTimeout(() => setTestData(res.data.data[0]), 1000);
            setDefaultSelect({
              type: res.data.data.detail[0].gn_ftp_acc_type - 1,
            });

            // dropdown list check
            // console.log(ftpAccountType);
            const dpIndex = ftpAccountType.findIndex(item => item.value === `${res.data.data.detail[0].gn_ftp_acc_type}`);
            console.log(dpIndex);
            setDefaultSelect({
              type: dpIndex,
            });

            setTestData(
              {
                gn_ftp_acc_name: res.data.data.detail[0].gn_ftp_acc_name,
                gn_ftp_acc_code: res.data.data.detail[0].gn_ftp_acc_code,
                gn_ftp_acc_type: res.data.data.detail[0].gn_ftp_acc_type,
                gn_ftp_acc_host: ftpKeys.host,
                gn_ftp_acc_username: ftpKeys.username,
                gn_ftp_acc_password: ftpKeys.password,
                gn_ftp_acc_port: ftpKeys.port,
                gn_ftp_acc_pem: ftpKeys.pem,
                gn_ftp_acc_remarks: res.data.data.detail[0].gn_ftp_acc_remarks,
                gn_ftp_acc_modified_by: res.data.data.detail[0].gn_ftp_acc_modified_by,
                gn_ftp_acc_modified_on: res.data.data.detail[0].gn_ftp_acc_modified_on,
                gn_ftp_acc_Created_at: res.data.data.detail[0].gn_ftp_acc_Created_at,
              },
              );
            if (res.data.data.detail[0].gn_ftp_acc_status === 1) {
              setinputActive(true);
            } else {
              setinputActive(false);
            }
            setLoading(false);
          });
      }
  viewData();
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  } 
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <Link to="/master/ftpaccount">
              <Button className="icon" id="backButton_master_ftp" color="primary"><p><ArrowLeftBoldIcon /> Back</p></Button>
            </Link>
            <h5 className="bold-text mt-3">FTP Account {params.action}-Form</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_name">FTP Account *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_name"
                      type="text"
                      placeholder="FTP Name"
                      id="gn_ftp_acc_name"
                      defaultValue={formData.gn_ftp_acc_name ? formData.gn_ftp_acc_name : ''}
                      {...register('gn_ftp_acc_name', {
                        required: 'FTP Account is required',
                      })}
                    />
                    {errors?.gn_ftp_acc_name && <span className="form__form-group-error">{errors.gn_ftp_acc_name.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_code">FTP Account Code *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_code"
                      type="text"
                      placeholder="Name"
                      id="gn_ftp_acc_code"
                      defaultValue={formData.gn_ftp_acc_code ? formData.gn_ftp_acc_code : ''}
                      {...register('gn_ftp_acc_code', {
                        required: 'FTP Account Code is required',
                      })}
                    />
                    {errors?.gn_ftp_acc_code && <span className="form__form-group-error">{errors.gn_ftp_acc_code.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="ftp_acc_type">Account Type *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="ftp_acc_type"
                      id="ftp_acc_type"
                      control={control}
                      defaultValue={ftpAccountType[defaultSelect.type]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={ftpAccountType}
                          components={animatedComponents}
                        />
                      )}
                      rules={{
                        required: 'Select option is required',
                      }}
                    />
                    {errors.ftp_acc_type && <span className="form__form-group-error">{errors.ftp_acc_type.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_remarks">FTP Account Remarks</label>
                <div className="form__form-group-field">
                  <textarea
                    name="gn_ftp_acc_remarks"
                    type="text"
                    placeholder="Enter the text..."
                    id="gn_ftp_acc_remarks"
                    {...register('gn_ftp_acc_remarks')}
                    row="3"
                  />
                </div>
              </div>
            </div>

            <Col md={12}>
              <h5 className="bold-text mt-3 mb-3">FTP Account Key</h5>
            </Col>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_host">Hostname *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_host"
                      type="text"
                      placeholder="hostname"
                      id="gn_ftp_acc_host"
                      defaultValue={formData.gn_ftp_acc_host ? formData.gn_ftp_acc_host : ''}
                      {...register('gn_ftp_acc_host', {
                        required: 'hostname is required',
                      })}
                    />
                    {errors?.gn_ftp_acc_host && <span className="form__form-group-error">{errors.gn_ftp_acc_host.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_username">Username *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_username"
                      type="text"
                      placeholder="Username"
                      id="gn_ftp_acc_username"
                      defaultValue={formData.gn_ftp_acc_username ? formData.gn_ftp_acc_username : ''}
                      {...register('gn_ftp_acc_username', {
                        required: 'Username is required',
                      })}
                    />
                    {errors?.gn_ftp_acc_username && <span className="form__form-group-error">{errors.gn_ftp_acc_username.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_password">Password *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_password"
                      type="text"
                      placeholder="Password"
                      id="gn_ftp_acc_password"
                      defaultValue={formData.gn_ftp_acc_password ? formData.gn_ftp_acc_password : ''}
                      {...register('gn_ftp_acc_password', {
                        required: 'Password is required',
                      })}
                    />
                    {errors?.gn_ftp_acc_password && <span className="form__form-group-error">{errors.gn_ftp_acc_password.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_port">Port *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_port"
                      type="text"
                      placeholder="Port"
                      id="gn_ftp_acc_port"
                      defaultValue={formData.gn_ftp_acc_port ? formData.gn_ftp_acc_port : ''}
                      {...register('gn_ftp_acc_port', {
                        required: 'Port is required',
                      })}
                    />
                    {errors?.gn_ftp_acc_port && <span className="form__form-group-error">{errors.gn_ftp_acc_port.message}</span>}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="gn_ftp_acc_file">Files Pem/ppk</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="gn_ftp_acc_file"
                      type="file"
                      id="gn_ftp_acc_file"
                      defaultValue={formData.gn_ftp_acc_file ? formData.gn_ftp_acc_file : ''}
                      {...register('gn_ftp_acc_file')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox 
                          name="sp_ftp_acc_typ_status" 
                          control={control}
                          value={inputActive}  
                          // eslint-disable-next-line react/jsx-boolean-value
                          checked={inputActive}                   
                          render={({ field }) => <Checkbox {...field} />}
                          onClick={(e) => {
                            setinputActive(e.target.checked);
                          }}
                        />
                      )}
                      label="Active" 
                      {...register('sp_ftp_acc_typ_status')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                {
                  (params.action === 'create')
                    ? (
                      <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
                        Cancel
                      </Button>
                    )
                    : (
                      <Link to="/master/ftpaccount">
                        <Button> Back</Button>
                      </Link>
                    )
                }
                {
                  // eslint-disable-next-line consistent-return
                  (() => {
                    if (params.action === 'create') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-else-return
                    } else if (params.action === 'edit') {
                      return (
                        <Button color="success" type="submit">Submit</Button>
                      );
                      // eslint-disable-next-line no-empty
                    } else if (params.action === 'view') {

                    }
                  })()
                }
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FTPAccountForm;
