/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const SchedulingTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const link = {
    toCreateLink: `/master/scheduling/${params.proid}/view/`,
    toEditLink: `/master/scheduling/${params.proid}/edit/`,
    toDeleteLink: `/master/scheduling/${params.proid}`,
  };
  const [proName, setProName] = useState('Project');
  const [tableData, setTableData] = useState([]);
 
  // useEffect(() => {
  //   data.push({
  //     map_project_id: '1',
  //     map_schdl_type: '',
  //     map_schdl_name: 'Schedule 1',
  //     map_schdl_detail: 'Text',
  //     map_schdl_from: 'September 27, 2021 12:00 AM',
  //     map_schdl_to: 'September 28, 2021 12:00 AM',
  //     map_schdl_remarks: 'Remarks',
  //     map_schdl_status: 'Active',
  //     action: <ActionGroupButton
  //       noHistory
  //       btnID="1"
  //       toCreateLink={`${link.toCreateLink}/1`}
  //       toEditLink={`${link.toEditLink}/1`}
  //       toDeleteLink={link.toDeleteLink}
  //     />,
  //   });
  //   setTableData(data);
  // }, []);

  async function loadMasterProjectSchMapping() {
    await axios.get(`${getUserData.ses_URL}project/schedule_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/project/schedule_map/1/30888374364/1/0')
    .then((res) => {
      // testShowDataSet();
      // testShowDataGet();
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(typeof (res.data.data[keyName].map_project_id));
        // console.log(typeof (params.proid));
        // const test = params.proid;
        // console.log(typeof (parseInt(params.proid, 10)));
        if ((res.data.data[keyName].map_schdl_status !== 2) && (res.data.data[keyName].map_project_id) === parseInt(params.proid, 10)) {
        setProName(`${res.data.data[keyName].pro_name}`);
        // if ((res.data.data[keyName].gn_pro_status !== 2)) {
          const currentFromDate = new Date(res.data.data[keyName].map_schdl_from).getFullYear();
          const currentToDate = new Date(res.data.data[keyName].map_schdl_to).getFullYear();

       data.push({
        map_schdl_id: `${res.data.data[keyName].map_schdl_id}`,
        pro_name: `${res.data.data[keyName].pro_name}`,
        map_schdl_name: `${res.data.data[keyName].map_schdl_name}`,
        // map_schdl_detail: `${res.data.data[keyName].map_schdl_detail}`,
        // map_schdl_from: `${res.data.data[keyName].map_schdl_from}`,
        map_schdl_from: currentFromDate === 1970 ? '-' : `${res.data.data[keyName].map_schdl_from}`,
        // map_schdl_to: `${res.data.data[keyName].map_schdl_to}`,
        map_schdl_to: currentToDate === 1970 ? '-' : `${res.data.data[keyName].map_schdl_to}`,
        status_name: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={res.data.data[keyName].map_schdl_id}
             toSection="master-project-sch"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].map_schdl_id}`}
             noHistory
             toEditLink={`${link.toEditLink}${res.data.data[keyName].map_schdl_id}`}
             toDeleteMethod={() => deleteMasterProjectSchMapping(`${res.data.data[keyName].map_schdl_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  async function deleteMasterProjectSchMapping(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Project Schedule?');
    if (confirmBox === true) {
      await axios.delete(`${getUserData.ses_URL}project/schedule_map/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      // axios.delete(`http://127.0.0.1:8000/project/schedule_map/1/30888374364/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadMasterProjectSchMapping();  
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    } 
  }
  useEffect(() => {
    loadMasterProjectSchMapping();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'map_schdl_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Schedule Name',
        accessor: 'map_schdl_name',
      },
      // {
      //   Header: 'Details',
      //   accessor: 'map_schdl_detail',
      // },
      {
        Header: 'From',
        accessor: 'map_schdl_from',
      },
      {
        Header: 'To',
        accessor: 'map_schdl_to',
      },
      {
        Header: 'Status',
        accessor: 'status_name',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData, projectName: proName };
  return reactTableData;
};

export default SchedulingTableData;
