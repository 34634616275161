/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// import axios from 'axios';
import { Col, Button, ButtonToolbar,} from 'reactstrap';
import {  useParams } from 'react-router-dom';
//import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
//import {  Checkbox, FormControlLabel, FormGroup,} from '@material-ui/core';

const UserProfileForm = ({ pristine, submitting }) => {
  const params = useParams();
  const [formData, setTestData] = useState({});
  const {
    handleSubmit, register, reset, control, formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });
  const onSubmit = data => console.log(data);

  if (params.action !== 'create') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setTestData({
        usr_first_name: 'Admin',
        usr_last_name: 'HiSmart',
        usr_username: 'Admin',
        usr_official_mail: 'admin@gmail.com',
        usr_primary_mobile: '231237432321',
      });
    }, [params.id]);
  }

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Col md={12} lg={12}>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_first_name">First Name *</label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_first_name"
                  type="text"
                  placeholder="First Name"
                  id="usr_first_name"
                  defaultValue={formData.usr_first_name ? formData.usr_first_name : ''}
                  {...register('usr_first_name', {
                    required: 'User first name is required',
                  })}
                />
                {errors?.usr_first_name && <span className="form__form-group-error">{errors.usr_first_name.message}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_last_name">Last Name *</label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_last_name"
                  type="text"
                  placeholder="Last Name"
                  id="usr_last_name"
                  defaultValue={formData.usr_last_name ? formData.usr_last_name : ''}
                  {...register('usr_last_name', {
                    required: 'User last name is required',
                  })}
                />
                {errors?.usr_last_name && <span className="form__form-group-error">{errors.usr_last_name.message}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_username">Username *</label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_username"
                  type="text"
                  placeholder="Username"
                  id="usr_username"
                  defaultValue={formData.usr_username ? formData.usr_username : ''}
                  {...register('usr_username', {
                    required: 'Username is required',
                  })}
                />
                {errors?.usr_username && <span className="form__form-group-error">{errors.usr_username.message}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_official_mail">Official email *</label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_official_mail"
                  type="email"
                  placeholder="abcd@123.com"
                  id="usr_official_mail"
                  defaultValue={formData.usr_official_mail ? formData.usr_official_mail : ''}
                  {...register('usr_official_mail', {
                    required: 'Official email is required',
                  })}
                />
                {errors?.usr_official_mail && <span className="form__form-group-error">{errors.usr_official_mail.message}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_personal_mail">Personal email </label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_personal_mail"
                  type="email"
                  placeholder="abcd@123.com"
                  id="usr_personal_mail"
                  defaultValue={formData.usr_personal_mail ? formData.usr_personal_mail : ''}
                  {...register('usr_personal_mail')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_primary_mobile">Primary Number *</label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_primary_mobile"
                  type="number"
                  placeholder="1234567890"
                  id="usr_primary_mobile"
                  defaultValue={formData.usr_primary_mobile ? formData.usr_primary_mobile : ''}
                  {...register('usr_primary_mobile', {
                    required: 'Primary number is required',
                  })}
                />
                {errors?.usr_primary_mobile && <span className="form__form-group-error">{errors.usr_primary_mobile.message}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form__form-group">
            <label className="form__form-group-label" htmlFor="usr_secondary_mobile">Secondary Number</label>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  name="usr_secondary_mobile"
                  type="number"
                  placeholder="1234567890"
                  id="usr_secondary_mobile"
                  defaultValue={formData.usr_secondary_mobile ? formData.usr_secondary_mobile : ''}
                  {...register('usr_secondary_mobile')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <ButtonToolbar className="form__button-toolbar">
            <Button type="button" onClick={() => reset({})} disabled={pristine || submitting}>
              Cancel
            </Button>
            <Button color="success" type="submit">Submit</Button>
          </ButtonToolbar>
        </div>
      </form>
    </Col>
  );
};

export default UserProfileForm;
