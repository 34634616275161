/* eslint-disable array-callback-return */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonToolbar,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { toast } from "react-toastify";
// import DpLanguage from '../../../../Components/Dropdowns/DpLanguage';

const AppSettingsForm = ({ pristine, submitting }) => {
  const [getUserData, setGetUserData] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (getUserData === null) {
    setGetUserData({
      ses_URL: "",
      ses_jwt: "",
    });
  }
  // const [selectedOptionType, setSelectedOptionType] = useState(1);
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(true);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      formData,
    },
  });

  const history = useHistory();

  const CurrencyType = [
    { value: "USD", label: "US Dollar - USD", placeholder: "US Dollar" },
    { value: "INR", label: "Indian Rupee - INR", placeholder: "Indian Rupee" },
    {
      value: "AUD",
      label: "Australian Dollar - AUD",
      placeholder: "Australian Dollar",
    },
    {
      value: "SGD",
      label: "Singapore Dollar - SGD",
      placeholder: "Singapore Dollar",
    },
  ];
  const CurrencyTls = [
    { value: "Yes", label: "Yes", placeholder: "Yes" },
    { value: "No", label: "No", placeholder: "No" },
  ];
  const WeeklyDropdown = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
  ];
  const userSectionDropdown = WeeklyDropdown;

  const [currencyCodeIndex, setCurrencyCodeIndex] = useState(0);
  const [tslCodeIndex, setTslCodeIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  // Indian Rupee - INR, US Dollar - USD, Australian Dollar - AUD,  - EUR, Singapore Dollar - SGD,

  const { t } = useTranslation("common");
  const animatedComponents = makeAnimated();

  const [editedData, setEditedData] = useState([
    {
      app_setting_ccy_name: "",
      app_setting_ccy_code: "",
      app_setting_admin_mail: "",
      app_setting_admin_password: "",
      app_setting_mail_cc: "",
      app_setting_week_off: "",
      app_setting_modified_by: "",
      app_setting_modified_on: "",
      app_setting_smtp_tls: "",
      app_setting_smtp_server: "",
      app_setting_smtp_port: "",
    },
  ]);

  // Section selected dp
  const formSectionDp = [];
  const [selectedSectionDp, setSelectedSectionDp] = useState(formSectionDp);

  function onSubmit(data) {
    console.log("data", data, editedData);
    // const values = data.app_setting_week_off.split(',').map(item => parseInt(item.trim()));

    // if (values[0] > 6 || values[1] > 6 || values[2] > 6 || values[3] > 6 || values.some(isNaN)) {
    //   console.log('Invalid input');
    //   setError('Invalid input: Values greater than 6 are not allowed');
    // } else {
    //   console.log('Valid input');
    if (
      typeof data.app_setting_ccy_code === "object" &&
      data.app_setting_ccy_code !== null
    ) {
      console.log("obj");
      setEditedData(
        (editedData[0].app_setting_ccy_code = data.app_setting_ccy_code.value),
        (editedData[0].app_setting_ccy_name =
          data.app_setting_ccy_code.placeholder)
      );
    } else {
      console.log("no obj");
      setEditedData(
        (editedData[0].app_setting_ccy_code = data.app_setting_ccy_code),
        (editedData[0].app_setting_ccy_name = data.app_setting_ccy_name)
      );
    }
    if (
      typeof data.app_setting_smtp_tls === "object" &&
      data.app_setting_smtp_tls !== null
    ) {
      console.log("obj");
      console.log(data.app_setting_smtp_tls.value);

      setEditedData(
        (editedData[0].app_setting_smtp_tls = data.app_setting_smtp_tls.value)
      );
    } else {
      console.log(data.app_setting_smtp_tls);
      editedData[0].app_setting_smtp_tls = data.app_setting_smtp_tls;
    }
    setEditedData(
      (editedData[0].app_setting_admin_mail = data.app_setting_admin_mail),
      (editedData[0].app_setting_admin_password =
        data.app_setting_admin_password),
      (editedData[0].app_setting_mail_cc = data.app_setting_mail_cc),
      (editedData[0].app_setting_smtp_port =data.app_setting_smtp_port.toString()),
      (editedData[0].app_setting_smtp_server = data.app_setting_smtp_server)
      // editedData[0].app_setting_week_off = data.app_setting_week_off,
      // editedData[0].app_setting_week_off = data.app_setting_week_off1.map(option => option.value).join(', '),
    );

    if (data.app_setting_week_off1 === undefined) {
      setEditedData(
        (editedData[0].app_setting_week_off = data.app_setting_week_off)
      );
    } else {
      setEditedData(
        (editedData[0].app_setting_week_off = data.app_setting_week_off1
          .map((option) => option.value)
          .join(", "))
      );
    }

    setEditedData((editedData[0].app_setting_modified_by = "1"));
    const currentDate = new Date();
    // eslint-disable-next-line no-undef
    const currentDateFormate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    setEditedData((editedData[0].app_setting_modified_on = currentDateFormate));

        console.log("editedData", editedData);

    axios
      .put(
        `${getUserData.ses_URL}app_setting/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1`,
        editedData
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success("App Settings updated successfully");
          history.push("/dashboard");
        } else {
          toast.error("Try Again!");
          // reset();
        }
      });
    // }
  }

  useEffect(() => {
    axios
      .get(
        `${getUserData.ses_URL}app_setting/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1`
      )
      .then((res) => {
        setFormData(res.data.data[0]);
        console.log(res.data.data);
        // setCurrencyName(res.data.data[0].app_setting_ccy_code);
        // defaultValue = CurrencyType.find(option => option.value === res.data.data[0].app_setting_ccy_code);
        const defaultValue = CurrencyType.findIndex(
          (item) => item.value === `${res.data.data[0].app_setting_smtp_tls}`
        );
        const CurrencyTlsValue = CurrencyTls.findIndex(
          (item) => item.value === `${res.data.data[0].app_setting_smtp_tls}`
        );

        // section
        res.data.data[0].app_setting_week_off.split(",").map((item) => {
          formSectionDp.push(
            WeeklyDropdown.filter((v) => v.value === item.trim().toString())[0]
          );
        });

        console.log("formSectionDp", formSectionDp);
        // console.log('selectedSectionDp', selectedSectionDp);
        // console.log(defaultValue);
        setCurrencyCodeIndex(defaultValue);
        setTslCodeIndex(CurrencyTlsValue);

        // console.log(currencyCodeIndex);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    console.log(formData);
    reset(formData);
  }, [formData]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title col-sm-12">
            <h5 className="bold-text mt-3">App Settings</h5>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_ccy_code"
                >
                  Currency Code *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="app_setting_ccy_code"
                      id="app_setting_ccy_code"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={CurrencyType}
                          components={animatedComponents}
                          // defaultValue={CurrencyType[currencyCodeIndex]}
                          value={CurrencyType[currencyCodeIndex]}
                          onChange={(event) => {
                            field.onChange(event);
                            setCurrencyCodeIndex(
                              CurrencyType.findIndex(
                                (item) => item.value === event.value
                              )
                            );
                          }}
                        />
                      )}
                      rules={{
                        required: "Currency Code is required",
                      }}
                    />
                    {errors.app_setting_ccy_code && (
                      <span className="form__form-group-error">
                        {errors.app_setting_ccy_code.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_week_off1"
                >
                  weekly-off *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    {/* <Select
                      name="app_setting_week_off"
                      id="app_setting_week_off"
                      closeMenuOnSelect={false}
                      defaultValue={selectedSectionDp || ''}
                      isMulti
                      options={WeeklyDropdown}
                      control={control}
                      {...register('app_setting_week_off', { required: 'Week off is required' })}
                    />
                    {errors.app_setting_week_off && <span className="form__form-group-error">{errors.app_setting_week_off.message}</span>} */}
                    <Controller
                      name="app_setting_week_off1"
                      control={control}
                      // defaultValue={[
                      //   { value: '0', label: 'Sunday' },
                      //   { value: '1', label: 'Monday' },
                      // ]}
                      defaultValue={selectedSectionDp || ""}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={WeeklyDropdown}
                          closeMenuOnSelect={false}
                          defaultValue={selectedSectionDp}
                          isMulti
                          // {...register('app_setting_week_off1', {
                          //   required: 'Week off is required',
                          // })}
                          onChange={(event) => {
                            field.onChange(event);
                            console.log(event);
                            if (event === null) {
                              setError("please select the week off days");
                              // event.preventDefault();
                            } else {
                              setError("");
                            }
                          }}
                        />
                      )}
                    />
                    {error && (
                      <span className="form__form-group-error">{error}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_admin_mail"
                >
                  Admin Email *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="app_setting_admin_mail"
                      id="app_setting_admin_mail"
                      type="email"
                      placeholder="abcd@zyx.com"
                      defaultValue={
                        formData.app_setting_admin_mail
                          ? formData.app_setting_admin_mail
                          : ""
                      }
                      {...register("app_setting_admin_mail", {
                        required: "Admin email is required",
                      })}
                    />
                    {errors?.app_setting_admin_mail && (
                      <span className="form__form-group-error">
                        {errors.app_setting_admin_mail.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_admin_password"
                >
                  Password *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="app_setting_admin_password"
                      id="app_setting_admin_password"
                      type="text"
                      placeholder="abcd@zyx.com"
                      defaultValue={
                        formData.app_setting_admin_password
                          ? formData.app_setting_admin_password
                          : ""
                      }
                      {...register("app_setting_admin_password", {
                        required: "Password is required",
                      })}
                    />
                    {errors?.app_setting_admin_password && (
                      <span className="form__form-group-error">
                        {errors.app_setting_admin_password.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_week_off"
                >
                  Smtp Server *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="app_setting_smtp_server"
                      id="app_setting_smtp_server"
                      type="text"
                      placeholder="Smtp Server"
                      defaultValue={
                        formData.app_setting_smtp_server
                          ? formData.app_setting_smtp_server
                          : ""
                      }
                      {...register("app_setting_smtp_server", {
                        required: "Smtp Server is required",
                      })}
                    />
                    {errors?.app_setting_smtp_server && (
                      <span className="form__form-group-error">
                        {errors.app_setting_smtp_server.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_smtp_port"
                >
                  Smtp Port *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input
                      name="app_setting_smtp_port"
                      id="app_setting_smtp_port"
                      type="text"
                      placeholder="Smtp Port"
                      defaultValue={
                        formData.app_setting_smtp_port
                          ? formData.app_setting_smtp_port
                          : ""
                      }
                      {...register("app_setting_smtp_port", {
                        required: "Smtp Port is required",
                      })}
                    />
                    {errors?.app_setting_smtp_port && (
                      <span className="form__form-group-error">
                        {errors.app_setting_smtp_port.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_mail_cc"
                >
                  CC Mail Accounts *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <textarea
                      name="app_setting_mail_cc"
                      id="app_setting_mail_cc"
                      placeholder="enter CC email address"
                      defaultValue={
                        formData.app_setting_mail_cc
                          ? formData.app_setting_mail_cc
                          : ""
                      }
                      {...register("app_setting_mail_cc", {
                        required: "Enter CC email address",
                      })}
                    />
                    {errors?.app_setting_mail_cc && (
                      <span className="form__form-group-error">
                        {errors.app_setting_mail_cc.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="input-notes">
                  <div>
                    <span className="text-bold">Note: </span>Please add the CC
                    email accounts with the ,(comma) separation
                  </div>
                  <div>
                    <span className="text-bold">Example: </span>test1@abc.com,
                    test2@abc.com, test3@abc.com
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form__form-group">
                <label
                  className="form__form-group-label"
                  htmlFor="app_setting_smtp_tls"
                >
                  Smtp Tsl *
                </label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Controller
                      name="app_setting_smtp_tls"
                      id="app_setting_smtp_tls"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={CurrencyTls}
                          components={animatedComponents}
                          // defaultValue={CurrencyType[currencyCodeIndex]}
                          value={CurrencyTls[tslCodeIndex]}
                          onChange={(event) => {
                            field.onChange(event);
                            setTslCodeIndex(
                              CurrencyTls.findIndex(
                                (item) => item.value === event.value
                              )
                            );
                          }}
                        />
                      )}
                      rules={{
                        required: "Smtp Tls is required",
                      }}
                    />
                    {errors.app_setting_smtp_tls && (
                      <span className="form__form-group-error">
                        {errors.app_setting_smtp_tls.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="app_setting_week_off">weekly-off *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <Select
                      name="app_setting_week_off"
                      id="app_setting_week_off"
                      closeMenuOnSelect={false}
                      defaultValue={selectedSectionDp || ''}
                      isMulti
                      options={WeeklyDropdown}
                      // control={control}
                      // {...register('app_setting_week_off')}
                      onChange={(event) => {
                        console.log(event.target);
                      }}
                    />
                    {errors.app_setting_week_off && <span className="form__form-group-error">{errors.app_setting_week_off.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <Select
              name="app_setting_week_off1"
              id="app_setting_week_off1"
              closeMenuOnSelect={false}
              defaultValue={selectedSectionDp}
              isMulti
              options={WeeklyDropdown}
              // styles={colourStyles}
              {...register('app_setting_week_off', {
                required: 'Week off is required',
              })}
            /> */}

            {/* <div className="col-md-4">
              <div className="form__form-group">
                <label className="form__form-group-label" htmlFor="app_setting_week_off">weekly-off *</label>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap"> */}
            {/* <Controller */}

            {/* // render={({ field }) => ( */}
            {/* //   <Select
                      //     {...field}
                      //     options={WeeklyDropdown}
                      //     components={animatedComponents}
                      //     closeMenuOnSelect={false}
                      //     isMulti
                      //   />
                      // )}
                      // render={({ field }) => (
                      //   <> */}
            {/* <Select
                      name="app_setting_week_off"
                      id="app_setting_week_off"
                      defaultValue={selectedSectionDp}
                      control={control}
                      options={WeeklyDropdown}
                      components={animatedComponents}
                      closeMenuOnSelect={false}
                      onChange={setSelectedSectionDp}
                      isMulti
                      {...register('app_setting_week_off', {
                        required: 'Week off is required',
                      })}
                    />
                    {errors.app_setting_week_off && <span className="form__form-group-error">{errors.app_setting_week_off.message}</span>}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-4">
                    <div className="form__form-group">
                      <label className="form__form-group-label" htmlFor="app_setting_week_off">weekly-off *</label>
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                          <input
                            name="app_setting_week_off"
                            id="app_setting_week_off"
                            placeholder="0, 6"
                            defaultValue={formData.app_setting_week_off ? formData.app_setting_week_off : ''}
                            {...register('app_setting_week_off', {
                              required: 'Week off is required',
                            })}
                            onChange={(event) => {
                              // const { value } = event.target;
                              // // console.log(event.target);
                              // setInputValue(value);
                              // setError('');
                              // // console.log('rrr');
                              // const values = inputValue.split(',').map(item => item.trim());

                              const { value } = event.target;
                              setInputValue(value);
                              setError('');

                              const values = inputValue.split(',').map(item => item.trim());

                              //   // if (values.length > 2) {
                              //   //   setError('Invalid input');
                              //   //   return;
                              //   // }

                              const [value1, value2, value3] = values;
                              if (parseInt(value1) > 6 || parseInt(value2) > 6 || parseInt(value3) > 6) {
                                setError('Invalid input: Values greater than 6 are not allowed');
                              }
                              // field.onChange(event);
                              //   // validateInput();
                            }}
                          />
                          {errors?.app_setting_week_off && <span className="form__form-group-error">{errors.app_setting_week_off.message}</span>}
                          {error && <span className="form__form-group-error">{error}</span>}
                        </div>
                      </div>
                      <div className="input-notes">
                        <div><span className="text-bold">Note: </span>Please add the week off with the ,(comma) separation</div>
                        <div>Sunday : <span className="text-bold">0</span></div>
                        <div>Monday : <span className="text-bold">1</span></div>
                        <div>Tuesday : <span className="text-bold">2</span></div>
                        <div>Wednesday : <span className="text-bold">3</span></div>
                        <div>Thursday : <span className="text-bold">4</span></div>
                        <div>Friday : <span className="text-bold">5</span></div>
                        <div>Saturday : <span className="text-bold">6</span></div>
                        <div><span className="text-bold">Example: </span>0, 6</div>
                        <div>Sunday and Saturday as 0, 6</div>
                      </div>
                    </div>

                  </div> */}

            <div className="col-md-12">
              <ButtonToolbar className="form__button-toolbar">
                <Button
                  color="success"
                  type="submit"
                  className={error ? "disabled-button" : ""}
                >
                  Submit
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AppSettingsForm;
