export const SHEET_DATA_SET = 'SHEET_DATA_SET';
export const SHEET_DATA_GET = 'SHEET_DATA_GET';

export function toSheetDataSet(data) {
  return {
    type: SHEET_DATA_SET,
    payload: [data],
    // payload,
  };
}

export function toSheetDataGet() {
  return {
    type: SHEET_DATA_GET,
  };
}
