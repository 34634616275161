/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const SchedulerTableData = () => {
  // const getSchedulerData = JSON.parse(localStorage.getItem('hismartData'));
  // const [isLoading, setLoading] = useState(true);
  const [getSchedulerData, setGetSchedulerData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getSchedulerData === null) {
    setGetSchedulerData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  // Table Data
  // const data = [
  //   {
  //     sp_sch_id: '1',
  //     sp_sch_name: 'FollowUp 1',
  //     sp_sch_code: 'F1',
  //     scheduler_remarks: 'F1',
  //     scheduler_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  //   {
  //     sp_sch_id: '2',
  //     sp_sch_name: 'FollowUp 2',
  //     sp_sch_code: 'F2',
  //     scheduler_remarks: 'F2',
  //     scheduler_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  //   {
  //     sp_sch_id: '3',
  //     sp_sch_name: 'Reminder 1',
  //     sp_sch_code: 'R1',
  //     scheduler_remarks: 'R1',
  //     scheduler_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  // ];

  const data = [];

  const link = {
    toCreateLink: '/systemparameter/scheduler/view/',
    toHistoryLink: '/systemparameter/scheduler/history/',
    toEditLink: '/systemparameter/scheduler/edit/',
    // toDeleteLink: '/systemparameter/scheduler',
  };
  const [tableData, setTableData] = useState([]);

  async function loadSysScheduler() {
    await axios.get(`${getSchedulerData.ses_URL}schedule/${getSchedulerData.gn_usr_id}/${getSchedulerData.ses_jwt}/1/0`)
      // axios.get('http://127.0.0.1:8000/scheduler/1/30888374364/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName) => {
          // console.log(res.data.data);
          // console.log(res.data.status);
          if (res.data.data[keyName].sp_sch_status !== 2) {
            data.push({
              sp_sch_id: `${res.data.data[keyName].sp_sch_id}`,
              sp_sch_name: `${res.data.data[keyName].sp_sch_name}`,
              sp_sch_code: `${res.data.data[keyName].sp_sch_code}`,
              sp_sch_project_typ: `${res.data.data[keyName].sp_sch_project_typ}`,
              sp_sch_follow_up_day: moment(`${res.data.data[keyName].sp_sch_follow_up_day}`).format('YYYY-MM-DD'),
              sp_sch_prior: `${res.data.data[keyName].sp_sch_prior}`,
              sp_sch_business_day: moment(`${res.data.data[keyName].sp_sch_business_day}`).format('YYYY-MM-DD'),
              sp_sch_remarks: `${res.data.data[keyName].sp_sch_remarks}`,
              status_name: `${res.data.data[keyName].status_name}`,
              action: <ActionGroupButton
                btnID={`${res.data.data[keyName].sp_sch_id}`}
                toSection="sys-Scheduler"
                toCreateLink={`${link.toCreateLink}${res.data.data[keyName].sp_sch_id}`}
                toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].sp_sch_id}`}
                toEditLink={`${link.toEditLink}${res.data.data[keyName].sp_sch_id}`}
                toDeleteMethod={() => deleteSysScheduler(`${res.data.data[keyName].sp_sch_id}`)}
              />,
            });
          }
        });
        console.log(data);
      });
    setTableData(data);
  }
  async function deleteSysScheduler(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Scheduler?');
    if (confirmBox === true) {
      await axios.delete(`${getSchedulerData.ses_URL}schedule/${getSchedulerData.gn_usr_id}/${getSchedulerData.ses_jwt}/2/${deleteID}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.status_code === 200) {
            // window.location = 'http://localhost:3000/hismart/systemparameter/ftpaccounttype';
            toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
            data.length = 0;
            setTableData([]);
            loadSysScheduler();
            // window.location.reload(false);       
          } else {
            toast.error('Try Again!');
          }
        });
    }
  }
  useEffect(() => {
    loadSysScheduler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'sp_sch_id',
      },
      {
        Header: 'Name',
        accessor: 'sp_sch_name',
      },
      {
        Header: 'Code',
        accessor: 'sp_sch_code',
      },
      {
        Header: 'Type',
        accessor: 'sp_sch_project_typ',
      },
//       {
//         Header: 'Follow Up Day',
//         accessor: 'sp_sch_follow_up_day',
//       },
      {
        Header: 'Prior Day',
        accessor: 'sp_sch_prior',
      },
//       {
//         Header: 'Business Day',
//         accessor: 'sp_sch_business_day',
//       },
      {
        Header: 'Remarks',
        accessor: 'sp_sch_remarks',
      },
      {
        Header: 'Status',
        accessor: 'status_name',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );
  // console.log(isLoading);
  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  // if (isLoading) {
  //   return <div className="App">Loading...</div>;
  // }
  return reactTableData;
};

export default SchedulerTableData;
