/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ActionGroupButton from '../../../../Components/ActionGroupButton';



const UserTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const data = [];
  const link = {
    toCreateLink: '/master/user/view/',
    toHistoryLink: '/master/user/history/',
    toEditLink: '/master/user/edit/',
    // toDeleteLink: '/master/user',
  };
  const [tableData, setTableData] = useState([]);

  function loadMasterUser() {
    axios.get(`${getUserData.ses_URL}user/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/user/1/30888374364/1/0')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].sp_ftp_acc_typ_status !== 2) {
       data.push({
        usr_id: `${res.data.data[keyName].gn_usr_id}`,
        usr_first: `${res.data.data[keyName].gn_usr_first_name}`,
        usr_last: `${res.data.data[keyName].gn_usr_last_name}`,
        usr_user: `${res.data.data[keyName].gn_usr_username}`,
        usr_email: `${res.data.data[keyName].gn_usr_official_mail}`,
        usr_phone: `${res.data.data[keyName].gn_usr_primary_mobile}`,
        usr_type: `${res.data.data[keyName].typ_name}`,
        usr_state: `${res.data.data[keyName].state_name}`,
        usr_status: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={res.data.data[keyName].gn_usr_id}
             toSection="master-user"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].gn_usr_id}`}
             toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].gn_usr_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].gn_usr_id}`}
             toDeleteMethod={() => deleteMasterUser(`${res.data.data[keyName].gn_usr_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  function deleteMasterUser(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this FTP Account type?');
    if (confirmBox === true) {
      axios.delete(`${getUserData.ses_URL}user/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      // axios.delete(`http://127.0.0.1:8000/ftp_account/1/30888374364/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadMasterUser();     
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    } 
  }
  useEffect(() => {
    loadMasterUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'usr_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'First Name',
        accessor: 'usr_first',
      },
      {
        Header: 'Last Name',
        accessor: 'usr_last',
      },
      {
        Header: 'Username',
        accessor: 'usr_user',
      },
      {
        Header: 'Email',
        accessor: 'usr_email',
      },
      {
        Header: 'Phone',
        accessor: 'usr_phone',
      },
      {
        Header: 'User Type',
        accessor: 'usr_type',
      },
      {
        Header: 'State',
        accessor: 'usr_state',
      },
      {
        Header: 'Status',
        accessor: 'usr_status',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default UserTableData;
