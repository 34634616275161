/* eslint-disable max-len */
/* eslint-disable no-inner-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import {
  React, useState, useEffect,
} from 'react';
import {
  Card, CardBody, Breadcrumb, BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { 
  CgFolder, CgNotes, 
} from 'react-icons/cg';
import { BiSpreadsheet } from 'react-icons/bi';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
// import { toGlobalDataSet } from '../../../redux/custom/actions/globalDataActions';
import { toSheetDataSet } from '../../../redux/custom/actions/sheetTableDataActions';


// let counter = 0;

// const createData = (fname, fdate, ftype, fsize, fnameorder) => {
//   counter += 1;
//   return {
//     id: counter, fname, fdate, ftype, fsize, fnameorder,
//   };
// };

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const SheetMatTable = (props) => {
  console.log(props);
  // const SheetMatTable = ({
  //   props,
  // }) => {
  const [isLoading, setLoading] = useState(true);
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  } 
  // else {
  //   console.log('global data updated');
  //   props.toGlobalDataSetAction('a', 'b');
  //   console.log(props); 
  // }
    
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const tData = [];
  // const [data, setData] = useState(tData);
  // eslint-disable-next-line react/destructuring-assignment
  const [data, setData] = useState(props.sheetTableData);
  
  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = 'desc';
    if (orderBy === property && order === 'desc') { orderTemp = 'asc'; }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const isSelected = id => !!selected.get(id);
  
  const [requestData, setRequestData] = useState('');

  // props.toGlobalDataSetAction('url', 'token');
  // console.log(props);

  async function getTableData() {
    // setData([
    //   {
    //     id: '1', 
    //     fname: 'name',
    //     fdate: 'September 30, 2021-11-23',
    //     ftype: 'Worksheet', 
    //     fsize: '200kb',
    //     fnameorder: 'sheet_sample',
    //   },
    // ]);
    
    // setData([
    //   createData(<div className="file-manager_icons"><CgFolder /> <span>Sub Sheet 1</span></div>, 'September 30, 2021 17:23', 'File Folder', '', 'Sub Sheet 1'),
    //   createData(<div className="file-manager_icons"><CgFolder /> <span>Sub Sheet 2</span></div>, 'October 01, 2021 01:23', 'File Folder', '', 'Sub Sheet 2'),
    //   // createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet1</span></div>, 'September 29, 2021 07:23', 'Worksheet', '22KB', 'Sheet1'),
    //   // createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet2</span></div>, 'September 20, 2021 18:23', 'Worksheet', '4KB', 'Sheet2'),
    //   // createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet3</span></div>, 'October 03, 2021 20:23', 'Worksheet', '22KB', 'Sheet3'),
    //   // createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>Sheet_sample</span></div>, 'September 30, 2021 11:23', 'Worksheet', '79KB', 'Sheet_sample'),
    //   // createData(<div className="file-manager_icons"><BiSpreadsheet /> <span>test_sheet</span></div>, 'October 03, 2021 23:23', 'Worksheet', '32KB', 'test_sheet'),
    // ]);
    // console.log(data);
    // axios.get('http://127.0.0.1:8000/smart_sheet/work_space/1/15492655537/2/1/3050104279066500')
    await axios.get(`${getUserData.ses_URL}smart_sheet/work_space/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/1/3050104279066500`)
    .then((res) => {
      // console.log(res.data.data);
      setRequestData(res.data.data);
      // console.log(requestData);
      // console.log(res.data.data.folders);
      // console.log(res.data.data.reports);
      // console.log(res.data.data.sheets);
      if (res.data.data.folders !== null) {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data.folders).map((keyName) => {
          tData.push(
            {
              id: `${res.data.data.folders[keyName].id}`, 
              // fname: <div className="file-manager_icons"><CgFolder /> <span>{res.data.data.folders[keyName].name}</span></div>,
              fname: `${res.data.data.folders[keyName].name}`,
              fdate: 'September 30, 2021-11-23',
              ftype: 'Folder', 
              fsize: '',
              fnameorder: 'sheet_sample',
              fpermalink: `${res.data.data.folders[keyName].permalink}`,
            },
          );
        });
      }
      if (res.data.data.sheets !== null) {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data.sheets).map((keyName) => {
          tData.push(
            {
              id: `${res.data.data.sheets[keyName].id}`, 
              // fname: <div className="file-manager_icons"><BiSpreadsheet /> <span>{res.data.data.sheets[keyName].name}</span></div>,
              fname: `${res.data.data.folders[keyName].name}`,
              fdate: 'September 30, 2021-11-23',
              ftype: 'Sheets', 
              fsize: '',
              fnameorder: 'sheet_sample',
              fpermalink: `${res.data.data.sheets[keyName].permalink}`,
            },
          );
        });
      }
      if (res.data.data.reports !== null) {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data.reports).map((keyName) => {
          tData.push(
            {
              id: `${res.data.data.reports[keyName].id}`, 
              // fname: <div className="file-manager_icons"><CgNotes /> <span>{res.data.data.reports[keyName].name}</span></div>,
              fname: `${res.data.data.folders[keyName].name}`,
              fdate: 'September 30, 2021-11-23',
              ftype: 'Reports', 
              fsize: '',
              fnameorder: 'sheet_sample',
              fpermalink: `${res.data.data.reports[keyName].permalink}`,
            },
          );
        });
      }
      // console.log(tData);
      // setData(tData);
      // console.log(data);
      setLoading(false);
      // console.log(data.length);
      // props.toGlobalDataSetAction('a', 'b'); 
      // props.toSheetTableDataSetAction([
      //   {
      //     id: '1', 
      //     fname: 'name',
      //     fdate: 'September 30, 2021-11-23',
      //     ftype: 'Worksheet', 
      //     fsize: '200kb',
      //     fnameorder: 'sheet_sample',
      //   },
      // ]);
      // setData(props.sheetTableData);
    // setData(tData);
    props.toSheetTableDataSetAction(tData);
      console.log(data);
      console.log(tData);
    //   setData([
    //   {
    //     id: '1', 
    //     fname: 'name',
    //     fdate: 'September 30, 2021-11-23',
    //     ftype: 'Worksheet', 
    //     fsize: '200kb',
    //     fnameorder: 'sheet_sample',
    //   },
    // ]);
    });
    // setData([
    //   {
    //     id: '1', 
    //     fname: 'name t',
    //     fdate: 'September 30, 2021-11-23',
    //     ftype: 'Worksheet', 
    //     fsize: '200kb',
    //     fnameorder: 'sheet_sample',
    //   },
    // ]);
    // setData(tData);
    // setData(props.sheetTableData);
}
// const [rowsPerPage, setRowsPerPage] = useState(data.length);
const [rowsPerPage, setRowsPerPage] = useState(25);
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(Number(event.target.value));
};
// const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));
const emptyRows = 0;

  useEffect(() => {
    getTableData();
    // setWorkspaceData(wData);
    // console.log(workspaceData);
    // console.log(props); 
    // console.log(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Card>
      <CardBody className="mt-custom">

        <div className="">
          {/* <h5 className="bold-text mb-3">Files</h5> */}
          <Breadcrumb className="mt-2 mb-5">
            <BreadcrumbItem>Workspaces</BreadcrumbItem>
            <BreadcrumbItem>{`${requestData.name}`}</BreadcrumbItem>
            {/* <BreadcrumbItem>Sub Sheet 1</BreadcrumbItem> */}
          </Breadcrumb>
        </div>
        <MatTableToolbar
          numSelected={[...selected].filter(el => el[1]).length}
          handleDeleteSelected={handleDeleteSelected}
          onRequestSort={handleRequestSort}
        />
        <div className="material-table__wrap">
          <Table stickyHeader aria-label="sticky table" className="material-table material-table__orderhide material-table__custom">
            <MatTableHead
              numSelected={[...selected].filter(el => el[1]).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((d) => {
                  const select = isSelected(d.id);
                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={event => handleClick(event, d.id)}
                      aria-checked={select}
                      tabIndex={-1}
                      key={d.id}
                      selected={select}
                    >
                      <TableCell className="material-table__cell" padding="checkbox">
                        <Checkbox checked={select} className="material-table__checkbox" />
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {d.fname}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.fdate}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.ftype}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.fsize}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{d.fnameorder}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          className="material-table__pagination material-table__hide"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[data.length, 5, 10, 15]}
          dir="ltr"
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        />
      </CardBody>
    </Card>
  );
};


const mapStateToProps = state => ({
  // rtl: state.rtl,
  // globalData: state.globalData,
  sheetTableData: state.sheetTableData,
});
const mapDispatchToProps = dispatch => ({
  // toGlobalDataSetAction: (url, token) => dispatch(toGlobalDataSet(url, token)),
  toSheetTableDataSetAction: data => dispatch(toSheetDataSet(data)),
});


// export default SheetMatTable;

export default connect(mapStateToProps, mapDispatchToProps)(SheetMatTable);
