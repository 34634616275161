
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProjectHistoryDesign from './ProjectHistoryDesign';
import ProjectHistoryData from './ProjectHistoryData';



const ProjectHistoryIndex = () => {
    const reactTableData = ProjectHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Project History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <ProjectHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default ProjectHistoryIndex;
