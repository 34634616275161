/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import FolderMultipleIcon from 'mdi-react/FolderMultipleIcon';

const TotalVendors = ({ count }) => (
  <Col md={12} xl={4} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card total-vendor">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title">
            {count}
          </h5>
          <FolderMultipleIcon style={{ fill: '#1fd15a' }} className="dashboard__trend-icon" />
        </div>
        <h5 className="dashboard__booking-total-description">Total Vendor</h5>
        <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--rounded">
          <p className="dashboard__booking-card-progress-label progress__label mb-1">Vendors</p>
          <Progress value={100} />
        </div>
      </CardBody>
    </Card>
  </Col>
 );
export default TotalVendors;
