import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import { connect } from 'react-redux';
import TemplateTypeTableData from './components/Table/TemplateTypeTableData';
import TemplateTypeTableDesign from './components/Table/TemplateTypeTableDesign';

const DataTable = () => {
    const reactTableData = TemplateTypeTableData();
    // console.log(props);
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Template Type</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <TemplateTypeTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  // export default connect(state => ({
  //   globalData: state.globalData,
  // }))(DataTable);
  export default DataTable;
