import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import DataUploadForm from './components/Form/DataUploadForm';

const Index = () => {
  const { t } = useTranslation('common');
  // const reactTableData = DataUploadTableData();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('master.dataUpload.title')}</h3>
          <h3 className="page-subhead subhead">Form</h3>
        </Col>
      </Row>
      <Row>
        <DataUploadForm />
      </Row> 
    </Container>
  );
};

export default Index;
