import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Card, CardBody, Col, Button, 
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import PlusBoldIcon from 'mdi-react/PlusBoldIcon';

const SchedulingTableDesign = ({ reactTableData }) => {
  const rows = reactTableData.tableRowsData;
  const { projectName } = reactTableData;
  console.log(projectName);
  const dNone = 'd-none';
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by ...',
  };
  const params = useParams();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title d-flex align-items-baseline">
              <h5 className="bold-text">Scheduling Project-list</h5>
              <Link to={`/master/scheduling/${params.proid}/create`}>
                <Button className="icon" color="primary ml-4"><p><PlusBoldIcon /> Add Scheduling</p></Button>
              </Link>
            </div>
            <h5>Project Name: <strong>{projectName}</strong></h5>
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
              fullCustomizer
              advanceCustomizerOff={dNone}
            />
          </div>
          <ReactTableBase
            key={withSearchEngine ? 'modified' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={rows}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

SchedulingTableDesign.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
    projectName: PropTypes.string,
  }).isRequired,
};

export default SchedulingTableDesign;
