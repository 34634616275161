/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

const TestTableData = () => {
  const data = [];
  const link = {
    toCreateLink: '/systemparameter/test/view/',
    toHistoryLink: '/systemparameter/test/history',
    toEditLink: '/systemparameter/test/edit/',
    toDeleteLink: '/systemparameter/test',
  };
  const [tableData, setTableData] = useState([]);
 
  useEffect(() => {
    axios.get('https://jsonplaceholder.typicode.com/users')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data).map((keyName) => {
       data.push({
        id: `${res.data[keyName].id}`,
        name: `${res.data[keyName].name}`,
        username: `${res.data[keyName].username}`,
        email: `${res.data[keyName].email}`,
        phone: `${res.data[keyName].phone}`,
           action: <ActionGroupButton
             btnID={res.data[keyName].id}
             toCreateLink={`${link.toCreateLink}${res.data[keyName].id}`}
             toHistoryLink={link.toHistoryLink}
             toEditLink={`${link.toEditLink}${res.data[keyName].id}`}
             toDeleteLink={link.toDeleteLink}
           />,
         }); 
       }); 
       console.log(data); 
      setTableData(data);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default TestTableData;
