/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TestHistoryData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const params = useParams();
  const data = [];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios.get(`${getUserData.ses_URL}user/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/3/${params.id}`)
    // axios.get(`http://127.0.0.1:8000/user/1/30888374364/3/${params.id}`)
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
       data.push({
        hst_id: `${res.data.data[keyName].hst_id}`,
        hst_gn_usr_first_name: `${res.data.data[keyName].hst_gn_usr_first_name}`,
        hst_gn_usr_last_name: `${res.data.data[keyName].hst_gn_usr_last_name}`,
        hst_gn_usr_typ: `${res.data.data[keyName].hst_gn_usr_typ}`,
        hst_gn_usr_official_mail: `${res.data.data[keyName].hst_gn_usr_official_mail}`,
        hst_gn_usr_primary_mobile: `${res.data.data[keyName].hst_gn_usr_primary_mobile}`,
        hst_gn_usr_state: `${res.data.data[keyName].hst_gn_usr_state}`,
        hst_gn_usr_status: `${res.data.data[keyName].hst_gn_usr_status}`,
        hst_updated_on: `${res.data.data[keyName].hst_updated_on}`,
        hst_opreation_type: `${res.data.data[keyName].hst_opreation_type}`,
         }); 
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'hst_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'First Name',
        accessor: 'hst_gn_usr_first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'hst_gn_usr_last_name',
      },
      {
        Header: 'User Type',
        accessor: 'hst_gn_usr_typ',
      },
      {
        Header: 'Email',
        accessor: 'hst_gn_usr_official_mail',
      },
      {
        Header: 'Phone',
        accessor: 'hst_gn_usr_primary_mobile',
      },
      {
        Header: 'State',
        accessor: 'hst_gn_usr_state',
      },
      {
        Header: 'Status',
        accessor: 'hst_gn_usr_status',
      },
      {
        Header: 'Modified Date',
        accessor: 'hst_updated_on',
      },
      {
        Header: 'Operation',
        accessor: 'hst_opreation_type',
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default TestHistoryData;
