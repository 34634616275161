/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';
// import moment from 'moment';
// import { RTLProps } from '@/shared/prop-types/ReducerProps';
// import { toast } from 'react-toastify';
// import Projects from './components/Projects';
// import Sheets from './components/Sheets';
// import FtpAcc from './components/FtpAcc';
// import NewUsers from './components/NewUsers';
// import ABTestingAnalytics from './components/ABTestingAnalytics';
import TotalProjects from './components/TotalProjects';
// import TotalSheets from './components/TotalSheets';
// import TotalFTP from './components/TotalFTP';
import TotalUser from './components/TotalUser';
import TotalVendors from './components/TotalVendors';
// import TotalInvoice from './components/TotalInvoice';
// import PaidInvoice from './components/PaidInvoice';
// import PendingPayment from './components/PendingPayment';
// import PaidAmount from './components/PaidAmount';
import UpcomingFollowups from './components/UpcomingFollowups';
import Followed from './components/Followed';
import ReceivedFiles from './components/ReceivedFiles';
// import ProjectAndSheets from './components/ProjectAndSheets';
import BigCalendar from '../../DefaultPage/Calendar/components/BigCalendar';
import EventLabels from '../../DefaultPage/Calendar/components/EventLabels';
import { toGlobalDataSet } from '../../../redux/custom/actions/globalDataActions';

// import BounceRateArea from './components/BounceRateArea';
// import VisitorsSessions from './components/VisitorsSessions';
// import SalesStatistic from './components/SalesStatistic';
// import BudgetStatistic from './components/BudgetStatistic';
// import AudienceByCountry from './components/AudienceByCountry';
// import BestSellingRegions from './components/BestSellingRegions';
// import GoalsCompletion from './components/GoalsCompletion';

const OnLineMarketingDashboard = (props) => {
  const [isLoading, setLoading] = useState(true);
  // const { t } = useTranslation('common');
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
      gn_usr_id: '',
    });
  }

  const data = [];
  const calenderData = [];
  const [tableData, setTableData] = useState(data);

  async function loadDashboardData() {
    console.log('global data updated');
    props.toGlobalDataSetAction(getUserData.ses_URL, getUserData.ses_jwt);
    console.log(
      props.globalData,
      `${getUserData.ses_URL}dashboard/data/${getUserData.gn_usr_id}/${getUserData.ses_jwt}`
    );
    await axios.get(`${getUserData.ses_URL}dashboard/data/${getUserData.gn_usr_id}/${getUserData.ses_jwt}`)
      // axios.get('http://127.0.0.1:8000/notification_type/1/30888374364/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        console.log(res.data.data);
        data.push(res.data.data);
        if (res.data.data.upcoming_follow_up_dates) {
          // eslint-disable-next-line array-callback-return
          Object.keys(res.data.data.upcoming_follow_up_dates).map((keyName, index) => {
            calenderData.push({
              id: `FU${index}`,
              title: `Followup-${index + 1}`,
              start: new Date(res.data.data.upcoming_follow_up_dates[keyName].upcoming_follow_up_dates),
              end: new Date(res.data.data.upcoming_follow_up_dates[keyName].upcoming_follow_up_dates),
              // end: `${new Date(2021, 11, 20, 15, 30, 10)}`,
              priority: 'red',
            });
          });
        }
        if (res.data.data.history_follow_up_dates) {
          // eslint-disable-next-line array-callback-return
          Object.keys(res.data.data.history_follow_up_dates).map((keyName, index) => {
            calenderData.push({
              id: `F${index}`,
              title: `Followed-${index + 1}`,
              start: new Date(res.data.data.history_follow_up_dates[keyName].history_follow_up_dates),
              end: new Date(res.data.data.history_follow_up_dates[keyName].history_follow_up_dates),
              // end: `${new Date(2021, 11, 20, 15, 30, 10)}`,
              priority: 'accent',
            });
          });
        }
        // if (res.data.data.sheet_list) {
        //   // eslint-disable-next-line array-callback-return
        //   Object.keys(res.data.data.sheet_list).map((keyName, index) => {
        //     calenderData.push({
        //       id: `S${index}`,
        //       title: `${res.data.data.sheet_list[keyName].gn_sheet_acc_name}`,
        //       start: new Date(res.data.data.sheet_list[keyName].gn_sheet_acc_modified_on),
        //       end: new Date(res.data.data.sheet_list[keyName].gn_sheet_acc_modified_on),
        //       // end: `${new Date(2021, 11, 20, 15, 30, 10)}`,
        //       priority: 'accent',
        //     });
        //   });
        // }
        // if (res.data.data.ftp_list) {
        //   // eslint-disable-next-line array-callback-return
        //   Object.keys(res.data.data.ftp_list).map((keyName, index) => {
        //     calenderData.push({
        //       id: `F${index}`,
        //       title: `${res.data.data.ftp_list[keyName].gn_ftp_acc_name}`,
        //       start: new Date(res.data.data.ftp_list[keyName].gn_ftp_acc_modified_on),
        //       end: new Date(res.data.data.ftp_list[keyName].gn_ftp_acc_modified_on),
        //       // end: `${new Date(2021, 11, 20, 15, 30, 10)}`,
        //       priority: 'blue',
        //     });
        //   });
        // }
        // if (res.data.data.user_list) {
        //   // eslint-disable-next-line array-callback-return
        //   Object.keys(res.data.data.user_list).map((keyName, index) => {
        //     calenderData.push({
        //       id: `U${index}`,
        //       title: `${res.data.data.user_list[keyName].gn_usr_first_name} - ${res.data.data.user_list[keyName].gn_usr_last_name}`,
        //       start: new Date(res.data.data.user_list[keyName].gn_usr_modified_on),
        //       end: new Date(res.data.data.user_list[keyName].gn_usr_modified_on),
        //       // end: `${new Date(2021, 11, 20, 15, 30, 10)}`,
        //       priority: 'yellow',
        //     });
        //   });
        // }

        // console.log(calenderData);
        data.push(calenderData);

        // console.log(data);
        setTableData(data);
        console.log(tableData);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Row>
        {/* <TotalProjects projectcount={`${tableData[0].project_count}`} /> */}
        {/* <TotalInvoice count={3} />    */}
        <TotalUser count={(tableData[0].total_count[0].user_count) ? `${tableData[0].total_count[0].user_count}` : 0} />
        <TotalVendors count={(tableData[0].total_vendor_count[0].total_vendor_count) ? `${tableData[0].total_vendor_count[0].total_vendor_count}` : 0} />
        <TotalProjects count={(tableData[0].total_count[0].project_count) ? `${tableData[0].total_count[0].project_count}` : 0} />
        {/* <TotalProjects projectcount="30" /> */}
        {/* <TotalSheets count={`${tableData[0].total_count[0].sheet_count}`} /> */}
        {/* <TotalFTP count={`${tableData[0].total_count[0].ftp_count}`} /> */}

      </Row>
      <Row>
        {/* <Projects monthlydata={tableData[0].project_monthly_count} />
        <Sheets monthlydata={tableData[0].sheet_monthly_count} />
        <FtpAcc monthlydata={tableData[0].ftp_monthly_count} />
        <NewUsers monthlydata={tableData[0].user_monthly_count} /> */}
        {/* <PendingPayment count={2} /> */}
        {/* <PaidAmount count={165000} />   */}
        <ReceivedFiles count={(tableData[0].received_files_monthly_count.length > 0) ? `${tableData[0].received_files_monthly_count[0].received_file_month_count}` : 0} />
        <UpcomingFollowups count={(tableData[0].upcoming_follow_up_count.length > 0) ? `${tableData[0].upcoming_follow_up_count[0].upcoming_follow_up_count}` : 0} />
        {/* <UpcomingFollowups count={`${tableData[0].upcoming_follow_up_count[0].upcoming_follow_up_count}`} />   */}
        <Followed count={
          (tableData[0].history_follow_up_count.length > 0)
            ? `${tableData[0].history_follow_up_count[0].history_follow_up_count}`
            : 0
        }
        />
      </Row>
      {/* <Row>
        <ProjectAndSheets />
      </Row> */}
      <Row>
        <Col md={12}>
          <h3 className="page-title">FollowUps</h3>
          <h3 className="page-subhead subhead">Upcoming FollowUps & Followed Dates
          </h3>
        </Col>
      </Row>
      <Row>
        <BigCalendar calenderdata={tableData[1]} />
        <EventLabels />
      </Row>
      {/* <Row>
        <ABTestingAnalytics dir={rtl.direction} />
        <BounceRateArea dir={rtl.direction} />
        <VisitorsSessions dir={rtl.direction} />
        <SalesStatistic />
        <BudgetStatistic />
        <AudienceByCountry />
        <BestSellingRegions />
        <GoalsCompletion />
      </Row> */}
    </Container>
  );
};

// OnLineMarketingDashboard.propTypes = {
//   rtl: RTLProps.isRequired,
// };

const mapStateToProps = state => ({
  rtl: state.rtl,
  globalData: state.globalData,
});
const mapDispatchToProps = dispatch => ({
  toGlobalDataSetAction: (url, token) => dispatch(toGlobalDataSet(url, token)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(OnLineMarketingDashboard);
