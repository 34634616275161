/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import ActionGroupButton from '../../../../Components/ActionGroupButton';

const SPLanguageTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const data = [];
  const link = {
    toCreateLink: '/systemparameter/splanguage/view/',
    toHistoryLink: '/systemparameter/splanguage/history/',
    toEditLink: '/systemparameter/splanguage/edit/',
    // toDeleteLink: '/systemparameter/splanguage',
  };
  const [tableData, setTableData] = useState([]);
  async function loadSysLanguage() {
    await axios.get(`${getUserData.ses_URL}language/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/language/1/30888374364/1/0')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].sp_lang_status !== 2) {
       data.push({
        lang_id: `${res.data.data[keyName].sp_lang_id}`,
        lang_name: `${res.data.data[keyName].sp_lang_name}`,
        lang_code: `${res.data.data[keyName].sp_lang_code}`,
        lang_remarks: `${res.data.data[keyName].sp_lang_remarks}`,
        lang_status: `${res.data.data[keyName].status_name}`,
           action: <ActionGroupButton
             btnID={res.data.data[keyName].sp_lang_id}
             toSection="sys-lang"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].sp_lang_id}`}
             toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].sp_lang_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].sp_lang_id}`}
             toDeleteMethod={() => deleteSysLanguage(`${res.data.data[keyName].sp_lang_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
      setTableData(data);
      // window.location.reload(false);
    });
  }
  async function deleteSysLanguage(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Language?');
    if (confirmBox === true) {
      await axios.delete(`${getUserData.ses_URL}language/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      // axios.delete(`http://127.0.0.1:8000/language/1/30888374364/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadSysLanguage();     
        } else {
          toast.error('Try Again!');
        }
      });
    } 
  }
  useEffect(() => {
    loadSysLanguage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'lang_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Language Name',
        accessor: 'lang_name',
      },
      {
        Header: 'Language Code',
        accessor: 'lang_code',
      },
      {
        Header: 'Remarks',
        accessor: 'lang_remarks',
      },
      {
        Header: 'Status',
        accessor: 'lang_status',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  return reactTableData;
};

export default SPLanguageTableData;
