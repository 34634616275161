
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SheetAccountTypeHistoryDesign from './SheetAccountTypeHistoryDesign';
import SheetAccountTypeHistoryData from './SheetAccountTypeHistoryData';



const SheetAccountTypeHistoryIndex = () => {
    const reactTableData = SheetAccountTypeHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Sheet Account Type History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <SheetAccountTypeHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default SheetAccountTypeHistoryIndex;
