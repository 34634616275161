import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import PlusBoldIcon from "mdi-react/PlusBoldIcon";

const UserTableDesign = ({ reactTableData }) => {
  const rows = reactTableData.tableRowsData;
  const dNone = "d-none";
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by ...",
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title d-flex align-items-baseline">
              <h5 className="bold-text">table user-list</h5>
              {/* <h5 className="subhead">Use table with&nbsp;
                <span className="red-text">table customizer</span>
              </h5> */}
              {/* eslint-disable-next-line max-len */}
              <Link to="/master/user/create">
                <Button className="icon" color="success ml-4">
                  <p>
                    <PlusBoldIcon /> Add User
                  </p>
                </Button>
              </Link>
              {/* <NavLink className="landing__btn landing__btn--header" to="/master/FormIndex">
                Add user
              </NavLink> */}
            </div>
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isSortable={isSortable}
              withPagination={withPagination}
              // withSearchEngine={withSearchEngine}
              fullCustomizer
              advanceCustomizerOff={dNone}
            />
          </div>
          <ReactTableBase
            key={withSearchEngine ? "modified" : "common"}
            columns={reactTableData.tableHeaderData}
            data={rows}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

UserTableDesign.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default UserTableDesign;
