import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import UserTableData from './components/Table/UserTableData';
import UserTableDesign from './components/Table/UserTableDesign';

const DataTable = () => {
  const { t } = useTranslation('common');
  const reactTableData = UserTableData();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('master.user.title')}</h3>
          <h3 className="page-subhead subhead">
            User List
          </h3>
        </Col>
      </Row>
      <Row>
        <UserTableDesign reactTableData={reactTableData} />
      </Row>
    </Container>
  );
};

export default DataTable;
