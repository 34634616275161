import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import TemplateTableData from './components/Table/TemplateTableData';
import TemplateTableDesign from './components/Table/TemplateTableDesign';

const DataTable = () => {
  const { t } = useTranslation('common');
  const reactTableData = TemplateTableData();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Template</h3>
          <h3 className="page-subhead subhead">
            Template List
          </h3>
        </Col>
      </Row>
      <Row>
        <TemplateTableDesign reactTableData={reactTableData} />
      </Row>
    </Container>
  );
};

export default DataTable;
