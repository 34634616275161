/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Button,
} from 'reactstrap';
import SourceBranchSyncIcon from 'mdi-react/SourceBranchSyncIcon';
import SyncIcon from 'mdi-react/SyncIcon';
import ActionGroupButton from '../../../../Components/ActionGroupButton';
import MappingButton from '../../../../Components/MappingButton';
import BouncingDotsLoader from '../../../../Components/BouncingDotsLoader';

const ProjectTableData = () => {
  const [getUserData, setGetUserData] = useState(JSON.parse(localStorage.getItem('hismartData')));
  if (getUserData === null) {
    setGetUserData({
      ses_URL: '',
      ses_jwt: '',
    });
  }
  const data = [];
  const urlData = [];
  const link = {
    toCreateLink: '/master/project/view/',
    toHistoryLink: '/master/project/history/',
    toEditLink: '/master/project/edit/',
    // toDeleteLink: '/master/project',
    toMapLink: '/master/mapping/',
    toSecLink: '/master/scheduling/',
  };
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function syncData(id, code, syncBtn) {
    // console.log(id);
    // console.log(code);
    setLoading(true);
    console.log(syncBtn);
    // document.getElementById(syncBtn).className('d-none');
    const rData = [];
    await axios.get(`${getUserData.ses_URL}project/file_details/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/${id}`)
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      rData.push(res.data.data);
      // console.log(rData);
    });
    await axios.post(`http://localhost:8080/HiSmart-Automate/${code}.php`, rData[0])
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      console.log(res);
      if (res.status === 200) {
        toast.success(
          <div className="Toastify__toast-data">
            {/* {res.data.message}, */}
            {res.data.message.map((item, i) => <li key={i}>{item}</li>)},
          </div>,
        );
      } else {
        toast.error('Try Again!');
      }
    });
    // document.getElementById(syncBtn).removeClassName('d-none');
    setLoading(false);
  }
  

  async function loadMasterProject() {
    await axios.get(`${getUserData.ses_URL}project/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/1/0`)
    // axios.get('http://127.0.0.1:8000/project/1/30888374364/1/0')
    .then((res) => {
      // eslint-disable-next-line array-callback-return
      Object.keys(res.data.data).map((keyName) => {
        // console.log(res.data.data);
        // console.log(res.data.status);
        if (res.data.data[keyName].gn_pro_status !== 2) {
          urlData.push({
            pro_id: `${res.data.data[keyName].gn_pro_id}`,
            page: `${res.data.data[keyName].gn_pro_code}.php`,
          });
          
       data.push({
        gn_pro_id: `${res.data.data[keyName].gn_pro_id}`,
        gn_pro_name: `${res.data.data[keyName].gn_pro_name}`,
        gn_pro_code: `${res.data.data[keyName].gn_pro_code}`,
        pro_typ_name: `${res.data.data[keyName].pro_typ_name}`,
        mapping: <MappingButton btnID={res.data.data[keyName].gn_pro_id} toMapLink={`${link.toMapLink}${res.data.data[keyName].gn_pro_id}`} toSecLink={`${link.toSecLink}${res.data.data[keyName].gn_pro_id}`} />,
        status_name: `${res.data.data[keyName].status_name}`,
        sync: <><Button id={`btn-syn-${res.data.data[keyName].gn_pro_id}`} color="success btn-icon__small"><SyncIcon style={{ width: 18, height: 18 }} onClick={() => syncData(`${res.data.data[keyName].gn_pro_id}`, `${res.data.data[keyName].gn_pro_code}`, `btn-syn-${res.data.data[keyName].gn_pro_id}`)} /></Button></>,
           action: <ActionGroupButton
             btnID={res.data.data[keyName].gn_pro_id}
             toSection="master-project"
             toCreateLink={`${link.toCreateLink}${res.data.data[keyName].gn_pro_id}`}
             toHistoryLink={`${link.toHistoryLink}${res.data.data[keyName].gn_pro_id}`}
             toEditLink={`${link.toEditLink}${res.data.data[keyName].gn_pro_id}`}
             toDeleteMethod={() => deleteMasterProject(`${res.data.data[keyName].gn_pro_id}`)}
           />,
         }); 
        }
       }); 
       console.log(data); 
       console.log(urlData);
      setTableData(data);
      // window.location.reload(false);
    });
  }
  async function deleteMasterProject(deleteID) {
    console.log(deleteID);
    const confirmBox = window.confirm('Do you want to delete this Project?');
    if (confirmBox === true) {
      await axios.delete(`${getUserData.ses_URL}project/${getUserData.gn_usr_id}/${getUserData.ses_jwt}/2/${deleteID}`)
      // axios.delete(`http://127.0.0.1:8000/project/1/30888374364/2/${deleteID}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status_code === 200) {
          toast.success(<div><strong className="text-danger">Deleted</strong> successfully</div>);
          data.length = 0;
          setTableData([]);
          loadMasterProject();      
        } else {
          toast.error('Try Again!');
        }
        // setTimeout(() => setTestData(res.data.data[0]), 1000);
        // setTestData(res.data.data[0]);
      });
    } 
  }
  useEffect(() => {
    loadMasterProject();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'gn_pro_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Project Name',
        accessor: 'gn_pro_name',
      },
      {
        Header: 'Project Code',
        accessor: 'gn_pro_code',
      },
      {
        Header: 'Project Type',
        accessor: 'pro_typ_name',
      },
      // {
      //   Header: 'Mapping/Scheduling',
      //   accessor: 'mapping',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
      {
        Header: 'Status',
        accessor: 'status_name',
      },
      // {
      //   Header: 'Sync Data',
      //   accessor: 'sync',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData, syncLoader: loading };
  return reactTableData;
};

export default ProjectTableData;
