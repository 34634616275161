/* eslint-disable array-callback-return */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable max-len */
import React, { useMemo, useEffects, useState } from 'react';
// import axios from 'axios';
import ActionGroupButton from '../../../../Components/ActionGroupButton';

// const config = {
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//   },
// };
// function getCountry() {
//   axios.get('http://thehimailer.com/himailer/api/country', config)
//   // axios.get('http://jsonplaceholder.typicode.com/todos', config)
//   .then(res => console.log(res))
//   .catch(err => console.log(err));
// }
// const initCountry = {
//   con_id: null,
//   con_name: null,
//   con_currency: null,
//   con_area_measurement: null,
//   con_code: null,
//   action: null,
// };

const CountryTableData = () => {
  const data = [];
  
  
  
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'con_id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Country',
        accessor: 'con_name',
      },
      {
        Header: 'Currency Type',
        accessor: 'con_currency',
      },
      {
        Header: 'Area Measurement',
        accessor: 'con_area_measurement',
      },
      {
        Header: 'Code',
        accessor: 'con_code',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  // const reactTableData = [];
  // const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  // const inintReactTableData = { tableHeaderData: columns, tableRowsData: data };

  // const [reactTableData, setreactTableData] = useState(inintReactTableData);

  const link = {
    toCreateLink: '/systemparameter/country/view/',
    toHistoryLink: '/systemparameter/country/history',
    toEditLink: '/systemparameter/country/edit/',
    toDeleteLink: '/systemparameter/country',
  };
  const getRows = async () => { 
    const response = await fetch('http://thehimailer.com/himailer/api/country');
    const result = await response.json();
    // console.log(result.data);   
    Object.keys(result.data).map((keyName, keyIndex) => {
     // console.log(`key=${keyIndex}, value=${result.data[keyName]}`);
    //  Object.keys(result.data[keyIndex]).map((keyNestName, keyNestIndex) => {
    //     console.log(`key=${keyNestIndex}, value=${result.data[keyIndex][keyNestName]}`);
    //     // const nName = result.data[keyName][keyNestName];        
    //   }); 
      data.push({
        con_id: `${result.data[keyName].con_id}`,
        con_name: `${result.data[keyName].con_name}`,
        con_currency: `${result.data[keyName].con_currency}`,
        con_area_measurement: `${result.data[keyName].con_area_measurement}`,
        con_code: `${result.data[keyName].con_code}`,
        action: <ActionGroupButton
          btnID={result.data[keyName].con_id}
          toCreateLink={`${link.toCreateLink}${result.data[keyName].con_id}`}
          toHistoryLink={link.toHistoryLink}
          toEditLink={`${link.toEditLink}${result.data[keyName].con_id}`}
          toDeleteLink={link.toDeleteLink}
        />,
      }); 
    });   

    // console.log(data);
    // setreactTableData({ tableHeaderData: columns, tableRowsData: data });  
  };

  getRows();

  // setTimeout(() => {
  //   console.log('This will run after 1 second!');
  // }, 5000); 
  const reactTableData = { tableHeaderData: columns, tableRowsData: data }; 
  return reactTableData;
};

export default CountryTableData;
