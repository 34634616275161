import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FTPAccountTypeTableData from './components/Table/FTPAccountTypeTableData';
import FTPAccountTypeTableDesign from './components/Table/FTPAccountTypeTableDesign';

const DataTable = () => {
    const reactTableData = FTPAccountTypeTableData();
    
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">FTP Account Type</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <FTPAccountTypeTableDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
  };
  
  export default DataTable;
