/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
// import ActionGroupButton from "../../../../Components/ActionGroupButton";

const UploadHistory = () => {
 
  const [Uploaddata, setUploaddata] = useState(
    JSON.parse(localStorage.getItem("hismartData"))
  );
  if (Uploaddata === null) {
    setUploaddata({
      ses_URL: "",
      ses_jwt: "",
    });
  }
//  console.log(Uploaddata);
  // const data = [
  //   {
  //     sp_sch_id: '1',
  //     sp_sch_name: 'FollowUp 1',
  //     sp_sch_code: 'F1',
  //     Template_remarks: 'F1',
  //     Template_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  //   {
  //     sp_sch_id: '2',
  //     sp_sch_name: 'FollowUp 2',
  //     sp_sch_code: 'F2',
  //     Template_remarks: 'F2',
  //     Template_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  //   {
  //     sp_sch_id: '3',
  //     sp_sch_name: 'Reminder 1',
  //     sp_sch_code: 'R1',
  //     Template_remarks: 'R1',
  //     Template_status: 'Approved',
  //     action: <ActionGroupButton />,
  //   },
  // ];

  const data = [];

  const [tableData, setTableData] = useState([]);
  console.log(
    `${Uploaddata.ses_URL}upload_history/${Uploaddata.gn_usr_id}/${Uploaddata.ses_jwt}/1/0`,
    "demo"
  );
  async function loadSysTemplate() {
    await axios
      .get(
        `${Uploaddata.ses_URL}upload_history/${Uploaddata.gn_usr_id}/${Uploaddata.ses_jwt}/1/0`
      )
      // axios.get('http://127.0.0.1:8000/upload_history/1/91123526535/1/0')
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(res.data.data).map((keyName) => {
          // console.log(res.data.data.sp_temp_filepath,'path');
          // console.log(res.data.status);
          if (res.data.data[keyName].sp_sch_status !== 2) {
            // console.log(res.data.data[keyName]);
            data.push({
              trans_upload_history_id: `${res.data.data[keyName].trans_upload_history_id}`,
              trans_upload_history_updated_count: `${res.data.data[keyName].trans_upload_history_updated_count}`,
              trans_upload_history_newly_created_count: `${res.data.data[keyName].trans_upload_history_newly_created_count}`,
              // trans_upload_history_date: `${res.data.data[keyName].trans_upload_history_date}`,
              trans_upload_history_uploaded_type: `${res.data.data[keyName].trans_upload_history_uploaded_type}`,
              trans_upload_history_date: moment(
                `${res.data.data[keyName].trans_upload_history_date}`
              ).format("YYYY-MM-DD"),

              // sp_sch_prior: `${res.data.data[keyName].sp_sch_prior}`,
              trans_upload_history_time: moment(
                `${res.data.data[keyName].trans_upload_history_date}`
              ).format("HH:mm"),
              trans_upload_history_uploaded_by: `${res.data.data[keyName].trans_upload_history_uploaded_by}`,
              trans_upload_history_status: `${res.data.data[keyName].trans_upload_history_status}`,
            });
          }
        });
        // console.log(data, "data");
      });
    setTableData(data);
  }
  // async function deleteSysTemplate(deleteID) {
  //   console.log(deleteID);
  //   const confirmBox = window.confirm("Do you want to delete this Template?");
  //   if (confirmBox === true) {
  //     await axios
  //       .delete(
  //         `${getTemplateData.ses_URL}schedule/${getTemplateData.gn_usr_id}/${getTemplateData.ses_jwt}/2/${deleteID}`
  //       )
  //       .then((res) => {
  //         console.log(res.data);
  //         if (res.data.status_code === 200) {
  //           // window.location = 'http://localhost:3000/hismart/master/ftpaccounttype';
  //           toast.success(
  //             <div>
  //               <strong className="text-danger">Deleted</strong> successfully
  //             </div>
  //           );
  //           data.length = 0;
  //           setTableData([]);
  //           loadSysTemplate();
  //           // window.location.reload(false);
  //         } else {
  //           toast.error("Try Again!");
  //         }
  //       });
  //   }
  // }
  useEffect(() => {
    loadSysTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "trans_upload_history_id",
      },
      {
        Header: "Date",
        accessor: "trans_upload_history_date",
      },
      {
        Header: "Time",
        accessor: "trans_upload_history_time",
        
      },
      {
        Header: "Name",
        accessor: "trans_upload_history_uploaded_by",
      },
      {
        Header: "History Uploaded Type",
        accessor: "trans_upload_history_uploaded_type",
      },

      {
        Header: "History Newly Created Count",
        accessor: "trans_upload_history_newly_created_count",
      },
      //       {
      //         Header: 'Follow Up Day',
      //         accessor: 'sp_sch_follow_up_day',
      //       },
      {
        Header: "history Updated Count",
        accessor: "trans_upload_history_updated_count",
      },

      // {
      //   Header: "Remarks",
      //   accessor: "sp_sch_remarks",
      // },
      {
        Header: "Status",
        accessor: "trans_upload_history_status",
        Cell: ({ value }) => (
          <div>
            {value === "Failed" ? (
              <div className="hs-text-danger">{value}</div>
            ) : (
              <div className="hs-text-success">{value}</div>
            )}
          </div>
        ),
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
    ],
    []
  );
  // console.log(isLoading);
  const reactTableData = { tableHeaderData: columns, tableRowsData: tableData };
  // if (isLoading) {
  //   return <div className="App">Loading...</div>;
  // }
  return reactTableData;
};

export default UploadHistory;
