/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

const ReceivedFiles = ({ count }) => (
  <Col md={12} xl={4} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card received-files">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title dashboard__booking-total-title--green">
            { count }
          </h5>
          <AccountMultipleIcon style={{ fill: '#fb3a74' }} className="dashboard__trend-icon" />
        </div>
        <h5 className="dashboard__booking-total-description">Received Files</h5>
        <div className="progress-wrap progress-wrap--small progress-wrap--lime-gradient progress-wrap--rounded">
          <p className="dashboard__booking-card-progress-label progress__label mb-1">Current Month Files</p>
          <Progress value={100} />
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default ReceivedFiles;
