
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FTPAccountHistoryDesign from './FTPAccountHistoryDesign';
import FTPAccountHistoryData from './FTPAccountHistoryData';



const FTPAccountHistoryIndex = () => {
    const reactTableData = FTPAccountHistoryData();
  
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">FTP Account History</h3>
            <h3 className="page-subhead subhead">
              List
            </h3>
          </Col>
        </Row>
        <Row>
          <FTPAccountHistoryDesign reactTableData={reactTableData} />
        </Row>
      </Container>
    );
};
  

export default FTPAccountHistoryIndex;
