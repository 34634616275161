import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
} from "react-table";
import ReactTableHeader from "./ReactTableHeader";
import BodyReactTable from "./ReactTableBody";
import ReactTableFooter from "./ReactTableFooter";
import ReactTableFilter from "./ReactTableFilter";
import ReactTablePagination from "./ReactTablePagination";

const ReactTableConstructor = ({
  tableConfig,
  tableOptions,
  tableOptionalHook,
  pageset,
  pageIndex,
  onPageChange,
  pageSize,
  onPageSizeChange,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize,
  } = tableConfig;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    updateDraggableData,
    updateEditableData,
    dataLength,
    state: { pageIndex: currentPageIndex, pageSize: currentPageSize },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook
  );

  useEffect(() => {
    if (pageIndex !== undefined) {
      gotoPage(pageIndex);
    }
  }, [pageIndex, gotoPage]);

  useEffect(() => {
    if (onPageChange) onPageChange(currentPageIndex);
  }, [currentPageIndex, onPageChange]);

  useEffect(() => {
    if (pageSize !== undefined) {
      setPageSize(pageSize);
    }
  }, [pageSize, setPageSize]);

  // Handler to change the page size
  const handlePageSizeChange = (newPageSize) => {
    console.log("Page size:", newPageSize);
    if (onPageSizeChange) onPageSizeChange(newPageSize);
  };

  // useEffect(() => {
  //   if (pageIndex !== undefined) {
  //     console.log("Navigating to page:", pageIndex);
  //     gotoPage(pageIndex);
  //   }
  // }, [pageIndex, gotoPage]);

  // useEffect(() => {
  //   if (onPageChange) {
  //     console.log("Page changed to:", currentPageIndex);
  //     onPageChange(currentPageIndex);
  //   }
  // }, [currentPageIndex, onPageChange]);

  return (
    <div>
      {withSearchEngine && (
        <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder="Search by..."
          dataLength={dataLength}
        />
      )}
      <div
        className={
          withPagination
            ? "table react-table"
            : "table react-table table--not-pagination"
        }
      >
        <table
          {...getTableProps()}
          className={
            isEditable
              ? "react-table editable-table"
              : "react-table resizable-table"
          }
        >
          <ReactTableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isEditable={isEditable}
          // withDragAndDrop={withDragAndDrop}
          />
          {(pageCount === currentPageIndex + 1 ||
            (!withPagination && rows.length !== 0)) && (
              <ReactTableFooter footerGroups={footerGroups} />
            )}
        </table>
      </div>
      {withPagination && rows.length > 0 && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={currentPageSize}
          pageIndex={currentPageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize} // Pass setPageSize to handle page size change
          manualPageSize={manualPageSize}
          dataLength={dataLength}
          pageset={pageset}
          onPageSizeChange={handlePageSizeChange} // Pass handlePageSizeChange
        />
      )}
    </div>
  );
};

ReactTableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    setFilterValue: PropTypes.func,
    defaultColumn: PropTypes.object,
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
    autoResetPage: PropTypes.bool,
    disableSortBy: PropTypes.bool,
    manualSortBy: PropTypes.bool,
    manualGlobalFilter: PropTypes.bool,
    manualPagination: PropTypes.bool,
    initialState: PropTypes.object,
    stateReducer: PropTypes.func,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func),
  pageset: PropTypes.any,
  pageIndex: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
};

ReactTableConstructor.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 50],
  },
  tableOptions: {},
  tableOptionalHook: [],
  pageset: {},
  pageIndex: 0,
  onPageChange: () => { },
  pageSize: 10,
  onPageSizeChange: () => { },
};

export default ReactTableConstructor;
