/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRouter = ({ component, ...rest }) => {
    const [hasToken, setHasToken] = useState('');
    const RenderComponets = component;
    useEffect(() => {
        // console.log(localStorage.getItem('hismartData'));
        if (localStorage.getItem('hismartData') != null) {
            // console.log('yes');
            // console.log(JSON.parse(localStorage.getItem('hismartData'))[0].user_session[0].ses_jwt);
            setHasToken(`${JSON.parse(localStorage.getItem('hismartData')).ses_jwt}`);
            // console.log(hasToken);
        } else {
            // console.log('no');
            setHasToken(null);
            // console.log(hasToken);
        }
    }, []);
    
    // console.log(hasToken);
    // const hasToken = JSON.parse(localStorage.getItem('hismartData'))[0].user_session[0].ses_jwt;
    
    return (
      <Route
        {...rest}
        render={
                props => (hasToken !== null ? (
                  <RenderComponets {...props} />
                    ) : (
                      <Redirect
                        to={{
                            pathname: '/',
                        }}
                      />
                    ))
            }
      />
    );
};
export default ProtectedRouter;
